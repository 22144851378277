/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@uniteplus/rhenus-design-system';
import { Link } from 'react-router-dom';

export const columns = [
  {
    Header: 'Typ',
    accessor: 'warehouseType',
    width: 100,
    hide: true,
  },
  {
    Header: 'Auftragsdatum',
    accessor: 'auftragsdatum',
    width: 150,
  },
  {
    Header: 'Auftrag',
    accessor: 'auftragsnummer',
    width: 150,
  },
  {
    Header: 'Dossier',
    accessor: 'dossier',
    width: 150,
    Cell: ({ row, cell }) => {
      return row.original.transportExists ? (<span
        sx={{
          textDecoration: 'underline',
          color: (theme) => theme.colors.primary,
          cursor: 'pointer',
        }}
      >
        <Link to={"/transport/" + cell.value} onClick={() => { }}>
          {cell.value}
        </Link>
      </span>) :
        (
          <Fragment>{cell.value} </Fragment>
        )
    }
  },
  {
    Header: 'Kunde Nr',
    accessor: 'kundeNr',
    width: 150,
  },
  {
    Header: 'Kunde Name',
    accessor: 'kundeName',
    width: 250,
  },
  {
    Header: 'Kunde PLZ',
    accessor: 'kundePlz',
    width: 130,
  },
  {
    Header: 'Kunde Ort',
    accessor: 'kundeOrt',
    width: 200,
  },
  {
    Header: 'Kunde Land',
    accessor: 'kundeLand',
    width: 200,
  },
  {
    Header: 'Empfänger Nr',
    accessor: 'empfaengerNr',
    width: 150,
  },
  {
    Header: 'Empfänger Name',
    accessor: 'empfaengerName',
    width: 250,
  },
  {
    Header: 'Empfänger PLZ',
    accessor: 'empfaengerPlz',
    width: 130,
  },
  {
    Header: 'Empfänger Ort',
    accessor: 'empfaengerOrt',
    width: 200,
  },
  {
    Header: 'Empfänger Land',
    accessor: 'empfaengerLand',
    width: 200,
  },
  {
    Header: 'Absender Nr',
    accessor: 'absenderNr',
    width: 150,
  },
  {
    Header: 'Absender Name',
    accessor: 'absenderName',
    width: 250,
  },
  {
    Header: 'Absender PLZ',
    accessor: 'absenderPlz',
    width: 130,
  },
  {
    Header: 'Absender Ort',
    accessor: 'absenderOrt',
    width: 200,
  },
  {
    Header: 'Absender Land',
    accessor: 'absenderLand',
    width: 200,
  },
  {
    Header: 'Absender Referenz',
    accessor: 'absenderReferenz',
    width: 200,
  },
  {
    Header: 'Zusätzliche Referenzen',
    accessor: 'zusaetzlicheReferenzen',
    width: 200,
  },
  {
    Header: 'Leistungsdatum',
    accessor: 'leistungsdatum',
    width: 200,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 120,
  },
  {
    Header: 'Versandart',
    accessor: 'versandart',
    width: 120,
  },
  {
    Header: 'Stück',
    accessor: 'stueck',
    width: 120,
  },
  {
    Header: 'Positionen',
    accessor: 'positionen',
    width: 120,
  },
  {
    Header: 'Rhenus Standort',
    accessor: 'rhenusStandort',
    width: 120,
  },
];
