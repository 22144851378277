import React from 'react'
import ReactSelect from 'react-select'
import { useTranslation } from 'react-i18next';

export const LanguageSelect = () => {
    const { t, i18n } = useTranslation();
    // https://react.i18next.com/legacy-v9/step-by-step-guide#d-let-the-user-toggle-the-language
    const changeLanguage = ({ value }: any) => i18n.changeLanguage(value);
    const options: any = [
        { value: 'de_DE', label: t('german') },
        { value: 'en_EN', label: t('english') },
        { value: 'fr_FR', label: t('french') },
        { value: 'it_IT', label: t('italian') },
    ]
    const value = options.filter((opt: any) => opt.value === i18n.language)
    return (
        <ReactSelect
            value={value}
            options={options}
            onChange={changeLanguage}
            styles={{
                indicatorSeparator: (baseStyle) => ({ ...baseStyle, display: 'none' }),
                control: (baseStyle, { isFocused }) => ({
                    ...baseStyle,
                    borderColor: isFocused ? 'rgba(255, 255, 255, 0.25)' : 'rgba(108, 108, 108, 1)',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    "&:hover": {
                        borderColor: "rgba(255, 255, 255, 0.25)"
                    },
                    color: 'white'
                }),
                dropdownIndicator: (baseStyle, { isFocused }) =>
                ({
                    ...baseStyle,
                    color: isFocused ? 'rgba(255, 255, 255, 0.25)' : 'rgba(108, 108, 108, 1)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.25)'
                    }
                }),
                singleValue: (baseStyle) => ({ ...baseStyle, color: 'white' }),
                menu: (baseStyle) => ({ ...baseStyle, backgroundColor: '#2f383a', color: 'white' }),
                option: (baseStyle, { isFocused }) => ({ ...baseStyle, backgroundColor: isFocused ? 'rgba(2, 79, 165, 1)' : '#2f383a', color: 'white' })
            }}
        />
    )
}