import { gql } from 'apollo-boost';

export const query = gql`
  query LieferungenReportQuery(
    # $dateRangeField: DateRangeField
    # $startDate: String
    # $endDate: String
    # $id: Int
    # $kundeId: Int
    # $empfaengerLand: String
    # $statusGruppeId: Int
    # $statusCodeId: Int
    # $referenz: String
    # $frachtfuehrerId: Int
    # $frachtfuehrerGruppeId: Int
    # $lokation: String
    $sendungsnummer: String!
    $offset: Int
    $limit: Int
  ) {
    collection: lieferungenReportCollection(
      # dateRangeField: $dateRangeField
      # startDate: $startDate
      # endDate: $endDate
      # id: $id
      # kundeId: $kundeId
      # empfaengerLand: $empfaengerLand
      # statusGruppeId: $statusGruppeId
      # statusCodeId: $statusCodeId
      # referenz: $referenz
      # frachtfuehrerId: $frachtfuehrerId
      # frachtfuehrerGruppeId: $frachtfuehrerGruppeId
      # lokation: $lokation
      sendungsnummer: $sendungsnummer
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        lieferId: id
        lieferung {
          typ
          kennzeichen
          auftragsNr
          transportAbgangsdatum
          geplantesAnkunftsdatum
          weight
          departurePlace
          destinationPlace
          frachtfuehrer {
            nr
            name
            gruppe {
              name
            }
          }
          empfaenger {
            name
            strasse
            plz
            ort
            land
          }
          absender {
            name
            strasse
            plz
            ort
            land
          }
          pod {
            kommentar
            filename
            lieferzeitpunkt
          }
        }
        statusDate
        statusTime
        statusCode {
          statuscode
          quellsystem {
            name
          }
          statusGruppe {
            id
            name
          }
        }
        status
      }
    }
  }
`;
