/* @jsx jsx */
import { forwardRef, FC } from 'react';
import { Box, jsx } from 'theme-ui';
import { useModalContext } from './Modal';
import { wrapEvent } from '../utils';

const ModalContent: FC<any> = forwardRef(
  ({ onClick, children, zIndex = 'modal', noStyles, sx, ...props }, ref) => {
    const { onClose, isCentered, scrollBehavior, size } = useModalContext();

    let wrapperStyle = {};
    let contentStyle = {};

    if (isCentered) {
      wrapperStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
    } else {
      contentStyle = {
        top: '3.75rem',
        mx: 'auto',
      };
    }

    if (scrollBehavior === 'inside') {
      wrapperStyle = {
        ...wrapperStyle,
        maxHeight: 'calc(100vh - 7.5rem)',
        overflow: 'hidden',
        top: '3.75rem',
      };

      contentStyle = {
        ...contentStyle,
        height: '100%',
        top: 0,
      };
    }

    if (scrollBehavior === 'outside') {
      wrapperStyle = {
        ...wrapperStyle,
        overflowY: 'auto',
        overflowX: 'hidden',
      };

      contentStyle = {
        ...contentStyle,
        my: '3.75rem',
        top: 0,
      };
    }

    if (noStyles) {
      wrapperStyle = {};
      contentStyle = {};
    }

    return (
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex,
        }}
        onClick={onClose}
        {...wrapperStyle}
      >
        <Box
          // @ts-ignore
          ref={ref}
          as="section"
          variant="modal"
          __themeKey="modals"
          sx={{
            position: 'relative',
            maxWidth: size,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            outline: 0,
            zIndex,
            ...contentStyle,
            ...sx,
          }}
          onClick={wrapEvent(onClick, event => event.stopPropagation())}
          {...props}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

ModalContent.displayName = 'ModalContent';

export default ModalContent;
