import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth, Hub, Logger } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from '@uniteplus/rhenus-reports';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import it from 'date-fns/locale/it';
import { client } from './apollo';
import './i18n';

const logger = new Logger('IdentityContext');

type Identity = {
  user: any;
};

// const testLocale = 'en_EN';
const locales = { de, en, fr, it };
const registerSetLocale = (locale = 'de_DE') => {
  const isoCode = locale.includes('_') ? locale.split('_')[0] : locale;
  registerLocale(isoCode, locales[isoCode]);
  setDefaultLocale(isoCode);
};

export const IdentityContext = React.createContext<Identity>({ user: {} });

export const IdentityProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Record<string, any>>({});
  const history = useHistory();
  const { i18n } = useTranslation();

  function modifyUser(user) {
    const { attributes = {} } = user;
    const role = attributes['custom:userCategory'];

    return { ...attributes, role };
  }

  useEffect(() => {
    if (!user) {
      const [language] =
        navigator.language?.length === 2
          ? [navigator.language]
          : navigator.language?.split('-');
      if (Object.keys(locales).includes(language))
        i18n.changeLanguage([language, language?.toUpperCase()]?.join('_'));
    }
  }, [user, i18n]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        logger.debug({ locale: user.attributes.locale });
        i18n.changeLanguage(user.attributes.locale);
        registerSetLocale(user.attributes.locale);
        // registerSetLocale(testLocale);
        setUser(modifyUser(user));
      })
      .catch(() => history.push('/signin'));
  }, [history, i18n]);

  Hub.listen('auth', (data) => {
    const { event } = data.payload;

    switch (event) {
      case 'signIn':
        Auth.currentAuthenticatedUser().then((user) => {
          logger.debug({ locale: user.attributes.locale });
          i18n.changeLanguage(user.attributes.locale);
          registerSetLocale(user.attributes.locale);
          // registerSetLocale(testLocale);
          setUser(modifyUser(user));
        });
        history.push('/');
        break;
      case 'signOut':
        client.resetStore();
        setUser({});
        break;
    }
  });

  return (
    <IdentityContext.Provider value={{ user }}>
      {children}
    </IdentityContext.Provider>
  );
};

export const useAuth = () => useContext(IdentityContext);
