import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Box, Spinner } from '@uniteplus/rhenus-design-system';
import { BarChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { TransportLaufzeitauswertungNegativlisteQuery } from '../__generated__/TransportLaufzeitauswertungNegativlisteQuery';

const transportLaufzeitauswertungNegativliste = gql`
  query TransportLaufzeitauswertungNegativlisteQuery(
    $servicetype: Servicetype!
  ) {
    transportLaufzeitauswertungNegativliste(
      servicetype: $servicetype
      limit: 10
    ) {
      dataKey
      dv1Int
      dv2Int
    }
  }
`;

const TransportLaufzeitauswertungNegativliste = ({ servicetype }) => {
  const { t } = useTranslation();
  const delay = t(
    'dashboard.negativeReport.generalCargo.mapLegend.delay',
    'Anzahl Dossiere in Verzug',
  );
  const history = useHistory();
  const { loading, data: queryData } = useQuery<any>( //TransportLaufzeitauswertungNegativlisteQuery
    transportLaufzeitauswertungNegativliste,
    {
      variables: {
        servicetype,
      },
    },
  );
  const missingData = {
    missingData: t(
      'barChart.messages.missingData.desc',
      'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
    ),
  };

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!queryData || !queryData.transportLaufzeitauswertungNegativliste) {
    return (
      <div>
        {t('transportLaufzeitauswertungNegativliste.noData', 'Keine Daten')}
      </div>
    );
  }

  const data = queryData.transportLaufzeitauswertungNegativliste.map((ds) => ({
    name: ds.dataKey,
    [delay]: ds.dv1Int,
  }));

  return (
    <Box sx={{ height: 350, padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <BarChart
        data={data}
        messages={missingData}
        onClick={({ activeLabel }) =>
          activeLabel
            ? history.push(
                `/transports/negativreporting/${servicetype}/${activeLabel}`,
              )
            : history.push(`/transports/negativreporting/${servicetype}`)
        }
        dataKeys={{
          uv: delay,
          xAxis: 'name',
        }}
        uvColor="#e28181"
      />
    </Box>
  );
};

export default TransportLaufzeitauswertungNegativliste;
