/* @jsx jsx */
import { forwardRef, FC } from 'react';
import { Box, jsx } from 'theme-ui';

const ListItem: FC<any> = forwardRef(({ spacing, ...props }, ref) => (
  <Box
    ref={ref}
    as="li"
    sx={{
      mb: spacing,
      '& > a': {
        color: 'white',
      },
    }}
    {...props}
  />
));

ListItem.displayName = 'ListItem';

export default ListItem;
