import { useTranslation } from 'react-i18next';
import { reportLegend } from '@uniteplus/rhenus-reports';

type Filter = {
  name: string;
  i18n?: string;
  label: string;
  component?: any;
  props?: any;
  initialValue?: any;
};

export const useLocalizedFilters = (
  collection: Filter[],
  prefix: string,
  fallback: boolean = false,
) => {
  const { t, i18n } = useTranslation();

  return collection.map((filter) => ({
    ...filter,
    label: t(
      `${prefix}.${filter.i18n || filter.name}`,
      fallback && filter.label,
    ),
    props: {
      placeholder: t('filters.placeholder'),
      ...filter.props,
      ...(filter.props?.placeholders && {
        placeholders: {
          startDate: t(
            `${prefix}.${filter.name}.placeholders.startDate`,
            'Startdatum',
          ),
          endDate: t(
            `${prefix}.${filter.name}.placeholders.endDate`,
            'Enddatum',
          ),
        },
      }),
      ...(filter.props?.options && {
        options: filter.props.options.map((opt) => ({
          label: t(`${prefix}.${filter.name}.options.${opt.value}`, opt.label),
          value: opt.value,
        })),
      }),
      ...(['DateRangePicker', 'DatePicker'].includes(
        filter?.component?.displayName,
      ) &&
        i18n?.language && { locale: i18n.language?.split?.('_')?.[0] }),
    },
    ...(filter.initialValue?.value && {
      initialValue: {
        value: filter.initialValue.value,
        label: t(
          `${prefix}.${filter.name}.options.${filter.initialValue.value}`,
          filter.initialValue.label,
        ),
      },
    }),
  }));
};

type Column = {
  Header: string;
  i18n?: string;
  accessor: string;
  only?: any;
};

export const useLocalizedColumns = (
  collection: Column[],
  prefix: string,
  fallback: boolean = false,
) => {
  const { t } = useTranslation();

  return collection.map((column) => ({
    ...column,
    Header: t(
      `${prefix}.${column.i18n || column.accessor}`,
      fallback && column.Header,
    ),
  }));
};

export const useLocalizedButtons = (prefix = 'button.default') => {
  const { t } = useTranslation();

  const buttonLabels = Object.keys(reportLegend).filter(
    (item) => item !== 'subtitle',
  );

  return buttonLabels.reduce(
    (acc, label) => ({
      ...acc,
      [label]: t(`${prefix}.${label}`, t(`button.default.${label}`, label)),
    }),
    {},
  );
};
