/* @jsx jsx */
import { forwardRef, FC } from 'react';
import { jsx } from 'theme-ui';
import { ModalCloseButton } from '../Modal';

const DrawerCloseButton: FC<any> = forwardRef(({ onClick, ...rest }, ref) => (
  <ModalCloseButton
    variant="closeButton"
    __themeKey="drawers"
    ref={ref}
    sx={{ position: 'absolute', zIndex: 1 }}
    {...rest}
  />
));

DrawerCloseButton.displayName = 'DrawerCloseButton';

export default DrawerCloseButton;
