import React, { createContext, useState } from 'react';
import { Logger } from 'aws-amplify';

const logger = new Logger('FilterContext');

const FilterContext = createContext<any>({});

function FilterProvider(props) {
  const [filters, setFilters] = useState({});

  const setFilter = (id: string, filter: any) => {
    logger.debug('setFilter', { id, filter });
    setFilters({ ...filters, [id]: filter });
  };

  const getFilter = (id: string) => {
    const filter = filters[id];
    logger.debug('setFilter', { id, filter });
    return filter;
  };

  return <FilterContext.Provider value={{ setFilter, getFilter }} {...props} />;
}

function useFilterContext() {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error(`useFilterContext must be used within a FilterProvider`);
  }
  return context;
}

export { FilterProvider, useFilterContext };
