/* @jsx jsx */
import { forwardRef, FC } from 'react';
import { Box, jsx } from 'theme-ui';
import { useModalContext } from './Modal';

const ModalBody: FC<any> = forwardRef(({ sx, ...props }, ref) => {
  const { scrollBehavior } = useModalContext();

  let style = {};
  if (scrollBehavior === 'inside') {
    style = { overflowY: 'auto' };
  }

  return (
    <Box
      ref={ref}
      sx={{
        flex: '1 0 auto',
        px: 4,
        py: 2,
        ...sx,
      }}
      {...style}
      {...props}
    />
  );
});

ModalBody.displayName = 'ModalBody';

export default ModalBody;
