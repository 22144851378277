import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  CSSReset,
  ThemeProvider,
  themes,
} from '@uniteplus/rhenus-design-system';
import '@uniteplus/rhenus-reports/css/react-datepicker.css';
import '@uniteplus/rhenus-reports/css/react-datepicker-rhenus.css';

import { initSentry } from './errors';
import { IdentityProvider } from './identity-context';
import { amplifyConfig, isProd } from './config';
import { client } from './apollo';
import { initGA } from './usePageViews';
import App from './App';
import './i18n';

initGA();
initSentry();

Amplify.configure(amplifyConfig);
Amplify.Logger.LOG_LEVEL = isProd ? 'INFO' : 'DEBUG';

const theme = themes.v1;

ReactDOM.render(
  <Router>
    <IdentityProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CSSReset />
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </IdentityProvider>
  </Router>,
  document.getElementById('root'),
);
