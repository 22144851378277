import React from 'react';
import { Flex } from '@uniteplus/rhenus-design-system';

const View = props => (
  <Flex
    m={4}
    sx={{ height: 'calc(100% - 90px)', flexDirection: 'column' }}
    {...props}
  />
);

export default View;
