const colors = {
  white: '#FFFFFF',
  black: '#000000',
  text: '#4D4D4D',
  background: '#EEEEEE',
  primary: '#024FA5',
  muted: '#4F84A5',
  // TODO: remove gray colors and see what happens!
  gray: {
    100: '#F1F1F1',
    200: '#EEEEEE',
    250: '#E3E3E3',
    300: '#CCCCCC',
    600: '#A9A9A9',
    700: '#4D4D4D',
  },
  blue: {
    50: '#E3F0FF',
    100: '#7aa9de',
    200: '#4F84A5',
    300: '#024FA5',
  },
  red: {
    300: '#82888a',
    500: '#E45C5C',
  },
  green: {
    500: '#82ca9e',
  },
};

export default colors;
