import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Box, Spinner } from '@uniteplus/rhenus-design-system';
import { BarChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { TransportConsigneeCountryDistributionQuery } from '../__generated__/TransportConsigneeCountryDistributionQuery';

const transportConsigneeCountryDistribution = gql`
  query TransportConsigneeCountryDistributionQuery {
    transportConsigneeCountryDistribution(limit: 10) {
      dataKey
      dv1Int
    }
  }
`;

const TransportConsigneeCountryDistribution = () => {
  const { t } = useTranslation();
  const dossiers = t(
    'dashboard.deliveryDistributionPerCountry.mapLegend.dossiers',
    'Anzahl Dossiere',
  );
  const history = useHistory();
  const { loading, data: queryData } = useQuery<any>( //TransportConsigneeCountryDistributionQuery
    transportConsigneeCountryDistribution,
  );

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!queryData || !queryData.transportConsigneeCountryDistribution) {
    return (
      <div>
        {t('transportConsigneeCountryDistribution.noData', 'Keine Daten')}
      </div>
    );
  }

  const data = queryData.transportConsigneeCountryDistribution.map((ds) => ({
    name: ds.dataKey,
    [dossiers]: ds.dv1Int,
  }));

  return (
    <Box sx={{ height: 350, padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <BarChart
        data={data}
        messages={{
          missingData: t(
            'barChart.messages.missingData.desc',
            'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
          ),
        }}
        onClick={({ activeLabel }) =>
          activeLabel
            ? history.push(
                `/transports/consigneeCountryDistribution/${activeLabel}`,
              )
            : history.push(`/transports/consigneeCountryDistribution`)
        }
        dataKeys={{
          uv: dossiers,
          xAxis: 'name',
        }}
      />
    </Box>
  );
};

export default TransportConsigneeCountryDistribution;
