import React, { FC } from 'react';
import DatePicker from './DatePicker';

const Wrapper = ({ children, showPopperArrow, arrowProps = {}, ...props }) => (
  <div {...props} style={{ ...props.style, width: 120 }}>
    <div className="react-datepicker__triangle" {...arrowProps} />
    {children}
  </div>
);

const TimePicker: FC<any> = ({ timeCaption = 'Uhrzeit', ...props }) => (
  <DatePicker
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={15}
    timeCaption={timeCaption}
    dateFormat="HH:mm"
    calendarContainer={Wrapper}
    {...props}
  />
);

export default TimePicker;
