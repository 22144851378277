import { useThemeUI } from '@uniteplus/rhenus-design-system';

const useChartsTheme = () => {
  const { theme } = useThemeUI();
  // @ts-ignore
  const { charts = {} } = theme;
  const { stroke, strokeDasharray = '0' } = charts.grid || {};
  const { uv = '#8884d8', pv = '#82ca9d', tooltipCursor = '#eaeaea' } =
    charts.fillColors || {};

  return {
    grid: { stroke, strokeDasharray },
    fillColors: { uv, pv, tooltipCursor },
  };
};

export default useChartsTheme;
