import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  LoadingButton,
  Message,
} from '@uniteplus/rhenus-design-system';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { DatePicker, TimePicker, Select } from '@uniteplus/rhenus-reports';
import { setYear, setMonth, setDay, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns/fp';
import { getYear, getMonth, getDay, getHours, getMinutes, getSeconds, getMilliseconds } from 'date-fns';
import flow from 'lodash/fp/flow';
import { Flex } from 'theme-ui';
import { Container } from 'aws-amplify-react';
import { checkRules } from '../components/Can';
import { useAuth } from '../identity-context';

const pluralizeMessage = (count, t) =>
  count > 1
    ? t('tracking.batchStatusUpdate.confirmCompletion.questionPlural', {
      counter: count,
      defaultValue: `Möchten Sie den Abschluss der ${count} markierten Bestellungen bestätigen?`,
    })
    : t(
      'tracking.batchStatusUpdate.confirmCompletion.questionSingular',
      'Möchten Sie den Abschluss der markierten Bestellung bestätigen?',
    );
const resolveStatusUpdateErrors = (error, t) => {
  const { message } = error;

  if (message && message.indexOf('GraphQL error:') !== -1) {
    return message.split(':')[1];
  }

  return t(
    'tracking.statusUpdate.error.msg',
    `Fehler: Der Eintrag konnte nicht gespeichert werden. Bitte versuchen Sie es später
    erneut oder wenden sich in dringenden Angelegenheiten an Ihren Ansprechpartner bei Rhenus.`,
  );
};

const statusUpdateMutation = gql`
  mutation($rows: [String!], $lieferzeitpunkt: String, $abschlussart: String) {
    statusBatchUpdate: statusBatchUpdate(
      rows: $rows
      lieferzeitpunkt: $lieferzeitpunkt
      abschlussart: $abschlussart
    ) {
      path
      message
    }
  }
`;

const BatchStatusUpdate = ({
  isOpen = false,
  rows = [],
  onClose = () => { },
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n?.language ? i18n.language?.split?.('_')?.[0] : 'de';
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<string>();
  const [lieferzeitpunkt, setLieferzeitpunkt] = useState<Date>(new Date());
  const [abschlussart, setAbschlussart] = useState<String>('zugestellt');
  const { user } = useAuth();

  const [updateStatus, { loading }] = useMutation(statusUpdateMutation, {
    variables: {
      rows: rows.map((row) => row.sendungsnummer),
      lieferzeitpunkt: lieferzeitpunkt,
      abschlussart,
    },
    onCompleted: () => setCompleted(true),
    onError: (err) => setError(resolveStatusUpdateErrors(err, t)),
  });

  const handleClose = () => {
    setLieferzeitpunkt(new Date());
    setCompleted(false);
    onClose();
  };

  const handleChangeDate = (e) => {
    setError(undefined);
    if (!e.target.value) {
      e.target.value = new Date();
    }

    setLieferzeitpunkt(flow(
      setHours(getHours(lieferzeitpunkt)),
      setMinutes(getMinutes(lieferzeitpunkt)),
      setSeconds(getSeconds(lieferzeitpunkt)),
      setMilliseconds(getMilliseconds(lieferzeitpunkt)),
    )(e.target.value));
  };
  const handleChangeTime = (e) => {
    setError(undefined);
    if (!e.target.value) {
      e.target.value = new Date();
    }

    setLieferzeitpunkt(flow(
      setYear(getYear(lieferzeitpunkt)),
      setMonth(getMonth(lieferzeitpunkt)),
      setDay(getDay(lieferzeitpunkt)),
    )(e.target.value));

  };
  const handleChangeAbschlussart = (e) => {
    setError(undefined);
    console.log(e.target.value);
    setAbschlussart(e.target.value.value);
  };
  var abschlussarten = [
    {
      value: 'zugestellt',
      label: t(
        'tracking.batchStatusUpdate.confirmCompletion.abschlussarten.delivered.label',
        'Zugestellt',
      ),
    },
  ];
  if (checkRules(user.role, 'dossier-status:updateToNotDelivered')) {
    abschlussarten.push({
      value: 'nichtZugestellt',
      label: t(
        'tracking.batchStatusUpdate.confirmCompletion.abschlussarten.undelivered.label',
        'Nicht zugestellt',
      ),
    });
  }
  return (
    <Modal isOpen={isOpen} size="lg" onClose={handleClose}>
      <ModalOverlay onClose={handleClose} />
      <ModalContent sx={{ fontSize: '12px' }}>
        {/* Success body */}
        {completed && (
          <Fragment>
            <ModalHeader>
              <Heading>
                {t(
                  'tracking.batchStatusUpdate.confirmCompletion.confirmation.title',
                  'Auslieferung bestätigt!',
                )}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text sx={{ mb: 1, fontSize: 1, pr: 4, pb: 3 }}>
                {t(
                  'tracking.batchStatusUpdate.confirmCompletion.confirmation.msg',
                  'Vielen Dank für das Statusupdate. Die Änderungen erscheinen innerhalb der nächsten Stunde auch in Ihrer Transportübersicht.',
                )}
              </Text>
            </ModalBody>
          </Fragment>
        )}
        {/* Main body */}
        {!completed && (
          <Fragment>
            <ModalHeader>
              <Heading>
                {t(
                  'tracking.batchStatusUpdate.confirmCompletion.confirmProcessing.title',
                  'Abschluss bestätigen',
                )}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text sx={{ mb: 1, fontSize: 0 }}>
                {t(
                  'tracking.batchStatusUpdate.confirmCompletion.confirmProcessing.setDate.desc',
                  'Setzen Sie hier Abschlussdatum und -Uhrzeit',
                )}
              </Text>
              <Flex>
                <DatePicker
                  sx={{ width: 130 }}
                  placeholder={t(
                    'tracking.batchStatusUpdate.confirmCompletion.confirmProcessing.setDate.placeholder',
                    'Datum',
                  )}
                  selected={lieferzeitpunkt}
                  onChange={handleChangeDate}
                  locale={locale}
                />
                <TimePicker
                  sx={{ width: 130 }}
                  placeholder={t(
                    'tracking.batchStatusUpdate.confirmCompletion.confirmProcessing.setTime.placeholder',
                    'Uhrzeit',
                  )}
                  timeCaption={t(
                    'tracking.batchStatusUpdate.confirmCompletion.confirmProcessing.setTime.placeholder',
                    'Uhrzeit',
                  )}
                  selected={lieferzeitpunkt}
                  onChange={handleChangeTime}
                />
                <Container style={{ width: `200px` }}>
                  <Select
                    name="abschlussart"
                    label={t(
                      'tracking.batchStatusUpdate.confirmCompletion.confirmProcessing.abschlussart.label',
                      'Abschlussart',
                    )}
                    isClearable={false}
                    options={abschlussarten}
                    defaultValue={abschlussarten[0]}
                    nullable={false}
                    onChange={handleChangeAbschlussart}
                  />
                </Container>
              </Flex>
              {error && (
                <Message sx={{ mt: 1 }} variant="error">
                  {error}
                </Message>
              )}
              <Text sx={{ mt: 3 }}>{pluralizeMessage(rows.length, t)}</Text>
            </ModalBody>
            <ModalFooter sx={{ justifyContent: 'flex-start', pt: 1 }}>
              <LoadingButton
                variant="primary"
                loading={loading}
                onClick={updateStatus}
              >
                {t(
                  'tracking.batchStatusUpdate.confirmCompletion.submit.buttonText',
                  'Absenden',
                )}
              </LoadingButton>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BatchStatusUpdate;
