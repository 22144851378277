import React from 'react';

export default function Sticky({ children, height }) {
  return (
    <div style={{ position: 'relative', minHeight: height }}>
      <div style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
        {children}
      </div>
    </div>
  );
}
