import React, { FC } from 'react';
import AsyncReactSelect from 'react-select/async';
import AsyncCreatableReactSelect from 'react-select/async-creatable';
import { customStyles, ClearIndicator, DropdownIndicator } from './Select';
import { useThemeUI } from '@uniteplus/rhenus-design-system';

const AsyncSelect: FC<any> = ({
  name,
  onChange = () => {},
  height = 25,
  noOptionsMessage = () => 'Keine Auswahl möglich',
  creatable = false,
  ...props
}) => {
  const { theme } = useThemeUI();
  const Select = creatable ? AsyncCreatableReactSelect : AsyncReactSelect;

  return (
    <Select
      styles={customStyles({ height, theme })}
      components={{ ClearIndicator, DropdownIndicator }}
      name={name}
      noOptionsMessage={noOptionsMessage}
      onChange={(value) => onChange({ target: { name, value } })}
      {...props}
    />
  );
};

export default AsyncSelect;
