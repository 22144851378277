import React from 'react';

import rules from '../rbac-rules';

type Props = {
  role: string;
  perform: string;
  data?: any;
  fallback?: any;
};

const check = (rules: any, role: string, action: string, data: any) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const checkRules = (role: string, action: string, data: any = {}) =>
  check(rules, role, action, data);

const Can: React.FC<Props> = ({
  role,
  perform,
  data,
  fallback = null,
  children,
}) => {
  return check(rules, role, perform, data) ? children : fallback;
};

export default Can;
