import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Input,
  Text,
  LoadingButton,
} from '@uniteplus/rhenus-design-system';

const SearchByKundenreferenz = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => setDisabled(value.length === 0), [value]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    history.push(`/transports/byKundenreferenz/${value}`)
  };

  return (
    <Box as="form" onSubmit={handleSubmit} sx={{ p: 3, maxWidth: 400 }}>
      <Text sx={{ fontSize: 1, mb: 2 }}>
        {t(
          'dashboard.transportOverview.performanceCharts.searchKundenreferenz.tooltip',
          'Kundenreferenznummer eingeben',
        )}
      </Text>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target?.value);
        }}
      />
      <LoadingButton
        type="submit"
        variant="primary"
        disabled={disabled}
        sx={{ mt: 2 }}
      >
        {t(
          'dashboard.transportOverview.performanceCharts.searchKundenreferenz.buttonText',
          'Suchen',
        )}
      </LoadingButton>
    </Box>
  );
};

export default SearchByKundenreferenz;
