const colors = {
  white: '#FFFFFF',
  black: '#000000',
  text: '#4D4D4D',
  background: '#FFFFFF',
  primary: '#024FA5',
  gray: {
    300: '#CCCCCC',
    600: '#A9A9A9',
    700: '#4D4D4D',
  },
  blue: {
    300: '#00469B',
  },
  red: {
    500: '#FF0000',
  },
  yellow: {
    500: '#FFBE33',
  },
};

export default colors;
