import React, { createContext, useContext, FC, useRef } from 'react';
import Portal from '../Portal';

const ModalContext = createContext<{
  onClose?: () => void;
  isOpen?: boolean;
  isCentered?: boolean;
  scrollBehavior?: 'inside' | 'outside';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}>({});
export const useModalContext = () => useContext(ModalContext);

const Modal: FC<any> = ({
  isOpen,
  onClose,
  scrollBehavior = 'outside',
  isCentered,
  children,
  size = 'md',
}) => {
  const context = { isOpen, onClose, scrollBehavior, isCentered, size };

  const mountRef = useRef(document.getElementById('rhenus-portal'));

  if (!isOpen) return null;

  return (
    <ModalContext.Provider value={context}>
      <Portal container={mountRef.current}>{children}</Portal>
    </ModalContext.Provider>
  );
};

export default Modal;
