/* @jsx jsx */
import { forwardRef, FC } from 'react';
import { Box, jsx } from 'theme-ui';

const ModalFooter: FC<any> = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Box
      as="footer"
      // @ts-ignore
      ref={ref}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        px: 4,
        py: 4,
        ...sx,
      }}
      {...props}
    />
  );
});

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
