import React from 'react';
import { Text } from '@uniteplus/rhenus-design-system';

export type ChartProps = {
  data: any;
  dataKeys: {
    xAxis: string;
    uv: string;
    pv?: string;
  };
  onClick?: (args: any) => void;
  messages?: { missingData: string };
  stacked? : boolean;
  unit?: string;
  uvColor?: string;
  pvColor?: string;
};

export const CustomizedAxisTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

export const MissingData = props => (
  <Text sx={{ py: 3, color: 'gray.300' }} {...props} />
);

export const handleClick = (id, handler, props = {}) => event => {
  if (event === null) return;

  const clickedChart = id === 'chart';
  const clickedLegend = id === 'legend';
  const clickedBar = id === 'bar';

  clickedChart
    ? handler({ id, activeLabel: event.activeLabel, activePayload: event.activePayload, ...props })
    : clickedLegend
    ? handler({ id, dataKey: event.dataKey, ...props })
    : clickedBar
    ? handler({ id, activeLabel: event.name, ...props })
    : null;
};
