import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import { Flex } from '@uniteplus/rhenus-design-system';
import { Header } from './components';
import ErrorBoundary from './components/ErrorBoundary';
import {
  SignIn,
  ForgotPassword,
  RequireNewPassword,
} from './components/amplify';
import Sticky from './components/Sticky';
import DashboardSelection from './dashboard/DashboardSelection';
import Warehouse from './warehouse/Warehouse';
import Transports from './transports/Transports';
import { FilterProvider } from './filter-context';
import usePageViews from './usePageViews';
import Tracking from './tracking/Tracking';
import Regellaufzeitauswertung from './regellaufzeitauswertung/Regellaufzeitauswertung';

const App = () => {
  usePageViews();

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column' }}>
      <Sticky height={56}>
        <Header />
      </Sticky>
      <ErrorBoundary>
        <FilterProvider>
          <Switch>
            <Route exact path="/">
              <DashboardSelection />
            </Route>
            <Route path="/transport*">
              <Transports />
            </Route>
            <Route path="/warehouse">
              <Warehouse />
            </Route>
            <Route path="/lieferung/:code*">
              {/* http://localhost:3000/lieferung/0a3cf439f62a7ba815df432e916f9115 */}
              <Tracking />
            </Route>
            <Route path="/regellaufzeitauswertung">
              {/* http://localhost:3000/regellaufzeitauswertung */}
              <Regellaufzeitauswertung />
            </Route>
            <Redirect to="/" />
          </Switch>
        </FilterProvider>
      </ErrorBoundary>
    </Flex>
  );
};

export default withAuthenticator(
  App,
  false,
  [<SignIn />, <ForgotPassword />, <RequireNewPassword />],
  null,
  { toast: { display: 'none' } },
);
