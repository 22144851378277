/** @jsx jsx */
import { Fragment, useState, useEffect } from 'react';
import { Flex, jsx, Box } from '@uniteplus/rhenus-design-system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import ProofOfDelivery from '../../../components/ProofOfDelivery';

const Referenz = ({ value = '' }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const ref = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(ref);
  }, [copied]);

  return (
    <Flex sx={{ justifyContent: 'space-between' }}>
      <Box>{value}</Box>
      {value && (
        <Flex
          css={{
            width: 32,
            height: 18,
            flexShrink: 0,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            cursor: 'pointer',
          }}
        >
          <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke={copied ? '#024FA5' : 'currentColor'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
            </svg>
          </CopyToClipboard>
        </Flex>
      )}
    </Flex>
  );
};

export function StatusCircle({ idStatusGruppe }) {
  let color;
  if (idStatusGruppe === 5) color = 'green';
  // Zugestellt
  else if (idStatusGruppe === 6) color = 'red';
  // Nicht zugestellt
  else color = 'orange';

  var circleStyle = {
    backgroundColor: color,
    width: '10px',
    height: '10px',
    display: 'inline-block',
    marginRight: '6px',
    borderRadius: '50%',
  };
  return <div style={circleStyle}></div>;
}

export function columns(showDeliveryDelayColumn: Boolean) {
  return [
    {
      Header: 'Referenz',
      accessor: 'referenz',
      width: 220,
      resizable: true,
      disableSortBy: true,
      Cell: ({ cell }) => <Referenz value={cell.value} />,
    },
    {
      Header: 'Sendungsnummer',
      accessor: 'sendungsnummer',
      width: 200,
      id: 'sendungsnummer',
      resizable: true,
      disableSortBy: true,
      Cell: ({ row, cell }) =>
        row.original.anzahlLieferungen > 0 ? (
          <span
            sx={{
              textDecoration: 'underline',
              color: (theme) => theme.colors.primary,
              cursor: 'pointer',
            }}
            {...row.getToggleRowExpandedProps()}
          >
            {cell.value}
          </span>
        ) : (
          <Fragment>{cell.value}</Fragment>
        ),
    },
    {
      Header: 'Transport Abgangsdatum',
      accessor: 'transportAbgangsdatum',
      width: 170,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Geplantes Ankunftsdatum',
      accessor: 'geplantesAnkunftsdatum',
      width: 170,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Gesamtstatus',
      accessor: 'gesamtstatus',
      width: 170,
      resizable: true,
      disableSortBy: true,
      Cell: ({ row, cell }) => (
        <Fragment>
          <StatusCircle
            idStatusGruppe={cell.value === 'komplettAbgeschlossen' ? 5 : cell.value === 'keineSendungsbewegung' ? 6 : 0}
          />
          {useTranslation().t(`transports.dossier.columns.gesamtstatus.${cell.value}`, {
            defaultValue: cell.value,
          })
          }
        </Fragment>
      ),
    },
    {
      Header: 'Letztes Event Status Gruppe',
      accessor: 'statusGruppeName',
      width: 150,
      resizable: true,
      disableSortBy: true,
      Cell: ({ row, cell }) => (
        <Fragment>
          <StatusCircle
            idStatusGruppe={row.original.statusGruppe?.id}
          />
          {cell.value}
        </Fragment>
      ),
    },
    {
      Header: 'Letztes Event Status',
      accessor: 'status',
      width: 250,
      resizable: true,
      disableSortBy: true,
      Cell: ({ row, cell }) =>
        row.original.pod ? (
          <ProofOfDelivery
            label={cell.value}
            title={cell.value}
            pod={row.original.pod}
          />
        ) : (
          <Fragment>{cell.value}</Fragment>
        ),
    },
    {
      Header: 'Letztes Event Status Code',
      accessor: 'statuscode',
      width: 150,
      resizable: true,
      disableSortBy: true,
      Cell: ({ row, cell }) =>
        row.original.statusCode ? (
          <Fragment>
            {cell.value} - {row.original.statusCode.quellsystem.name}
          </Fragment>
        ) : (
          <Fragment>{cell.value}</Fragment>
        ),
    },
    {
      Header: 'Letztes Event Datum',
      accessor: 'statusDate',
      width: 150,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Letztes Event Uhrzeit',
      accessor: 'statusTime',
      width: 150,
      resizable: true,
      disableSortBy: true,
    },
    ...showDeliveryDelayColumn ? [{
      Header: 'Abweichung Zustelltermin',
      accessor: 'deliveryDelay',
      width: 150,
      resizable: true,
      disableSortBy: true,
      Cell: ({ row, cell }) => (
        row.original.performanceStatus ? (
          <Fragment>
            <StatusCircle
            idStatusGruppe={row.original.performanceStatus === 'DeliveredOnTime' ? 5 : row.original.performanceStatus === 'DeliveredDelayed' ? 6 : 0}
          />
          {row.original.deliveryDelay}
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )
      ),


    }] : [],
    {
      Header: 'Kundennummer',
      accessor: 'kundeNr',
      width: 150,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Kunde',
      accessor: 'kundeName',
      width: 200,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Anzahl Packstücke',
      accessor: 'anzahlPackstuecke',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Gewicht',
      accessor: 'weight',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Standort',
      accessor: 'lokation',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Empfänger',
      accessor: 'empfaengerName',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Empfänger PLZ',
      accessor: 'empfaengerPlz',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Empfänger Ort',
      accessor: 'empfaengerOrt',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
    {
      Header: 'Empfänger Land',
      accessor: 'empfaengerLand',
      width: 100,
      resizable: true,
      disableSortBy: true,
    },
  ]
};
