import React from 'react';
import {
  Flex,
  IconButton,
  Heading,
  Text,
  Button,
} from '@uniteplus/rhenus-design-system';

import { useReportContext } from './Report';
import ExportDialog from './ExportDialog';

const Header = () => {
  const {
    legend = {},
    stats = {},
    showFilters,
    toggleFilters = () => {},
    reportFilters,
    isSelectable,
    onAction,
    isSelected,
    query,
    queryVariables,
    columns,
    selectedRows,
    limit,
    messages,
  } = useReportContext();

  return (
    <Flex sx={{ bg: 'white', justifyContent: 'space-between', padding: 4 }}>
      <Flex
        sx={{
          alignItems: ['flex-start', 'flex-start', 'center'],
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Heading mr={2}>{legend.title}</Heading>
        <Text sx={{ fontSize: 1, color: '#4d4d4d' }}>
          {legend.subtitle({ count: stats.count })}
        </Text>
      </Flex>
      <Flex
        sx={{
          alignItems: ['flex-end', 'center'],
          flexDirection: ['column', 'row'],
          '& > button': { mb: 1 },
        }}
      >
        {!!reportFilters.length && (
          <IconButton
            variant="primary"
            iconBefore="filter"
            onClick={toggleFilters}
          >
            {showFilters ? legend.hideFilters : legend.showFilters}
          </IconButton>
        )}
        {isSelectable && (
          <ExportDialog
            messages={messages}
            query={query}
            queryVariables={queryVariables}
            columns={columns}
            disabled={!isSelected}
            totalRows={stats.count}
            selectedRows={selectedRows}
            buttonText={legend.csvExport}
            filename={legend.title}
            pageSize={limit}
          />
        )}
        {onAction && (
          <Button
            variant="primary"
            disabled={!isSelected}
            sx={{ ml: 2 }}
            onClick={onAction}
          >
            {legend.actionButton}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
