import { TransportType } from '../../utils';

export const columns = [
  {
    Header: 'Status Quelle',
    accessor: 'quellsystem.name',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Status Gruppe',
    accessor: 'statusCode.statusGruppe.name',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Status Code',
    accessor: 'statusCode.statuscode',
    width: 79,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 275,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Status Datum',
    accessor: 'statusDate',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Status Uhrzeit',
    accessor: 'statusTime',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Ort',
    accessor: 'city',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Flug',
    accessor: 'flug',
    width: 170,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => cell.value ? `${cell?.value?.seq} - ${cell?.value?.apdest} - ${cell?.value?.airlineCode} ${cell?.value?.flightNumber}` : '',
    only: TransportType.AIRPLANE,
  },
  {
    Header: 'Status: Menge / Gewicht in kg',
    accessor: 'units',
    width: 170,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => row.original.units || row.original.weight ? `${row.original.units} / ${row.original.weight}` : '',
    only: TransportType.AIRPLANE,
  },
  {
    Header: 'Total Sendung: Menge/Gewicht in kg',
    accessor: 'weight',
    width: 170,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => row.original.lieferung.units || row.original.lieferung.weight ? `${row.original.lieferung.units} / ${row.original.lieferung.weight}` : '',
    only: TransportType.AIRPLANE,
  },
  {
    Header: 'Frachtführer-Referenz',
    accessor: 'customCarrierReference',
    width: 170,
    resizable: true,
    disableSortBy:true,
  },
];
