import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Stat,
  StatEntry,
  Spinner,
} from '@uniteplus/rhenus-design-system';

import { TransportStatsQuery } from '../__generated__/TransportStatsQuery';
import SearchByTrackingNumber from './SearchByTrackingNumber';
import SearchByKundenreferenz from './SearchByKundenreferenz';

const transportStatsQuery = gql`
  query TransportStatsQuery {
    stats {
      total
      open
    }
  }
`;

const TransportPerformance = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data, loading } = useQuery<TransportStatsQuery>(transportStatsQuery);

  if (loading) {
    return <Spinner size={32} />;
  }

  const { total, open } = data?.stats || {};

  return (
    <Flex
      sx={{
        pl: 8,
        flexDirection: ['column', 'column', 'column', 'row'],
        height: [270, 270, 286, 286],
        justifyContent: 'space-between',
        '& > div': { flex: '1 0 auto' },
      }}
    >
      <Box>
        <Stat
          icon="arrowUp"
          label={t(
            'dashboard.transportOverview.performanceCharts.mapLegend.title',
            'Transporte der lfd. Woche',
          )}
          missingData={t(
            'dashboard.transportPerformance.missingData.desc',
            'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
          )}
        >
          <StatEntry
            value={total}
            onClick={() => history.push('/transports/week')}
          />
          <StatEntry
            value={open}
            subnote={t(
              'dashboard.transportOverview.performanceCharts.undelivered',
              'offen',
            )}
            onClick={() => history.push('/transports/open')}
          />
        </Stat>
      </Box>
      <Box>
        <SearchByTrackingNumber />
      </Box>
      <Box>
        <SearchByKundenreferenz />
      </Box>
    </Flex>
  );
};

export default TransportPerformance;
