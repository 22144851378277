import React from 'react';
import { Auth, Logger } from 'aws-amplify';
import { ForgotPassword as AmplifyForgotPassword } from 'aws-amplify-react';
import {
  Background,
  Container,
  Box,
  Heading,
  Label,
  Link,
  Input,
  Button,
  Flex,
  Text,
  Message,
} from '@uniteplus/rhenus-design-system';
import { Translation } from 'react-i18next';
import { logError } from '../../errors';

import Footer from './Footer';

const logger = new Logger('ForgotPassword');

class ForgotPassword extends AmplifyForgotPassword {
  state: any = {};

  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword'];
  }

  error(error: any) {
    logError(error);
    // getting rid of colons because this causes noSuchKey bugs @t18n
    const [message] = error?.message.split(':')?.map(str => str.trim())?.reverse()
    this.state.showError = true
    // @ts-ignore
    this.setState({ error: { ...error, message } });
  }

  send() {
    const { authData = {} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;
    Auth.forgotPassword(username)
      .then((data) => {
        logger.debug(data);
        this.setState({ delivery: data.CodeDeliveryDetails });
      })
      .catch((err) => this.error(err));
  }

  sendView() {
    return (
      <Box bg="white" mb={1} p={3}>
        <Text sx={{ fontSize: 1, mb: 3 }}>
          <Translation>
            {(t) =>
              t(
                'forgotPassword.desc',
                'Wir senden Ihnen eine Bestätigungs-E-Mail. Klicken Sie auf den darin enthaltenen Link, um Ihr Passwort zu ändern.',
              )
            }
          </Translation>
        </Text>
        <Label htmlFor="username">
          <Translation>
            {(t) => t('forgotPassword.username.label', 'Benutzername*')}
          </Translation>
        </Label>
        <Input
          id="username"
          key="username"
          name="username"
          onChange={this.handleInputChange}
          type="text"
          placeholder="Geben Sie Ihren Benutzernamen ein"
          mb={3}
        />
        {this.state.showError && this.state.error && (
          <Message variant="error">
            <Translation>
              {(t) =>
                t(this.state.error.message)
              }
            </Translation>
          </Message>
        )}
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Link
            href="#"
            onClick={() => {
              this.state.showError = false
              super.changeState('signIn')
            }}
            sx={{ fontSize: 1 }}
          >
            <Translation>
              {(t) =>
                t('forgotPassword.backToLoginPage', 'Zurück zu Anmeldung')
              }
            </Translation>
          </Link>
          <Button onClick={() => {
            this.state.showError = false
            this.send()
          }}>
            <Translation>
              {(t) => t('forgotPassword.buttonText.sendCode', 'Code senden')}
            </Translation>
          </Button>
        </Flex>
      </Box>
    );
  }

  submitView() {
    return (
      <Box bg="white" mb={1} p={3}>
        <Text sx={{ fontSize: 1, mb: 3 }}>
          <Translation>
            {(t) =>
              t(
                'forgotPassword.submit.desc',
                'Wir senden Ihnen eine Bestätigungs-E-Mail. Klicken Sie auf den darin enthaltenen Link, um Ihr Passwort zu ändern.',
              )
            }
          </Translation>
        </Text>
        <Label htmlFor="code">
          <Translation>
            {(t) => t('forgotPassword.submit.label.code', 'Code *')}
          </Translation>
        </Label>
        <Input
          id="code"
          key="code"
          name="code"
          onChange={this.handleInputChange}
          type="text"
          placeholder="Code"
          autoComplete="off"
          mb={3}
        />
        <Label htmlFor="Passwort">
          {' '}
          <Translation>
            {(t) =>
              t('forgotPassword.submit.label.newPassword', 'Neues Passwort *')
            }
          </Translation>
        </Label>
        <Input
          id="password"
          key="password"
          name="password"
          onChange={this.handleInputChange}
          type="password"
          placeholder="Passwort"
          autoComplete="off"
          mb={3}
        />
        {this.state.showError && this.state.error && (
          <Message variant="error">
            <Translation>
              {(t) =>
                t(this.state.error.message)
              }
            </Translation>
          </Message>
        )}
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Link href="#" onClick={this.send} sx={{ fontSize: 1 }}>
            <Translation>
              {(t) =>
                t('forgotPassword.submit.sendCodeAgain', 'Code erneut senden')
              }
            </Translation>
          </Link>
          <Button onClick={() => super.submit()}>
            {' '}
            <Translation>
              {(t) => t('forgotPassword.submit.send', 'Abschicken')}
            </Translation>
          </Button>
        </Flex>
      </Box>
    );
  }

  showComponent() {
    return (
      <Container>
        <Background url="/img/bg_rhenus_login.jpg" />
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ flex: '1 0 auto', mt: 100, width: 400 }}>
            <Box bg="white" mb={1} p={3}>
              <Heading>
                <Translation>
                  {(t) => t('forgotPassword.title', 'Passwort zurücksetzen')}
                </Translation>
              </Heading>
            </Box>
            {this.state.delivery ? this.submitView() : this.sendView()}
            <Footer />
          </Box>
        </Flex>
      </Container>
    );
  }
}

export default ForgotPassword;
