export const GRAPHQL = process.env.REACT_APP_GRAPHQL || `http://${window.location.hostname}:4000`;

export const PUBLIC_TNT = 'https://rhenus-tracking-v2.staging-uniteplus.de';
export const LEGACY_TNT = 'https://old-tracking.rhenus.ch';
export const DASHBOARD_MANAGER_URL = 'https://dash-manager.staging-uniteplus.de';

export const POD_BUCKET = 'rhenus-dashboard-uploads-mirror';

export const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-central-1:ce417ea2-8b51-4827-acd0-fd389b19d7ea',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_ThM90InzZ',
    userPoolWebClientId: '3a73d2mfmonotmfhufhqcqr94n',
    mandatorySignIn: true,
  },
};
