import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Box, Spinner } from '@uniteplus/rhenus-design-system';
import { BarChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { TransportLaufzeitauswertungQuery } from '../__generated__/TransportLaufzeitauswertungQuery';

const transportLaufzeitauswertung = gql`
  query TransportLaufzeitauswertungQuery(
    $servicetype: Servicetype!
    $limit: Int!
    $startDate: String
    $endDate: String
    $country: [String]
  ) {
    transportLaufzeitauswertung(
      servicetype: $servicetype
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      country: $country
    ) {
      dataKey
      dv1Int
      dv2Int
      dv3Int
      dv4Int
    }
  }
`;

const TransportLaufzeitauswertung = ({
  servicetype,
  limit = 10,
  startDate = new Date().getFullYear() + '-01-01',
  endDate = new Date().getFullYear() + '-12-01',
  country = []
}) => {
  const { t } = useTranslation();
  const labelDeliveredOnTime = t(
    'dashboard.standardDeliveryTimeEvaluation.generalCargo.mapLegend.onTime',
    'Prozent Dossiere zugestellt on time',
  );
  const labelDeliveredNotOnTime = t(
    'dashboard.standardDeliveryTimeEvaluation.generalCargo.mapLegend.notInTime',
    'Prozent Dossiere nicht on time zugestellt',
  );
  const labelToFilterValues = {
    [labelDeliveredOnTime]: 'DeliveredOnTime',
    [labelDeliveredNotOnTime]: 'DeliveredDelayed',
  };
  const missingData = {
    missingData: t(
      'barChart.messages.missingData.desc',
      'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
    ),
  };
  const history = useHistory();
  const { loading, data: queryData } = useQuery<any>( //TransportLaufzeitauswertungQuery
    transportLaufzeitauswertung,
    {
      variables: {
        servicetype,
        limit,
        startDate,
        endDate,
        ...(Array.isArray(country) && country.length && { country })
      },
    },
  );

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!queryData || !queryData.transportLaufzeitauswertung) {
    return <div>{t('transportLaufzeitauswertung.noData', 'Keine Daten')}</div>;
  }

  const getName = (name: string) => name.includes('-') ? name.split('-').reverse().slice(1).join('/') : name

  const data = queryData.transportLaufzeitauswertung
    .sort((a: any, b: any) => {
      return !a.dataKey.includes('-') ? 0 : a.dataKey < b.dataKey ? -1 : a.dataKey > b.dataKey ? 1 : 0
    })
    .map((ds: any) => ({
      name: getName(ds.dataKey),
      [labelDeliveredOnTime]: ds.dv1Int,
      [labelDeliveredNotOnTime]: ds.dv2Int,
    }));

  return (
    <Box sx={{ height: 350, padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <BarChart
        formatter={(value, name, entry) => {
          const ds = queryData.transportLaufzeitauswertung.find(({ dataKey }: any) => getName(dataKey) === entry?.payload?.name)
          const abs = name === labelDeliveredOnTime ? ds?.dv3Int : ds?.dv4Int
          return `${value}% (${abs})`
        }}
        data={data}
        messages={missingData}
        onClick={({ activeLabel, dataKey: performancestatus }) => {
          const isByMonth = activeLabel.includes('/')

          if (isByMonth && Array.isArray(country) && country.length === 1) {
            return history.push(
              `/transports/regellaufzeitauswertung/${servicetype}/${labelToFilterValues[performancestatus]}/${startDate}/${endDate}/${country?.[0]}`,
            )
          }

          !isByMonth && activeLabel ?
            history.push(
              `/transports/regellaufzeitauswertung/${servicetype}/${labelToFilterValues[performancestatus]}/${startDate}/${endDate}/${activeLabel}`,
            )
            : history.push(
              `/transports/regellaufzeitauswertung/${servicetype}/${labelToFilterValues[performancestatus]}/${startDate}/${endDate}`,
            );

        }}
        dataKeys={{
          uv: labelDeliveredOnTime,
          pv: labelDeliveredNotOnTime,
          xAxis: 'name',
        }}
        stacked={true}
        unit="%"
        uvColor="#85ce94"
        pvColor="#e28181"
      />
    </Box>
  );
};

export default TransportLaufzeitauswertung;
