import React from 'react';
import { RequireNewPassword as AmplifyRequireNewPassword } from 'aws-amplify-react';
import {
  Background,
  Container,
  Box,
  Heading,
  Label,
  Link,
  Input,
  Button,
  Flex,
  Message,
} from '@uniteplus/rhenus-design-system';
import { Translation } from 'react-i18next';

import Footer from './Footer';
import { logError } from '../../errors';

class RequireNewPassword extends AmplifyRequireNewPassword {
  state: any = {};

  constructor(props) {
    super(props);
    this._validAuthStates = ['requireNewPassword'];
  }

  error(error: any) {
    logError(error);
    // @ts-ignore
    this.setState({ error });
  }

  showComponent() {
    return (
      <Container>
        <Background url="/img/bg_rhenus_login.jpg" />
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ flex: '1 0 auto', mt: 100, width: 400 }}>
            <Box bg="white" mb={1} p={3}>
              <Heading>
                <Translation>
                  {(t) => t('requireNewPassword.title', 'Neues Passwort')}
                </Translation>
              </Heading>
            </Box>
            <Box bg="white" mb={1} p={3}>
              <Label htmlFor="Passwort">
                {' '}
                <Translation>
                  {(t) => t('requireNewPassword.label', 'Neues Passwort *')}
                </Translation>
              </Label>
              <Input
                id="password"
                key="password"
                name="password"
                onChange={this.handleInputChange}
                type="password"
                placeholder="Passwort"
                autoComplete="off"
                mb={3}
              />
              {this.state.error && (
                <Message variant="error">
                  <Translation>
                    {(t) =>
                      t(
                        'requireNewPassword.error.msg',
                        'Geben Sie hier bitte ihr neues Passwort ein. Dieses muss mindestens 8 Zeichen enthalten. Es muss eine Ziffer, ein Großbuchstabe und ein Kleinbuchstabe enthalten sein.',
                      )
                    }
                  </Translation>
                </Message>
              )}
              <Flex
                sx={{ alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Link
                  href="#"
                  onClick={() => this.changeState('signIn')}
                  sx={{ fontSize: 1 }}
                >
                  <Translation>
                    {(t) =>
                      t(
                        'requireNewPassword.backToLoginPage',
                        'Zurück zur Anmeldung',
                      )
                    }
                  </Translation>
                </Link>
                <Button onClick={this.change}>
                  <Translation>
                    {(t) => t('requireNewPassword.buttonText.change', 'Ändern')}
                  </Translation>
                </Button>
              </Flex>
            </Box>
            <Footer />
          </Box>
        </Flex>
      </Container>
    );
  }
}

export default RequireNewPassword;
