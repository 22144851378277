/** @jsx jsx */
import React, { Fragment, useEffect } from 'react';
import {
  useTable,
  useBlockLayout,
  useRowSelect,
  useExpanded,
  useResizeColumns,
} from 'react-table';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { jsx } from '@uniteplus/rhenus-design-system';
import { VerticalScrollbar, HorizontalScrollbar } from './Scrollbars';
import Drawer from './Drawer';
import Styles from './Styles';
import Loading from './Loading';
import { useReportContext } from './Report';

const IndeterminateCheckbox: React.FC<any> = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Fragment>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </Fragment>
    );
  },
);

function Table(props: any) {
  const {
    data,
    columns,
    isSelectable,
    setSelectedRows,
    itemCount,
    hasNextPage,
    loadMoreItems,
    tableBodyHeight,
    loading,
    Details,
  } = useReportContext();

  const defaultColumn = React.useMemo(() => ({ width: 60 }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    toggleRowExpanded,
    state: { selectedRowIds, expanded },
  } = useTable(
    {
      columns,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.hide)
          .map((col) => col.accessor),
      },
      data,
      defaultColumn,
      autoResetSelectedRows: true,
      stateReducer: (newState, action, _prevState) => {
        // console.log(newState, action, _prevState);
        switch (action.type) {
          case 'toggleRowExpanded': {
            return {
              ...newState,
              expanded: action.value === undefined ? { [action.id]: true } : {},
            };
          }
          default: {
            return newState;
          }
        }
      },
    },
    useBlockLayout,
    useResizeColumns,
    useRowSelect,
    useExpanded,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return isSelectable
          ? [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                ),
              },
              ...columns,
            ]
          : columns;
      });
    },
  );

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < data.length;

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];

      if (!row) {
        return null;
      }

      prepareRow(row);

      return (
        <div {...row.getRowProps({ style })} className="tr">
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps({ style: { display: 'flex' } })}
                className="td"
              >
                <span style={{ minWidth: 0 }}>{cell.render('Cell')}</span>
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedRowIds],
  );

  useEffect(() => {
    const rowIds = Object.keys(selectedRowIds);
    setSelectedRows(rows.filter((row) => rowIds.includes(row.id)));
  }, [selectedRowIds]);

  useEffect(() => {
    console.log('Expanded', JSON.stringify(expanded));
  }, [expanded]);

  const [expandedRowIndex] = Object.keys(expanded);

  return (
    <Fragment>
      <ScrollSync>
        <Styles>
          <ScrollSyncPane>
            <div className="tableWrap">
              <div {...getTableProps()} className="table">
                <div>
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className="header-tr"
                    >
                      {headerGroup.headers.map((column) => (
                        <div
                          {...column.getHeaderProps({
                            style: { display: 'flex' },
                          })}
                          className="th"
                        >
                          {column.render('Header')}
                          {/* @ts-ignore */}
                          {column.resizable && (
                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${
                                column.isResizing ? 'isResizing' : ''
                              }`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div {...getTableBodyProps()} className="table-body">
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount + 1}
                    loadMoreItems={loadMoreItems}
                  >
                    {({ onItemsRendered, ref }) => (
                      <Fragment>
                        <Loading loading={loading} height={tableBodyHeight} />
                        <Drawer open={Object.keys(expanded).length}>
                          <Details
                            data={data[expandedRowIndex]}
                            onClose={() =>
                              toggleRowExpanded([expandedRowIndex], false)
                            }
                          />
                        </Drawer>
                        <ScrollSyncPane>
                          <List
                            className="virtualList"
                            height={tableBodyHeight}
                            itemSize={40}
                            width={totalColumnsWidth}
                            itemCount={itemCount}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            {...props}
                          >
                            {RenderRow}
                          </List>
                        </ScrollSyncPane>
                      </Fragment>
                    )}
                  </InfiniteLoader>
                </div>
              </div>
            </div>
          </ScrollSyncPane>
          <ScrollSyncPane>
            <HorizontalScrollbar width={totalColumnsWidth} />
          </ScrollSyncPane>
          <ScrollSyncPane>
            <VerticalScrollbar height={itemCount * 40} />
          </ScrollSyncPane>
        </Styles>
      </ScrollSync>
    </Fragment>
  );
}

export default Table;
