/** @jsx jsx */
import { forwardRef, FC } from 'react';
import { Box, jsx } from 'theme-ui';

const ModalHeader: FC<any> = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Box
      // @ts-ignore
      ref={ref}
      as="header"
      sx={{
        position: 'relative',
        fontSize: 'xl',
        fontWeight: 'semibold',
        px: 4,
        py: 4,
        ...sx,
      }}
      {...props}
    />
  );
});

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
