import React from 'react';
import { Box, Flex, Heading, Spinner } from '@uniteplus/rhenus-design-system';

const Loading = ({ heading = 'Sendungsverfolgung' }: any) => (
  <Flex sx={{ flexDirection: 'column' }}>
    <Box sx={{ bg: 'white', mb: [0, 0, 1], p: 4 }}>
      <Heading>{heading}</Heading>
    </Box>
    <Flex
      sx={{
        height: 200,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // bg: 'rgba(255, 255, 255, 0.64)',
        bg: 'white',
      }}
    >
      <Spinner size={64} />
    </Flex>
  </Flex>
);

export default Loading;
