/** @jsx jsx */
import { FC } from 'react';
import { jsx, Global, css } from '@emotion/core';

const Background: FC<any> = ({ url }) => {
  return (
    <Global
      styles={_theme => css`
        html {
          background: url('${url}') no-repeat center center fixed;
          background-size: cover;
        }
        body {
          background: transparent;
        }
      `}
    />
  );
};

export default Background;
