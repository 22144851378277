import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import Icon from '../Icon';

const Stat: React.FC<any> = ({
  label,
  icon,
  children,
  missingData = 'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
  ...props
}) => {
  return (
    <Flex sx={{ bg: 'white', p: 3 }} {...props}>
      <Icon name={icon} size="26px" />
      <Box sx={{ px: 3, borderLeft: '2px solid', borderColor: 'black' }}>
        <Text sx={{ fontSize: 1 }}>{label}</Text>
        {children ? (
          children
        ) : (
          <Text sx={{ py: 2, fontSize: 1, color: 'gray.300', width: 180 }}>
            {missingData}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

Stat.displayName = 'Stat';

export default Stat;
