import React from 'react';
import Dashboard from './Dashboard';
import Dossier from '../transports/Dossier';
import { useAuth } from '../identity-context';

const DashboardSelection = () => {
  const { user } = useAuth();
  const isCarrier = user['custom:userCategory'] === 'Dienstleister';

  return isCarrier ? <Dossier /> : <Dashboard />;
};

export default DashboardSelection;
