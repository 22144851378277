import React, { FC, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import useChartsTheme from './useChartsTheme';
import { MissingData } from './shared';

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333">
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {value}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const Chart: FC<any> = ({
  data,
  dataKey = 'value',
  fill,
  height = 200,
  thickness = 10,
  onClick = () => {},
  messages = {
    missingData: 'Für diese Ansicht sind aktuell keine Daten vorhanden.',
  },
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { fillColors } = useChartsTheme();
  const missingData = !data.length;

  const onPieEnter = (_data, index) => {
    setActiveIndex(index);
  };

  const handleClick = ({ payload }) => onClick(payload.payload);

  const _fill = fill || fillColors.pv;

  const _height = Math.max(150, height);
  const outerRadius = (_height - 100) / 2;
  const innerRadius = outerRadius - thickness;

  return missingData ? (
    <MissingData>{messages.missingData}</MissingData>
  ) : (
    <div style={{ width: '100%', height: _height }}>
      <ResponsiveContainer>
        <PieChart height={_height}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill={_fill}
            dataKey={dataKey}
            onMouseEnter={onPieEnter}
            onClick={handleClick}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
