import { gql } from 'apollo-boost';
import { startOfYear, endOfYear } from 'date-fns';
import { DateRangePicker, Select } from '@uniteplus/rhenus-reports';
import Autocomplete from '../../../components/Autocomplete';
import Locations from '../filter/Locations';

const warehouseTypeOptions = [
  { value: 'in', label: 'Eingang' },
  { value: 'out', label: 'Ausgang' },
];

export const filters = [
  {
    name: 'timeRange',
    label: 'Zeitraum',
    component: DateRangePicker,
    props: { placeholders: { startDate: 'Startdatum', endDate: 'Enddatum' } },
    initialValue: {
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    },
  },
  {
    name: 'warehouseType',
    label: 'Typ',
    component: Select,
    props: { options: warehouseTypeOptions },
    initialValue: warehouseTypeOptions[0],
    hide: true,
    nullable: false,
  },
  {
    label: 'Auftragsnummer',
    name: 'auftragsnummer',
    component: Autocomplete,
    props: {
      query: gql`
        query warehouseAuftragsnummern($inputValue: String!) {
          suggestions: warehouseAuftragsnummern(
            warehouseType: in
            prefix: $inputValue
          ) {
            auftragsnummer
          }
        }
      `,
      mapOptions: (suggestion) => {
        const { auftragsnummer } = suggestion;
        return {
          value: auftragsnummer,
          label: auftragsnummer.toString(),
        };
      },
    },
  },
  {
    name: 'location',
    label: 'Lokation',
    component: Locations,
  },
  {
    name: 'status',
    label: 'Status',
    component: Select,
    props: {
      options: [
        { label: 'Offen', value: 'OPEN' },
        { label: 'Abgeschlossen', value: 'CLOSED' },
      ],
    },
  },
  {
    name: 'kundeNr',
    label: 'Kunde Nummer',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangKundeNr($inputValue: String!) {
          suggestions: parties(kundeNr: $inputValue, group: "kundeNr") {
            value: kundeNr
            label: kundeNr
          }
        }
      `,
    },
  },
  {
    name: 'kundeName',
    label: 'Kunde Name',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangKundeName($inputValue: String!) {
          suggestions: parties(kundeName: $inputValue, group: "kundeName") {
            value: kundeName
            label: kundeName
          }
        }
      `,
    },
  },
  {
    name: 'kundePlz',
    label: 'Kunde PLZ',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangKundePlz($inputValue: String!) {
          suggestions: parties(kundePlz: $inputValue, group: "kundePlz") {
            value: kundePlz
            label: kundePlz
          }
        }
      `,
    },
  },
  {
    name: 'kundeOrt',
    label: 'Kunde Ort',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangKundeOrt($inputValue: String!) {
          suggestions: parties(kundeOrt: $inputValue, group: "kundeOrt") {
            value: kundeOrt
            label: kundeOrt
          }
        }
      `,
    },
  },
  {
    name: 'kundeLand',
    label: 'Kunde Land',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangKundeLand($inputValue: String!) {
          suggestions: parties(kundeLand: $inputValue, group: "kundeLand") {
            value: kundeLand
            label: kundeLand
          }
        }
      `,
    },
  },
  {
    name: 'empfaengerNr',
    label: 'Empfänger Nummer',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangEmpfaengerNr($inputValue: String!) {
          suggestions: parties(
            empfaengerNr: $inputValue
            group: "empfaengerNr"
          ) {
            value: empfaengerNr
            label: empfaengerNr
          }
        }
      `,
    },
  },
  {
    name: 'empfaengerName',
    label: 'Empfänger Name',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangEmpfaengerName($inputValue: String!) {
          suggestions: parties(
            empfaengerName: $inputValue
            group: "empfaengerName"
          ) {
            value: empfaengerName
            label: empfaengerName
          }
        }
      `,
    },
  },
  {
    name: 'empfaengerPlz',
    label: 'Empfänger PLZ',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangEmpfaengerPlz($inputValue: String!) {
          suggestions: parties(
            empfaengerPlz: $inputValue
            group: "empfaengerPlz"
          ) {
            value: empfaengerPlz
            label: empfaengerPlz
          }
        }
      `,
    },
  },
  {
    name: 'empfaengerOrt',
    label: 'Empfänger Ort',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangEmpfaengerOrt($inputValue: String!) {
          suggestions: parties(
            empfaengerOrt: $inputValue
            group: "empfaengerOrt"
          ) {
            value: empfaengerOrt
            label: empfaengerOrt
          }
        }
      `,
    },
  },
  {
    name: 'empfaengerLand',
    label: 'Empfänger Land',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangEmpfaengerLand($inputValue: String!) {
          suggestions: parties(
            empfaengerLand: $inputValue
            group: "empfaengerLand"
          ) {
            value: empfaengerLand
            label: empfaengerLand
          }
        }
      `,
    },
  },
  {
    name: 'absenderNr',
    label: 'Absender Nummer',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangAbsenderNr($inputValue: String!) {
          suggestions: parties(absenderNr: $inputValue, group: "absenderNr") {
            value: absenderNr
            label: absenderNr
          }
        }
      `,
    },
  },
  {
    name: 'absenderName',
    label: 'Absender Name',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangAbsenderName($inputValue: String!) {
          suggestions: parties(
            absenderName: $inputValue
            group: "absenderName"
          ) {
            value: absenderName
            label: absenderName
          }
        }
      `,
    },
  },
  {
    name: 'absenderPlz',
    label: 'Absender PLZ',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangAbsenderPlz($inputValue: String!) {
          suggestions: parties(absenderPlz: $inputValue, group: "absenderPlz") {
            value: absenderPlz
            label: absenderPlz
          }
        }
      `,
    },
  },
  {
    name: 'absenderOrt',
    label: 'Absender Ort',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangAbsenderOrt($inputValue: String!) {
          suggestions: parties(absenderOrt: $inputValue, group: "absenderOrt") {
            value: absenderOrt
            label: absenderOrt
          }
        }
      `,
    },
  },
  {
    name: 'absenderLand',
    label: 'Absender Land',
    component: Autocomplete,
    props: {
      query: gql`
        query WareneingangAbsenderLand($inputValue: String!) {
          suggestions: parties(
            absenderLand: $inputValue
            group: "absenderLand"
          ) {
            value: absenderLand
            label: absenderLand
          }
        }
      `,
    },
  },
];
