/** @jsx jsx */
import {
  jsx,
  ListItem,
  Link as UILink,
  Divider,
  Heading,
  List,
} from '@uniteplus/rhenus-design-system';
import React, { Component } from 'react';
import { getSignedUrl } from './ProofOfDelivery';
import { client } from '../apollo';
import { gql } from 'apollo-boost';
import { Translation } from 'react-i18next';

type AttachedFilesProps = {
  dividerStyles: Object;
};
class AttachedFiles extends Component<AttachedFilesProps> {
  state: { attachedFiles: { key: string; name: string }[] } = {
    attachedFiles: null,
  };

  componentDidMount() {
    this.getAttachedFiles().then((attachedFiles) => {
      this.setState({ attachedFiles });
    });
  }

  async getAttachedFiles() {
    const query = gql`
      query attachedFiles {
        attachedFiles {
          key
          name
        }
      }
    `;

    const {
      data: { attachedFiles },
    } = await client.query({
      query,
      variables: {},
      fetchPolicy: 'network-only',
    });

    return attachedFiles;
  }

  render() {
    if (!this.state.attachedFiles || this.state.attachedFiles.length === 0)
      return <React.Fragment />;
    return (
      <React.Fragment>
        <Divider sx={this.props.dividerStyles} />
        <Heading>
          <Translation>
            {(t) => t('attachedFiles.title', 'Downloads')}
          </Translation>
        </Heading>
        <List spacing={2}>
          {this.state.attachedFiles.map((attachedFile) => (
            <ListItem key={attachedFile.key}>
              <UILink
                target="_blank"
                rel="noopener noreferrer"
                css="cursor: pointer;"
                onClick={async () => {
                  const url = await getSignedUrl(attachedFile.key);
                  window.open(url, '_blank');
                }}
              >
                {attachedFile.name}
              </UILink>
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  }
}

export default AttachedFiles;
