export const messages = (t) => ({
  exportDialog: {
    title: t('exportDialog.title', 'CSV Export'),
    rowsOfRows: (rowCount, totalRows) =>
      t('exportDialog.rowsOfrows.desc', {
        rowCount,
        totalRows,
        defaultValue: `Es werden ${rowCount} von ${totalRows} Zeilen exportiert!`,
      }),
    pageOfPageCount: (paging) =>
      t('exportDialog.pageOfpageCount.progress', {
        page: paging.page,
        pageCount: paging.pageCount,
        defaultValue: `Seite ${paging.page} von ${paging.pageCount}`,
      }),
    export: t('exportDialog.buttonText.export', 'Export'),
    abort: t('exportDialog.buttonText.abort', 'Abbruch'),
    selectAll: t(
      'exportDialog.selectAll',
      'Alle auswählen (längere Ladezeit)',
    ),
  },
});
