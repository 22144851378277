import sizes, { baseSizes } from './sizes';
import typography from './typography';
import colors from './colors';
import icons from './icons';

const space = baseSizes;

const heading = {
  color: 'primary',
  fontFamily: 'heading',
  lineHeight: 'normal',
  fontWeight: 'extrabold',
};

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

const button = {
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 3,
  padding: '7px 14px',
  border: '1px solid transparent',
  borderRadius: 2,
  outline: 'none',
  cursor: 'pointer',
};

const theme = {
  space,
  sizes,
  ...typography,
  fontSizes: [10, 12, 13, 14, 16, 18, 20, 38],
  zIndices,
  colors,
  icons,
  // https://theme-ui.com/color-modes/#turn-off-custom-properties
  useCustomProperties: false,
  borders: [1],
  buttons: {
    primary: {
      ...button,
      fontFamily: 'heading',
      bg: 'primary',
      color: 'white',
      ':disabled': {
        bg: 'gray.300',
      },
    },
    secondary: {
      ...button,
      fontFamily: 'heading',
      bg: 'white',
      color: 'text',
      border: '1px solid',
      borderColor: 'gray.300',
      ':disabled': {
        color: 'white',
        bg: 'gray.300',
      },
    },
    ghost: {
      ...button,
      bg: 'transparent',
      fontFamily: 'heading',
    },
  },
  radii: {
    none: 0,
    small: 2,
  },
  text: {
    heading: {
      fontSize: 6,
      color: 'primary',
      fontWeight: 'bold',
    },
  },
  forms: {
    label: {
      fontSize: 1,
      marginBottom: 1,
      fontWeight: 'bold',
    },
    input: {
      fontSize: 1,
      outline: 'none',
      borderRadius: 2,
      borderColor: 'gray.200',
      lineHeight: 'normal',
      height: 25,
      '&::placeholder': {
        color: 'gray.300',
      },
    },
    textarea: {
      fontSize: 1,
      color: 'muted',
      outline: 'none',
      borderRadius: 2,
      borderColor: 'gray.200',
      '&::placeholder': {
        color: 'gray.300',
      },
    },
    error: {
      fontSize: 1,
      color: 'muted',
      outline: 'none',
      borderRadius: 2,
      borderColor: 'red.500',
      lineHeight: 'auto',
      height: 25,
      '&::placeholder': {
        color: 'gray.300',
      },
    },
  },
  modals: {
    overlay: {
      bg: 'rgba(0,0,0,0.4)',
    },
    modal: {
      bg: 'white',
      shadow: '0 7px 14px 0 rgba(0,0,0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)',
    },
    closeButton: {
      color: 'black',
      '&:hover': {
        color: 'grey08',
      },
    },
  },
  drawers: {
    overlay: {
      bg: 'rgba(255,255,255,0.4)',
    },
    drawer: {
      bg: 'rgba(77, 77, 77, 0.95)',
      color: 'white',
      fontSize: 2,
    },
    closeButton: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },
  },
  charts: {
    grid: {
      stroke: 'rgba(71, 71, 71, 0.1)',
      // strokeDasharray: '3 3',
    },
    fillColors: {
      uv: '#4c7eb9',
      pv: '#ffd270',
      tooltipCursor: '#eaeaea',
    },
  },
  messages: {
    error: {
      fontSize: 1,
      color: 'red.500',
      border: 'none',
      background: 'none',
      padding: 0,
      marginBottom: 2,
    },
  },
  styles: {
    icon: {
      small: {
        width: '12px',
        height: '12px',
      },
      medium: {
        width: '18px',
        height: '18px',
      },
      large: {
        width: '24px',
        height: '24px',
      },
    },
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      bg: '#eee',
    },
    h1: {
      ...heading,
      fontSize: 7,
    },
    h2: {
      ...heading,
      fontSize: 6,
    },
    h3: {
      ...heading,
      fontSize: 5,
    },
    h4: {
      ...heading,
      fontSize: 4,
    },
    h5: {
      ...heading,
      fontSize: 3,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
    },
    hr: {
      opacity: 0.13,
    },
  },
};

export default theme;
