import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Background, Box, Button, Flex } from '@uniteplus/rhenus-design-system';
import { Tracking as TrackAndTrace } from '@uniteplus/rhenus-track-and-trace';
import { Report } from '@uniteplus/rhenus-reports';
import { Logger } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import report from './reports/versandstatus';
import View from '../components/View';
import { buildTrackingResultFromResponse } from './utils';
import StatusUpdate from './StatusUpdate';
import { useAuth } from '../identity-context';
import { checkRules } from '../components/Can';
import { ProofOfDelivery } from '../components';
import { useLocalizedColumns } from '../utils/useLocalized';
import { TransportType } from './utils';

const logger = new Logger('Tracking');

const lieferungsStatusQuery = gql`
  query LieferungsStatusQuery($id: String!) {
    statusGruppen {
      id
      name
      icon
    }
    lieferung(id: $id) {
      typ
      auftragsNr
      lieferscheinNr
      kennzeichen
      transportAbgangsdatum
      geplantesAnkunftsdatum
      departurePlace
      destinationPlace
      kundenreferenzen {
        nr
      }
      pod {
        kommentar
        filename
        lieferzeitpunkt
      }
      dossier {
        sendungsnummer
        kunde {
          nr
          name
          strasse
          plz
          ort
          land
        }
        quellsystem {
          name
        }
      }
      empfaenger {
        nr
        name
        strasse
        plz
        ort
        land
      }
      absender {
        nr
        name
        strasse
        plz
        ort
        land
      }
      frachtfuehrer {
        nr
        name
        gruppe {
          name
        }
      }
      fluege {
        id
        seq
        apdep
        apdest
        airlineCode
        flightNumber
        frachtfuehrer {
          name
        }
      }
      quellsystem {
        name
      }
    }
    versandstatus(lieferungId: $id) {
      statusCode {
        statusGruppe {
          id
        }
      }
    }
    versandstatusCollection(lieferungId: $id) {
      rows {
        statusCode {
          statuscode
        }
        status
      }
    }
  }
`;

const Tracking = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [editable, setEditable] = useState(false);
  const { params } = useRouteMatch();
  const [isOpen, setOpen] = useState<boolean>();
  const { code } = (params as any) || {};
  const variables = { id: code };
  const { data, loading } = useQuery(lieferungsStatusQuery, { variables });

  useEffect(() => {
    setEditable(checkRules(user.role, 'track-and-trace-status:update'));
  }, [user]);

  const i18nForTrackingResultsFromResponse = {
    trackingNumber: t(
      'tracking.informations.trackingNumber.label',
      'Sendungsnummer',
    ),
    contractNumber: t(
      'tracking.informations.contractNumber.label',
      'Auftrag-Nummer',
    ),
    receiptNumber: t(
      'tracking.informations.receiptNumber.label',
      'Lieferschein-Nummer',
    ),
    deliveryNumber: {
      default: t(
        'tracking.informations.deliveryNumber.default.label',
        'Lieferungskennzeichen',
      ),
      ship: t(
        'tracking.informations.deliveryNumber.ship.label',
        'Buchungsnummer',
      ),
      airplane: t(
        'tracking.informations.deliveryNumber.airplane.label',
        'Airwaybill',
      )
    },
    departurePlace: {
      default: t(
        'tracking.informations.departurePlace.default.label',
        'Abgangsort',
      ),
      ship: t(
        'tracking.informations.departurePlace.ship.label',
        'Abgangshafen',
      ),
      plane: t(
        'tracking.informations.departurePlace.plane.label',
        'Abgangsflughafen',
      ),
      rail: t(
        'tracking.informations.departurePlace.rail.label',
        'Abgangsbahnhof',
      )
    },
    destinationPlace: {
      default: t(
        'tracking.informations.destinationPlace.default.label',
        'Bestimmungsort',
      ),
      ship: t(
        'tracking.informations.destinationPlace.ship.label',
        'Bestimmungshafen',
      ),
      plane: t(
        'tracking.informations.destinationPlace.plane.label',
        'Bestimmungsflughafen',
      ),
      rail: t(
        'tracking.informations.destinationPlace.rail.label',
        'Bestimmungsbahnhof',
      )
    },
    dayStarted: t(
      'tracking.informations.transportDayStarted.label',
      'Transportabgangsdatum',
    ),
    scheduledArrival: t(
      'tracking.informations.scheduledArrival.label',
      'geplantes Ankunftsdatum',
    ),
    kundenreferenzen: t(
      'tracking.informations.kundenreferenzen.label',
      'Referenz-Nummer',
    ),
    customer: t('tracking.informations.detail.customer.label', 'Kunde'),
    recipient: t('tracking.informations.detail.recipient.label', 'Empfaenger'),
    shipper: t('tracking.informations.detail.shipper.label', 'Absender'),
    notSpecified: t(
      'tracking.informations.detail.info.notSpecified',
      'Nicht angegeben',
    ),
    freightCarrier: {
      label: t(
        'tracking.informations.detail.freightCarrier.label',
        'Frachtfuehrer',
      ),
      nr: t('tracking.informations.detail.freightCarrier.nr', 'Nr:'),
      name: t('tracking.informations.detail.freightCarrier.name', 'Name:'),
      group: t('tracking.informations.detail.freightCarrier.group', 'Gruppe:'),
    },
    flights: {
      label: t(
        'tracking.informations.detail.flights.label',
        'Flüge',
      ),
    },
    address: {
      nr: t('tracking.informations.detail.info.address.nr', 'Nr:'),
      name: t('tracking.informations.detail.info.address.name', 'Name:'),
      street: t('tracking.informations.detail.info.address.street', 'Strasse:'),
      zipCity: t(
        'tracking.informations.detail.info.address.zip/city',
        'PLZ/Stadt:',
      ),
      country: t('tracking.informations.detail.info.address.country', 'Land:'),
    },
  };

  const i18nForPackageTrackAndTrace = {
    shipmentTracking: t('tracking.title', 'Sendungsverfolgung'),
    informations: t('tracking.informations.title', 'Informationen'),
    buttonText: t('tracking.buttonText.edit', 'Bearbeiten'),
  };

  const messages = { title: t('exportDialog.title', 'CSV Export') };

  let transportType = TransportType.TRUCK;
  if (!loading && data && data.lieferung && data.lieferung.quellsystem && data.lieferung.quellsystem.name) {
    if (data.lieferung.quellsystem.name === 'FAST/Teilstrecken-See' || data.lieferung.quellsystem.name === 'FAST/Teilstrecken-Barge') {
      transportType = TransportType.SHIP;

      data.statusGruppen.forEach(statusGruppe => {
        if (statusGruppe.icon === 'shipping') {
          statusGruppe.icon = 'shippingShip';
        } else if (statusGruppe.icon === 'deliveryTruck') {
          statusGruppe.icon = 'deliveryShip';
        } else if (statusGruppe.icon === 'delivery') {
          statusGruppe.icon = 'deliveryCompletedShip';
        }
      });

    }
    else if (data.lieferung.quellsystem.name === 'FAST/Teilstrecken-Luft') {
      transportType = TransportType.AIRPLANE;

      data.statusGruppen.forEach(statusGruppe => {
        if (statusGruppe.icon === 'deliveryTruck') {
          statusGruppe.icon = 'deliveryAirplane';
        }
      });
    }
    else if (data.lieferung.quellsystem.name === 'FAST/Teilstrecken-Rail') {
      transportType = TransportType.RAIL;

      data.statusGruppen.forEach(statusGruppe => {
        if (statusGruppe.icon === 'deliveryTruck') {
          statusGruppe.icon = 'deliveryRail';
        }
      });
    }
  }

  const { infos, track, shippingDetails } = buildTrackingResultFromResponse({
    response: data,
    editable,
    i18nForTrackingResultsFromResponse,
    transportType
  });

  logger.log('pod data', data);

  const showPOD = data?.lieferung?.pod;
  let title = 'POD';
  if (
    data &&
    data.versandstatusCollection &&
    data.versandstatusCollection.rows
  ) {
    const [statusCode = {}] = [...data.versandstatusCollection.rows].reverse();
    title = statusCode.status;
  }

  const columns = useLocalizedColumns(
    report.columns,
    'tracking.versandstatus.columns',
  );

  return (
    <View>
      <Background url="/img/rhenus-background-ship.jpg" />
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 'container',
          mx: 'auto',
        }}
      >
        <Box sx={{ mb: 4 }}>
          <TrackAndTrace
            loading={loading}
            infos={infos}
            track={track as any}
            shippingDetails={shippingDetails}
            onEdit={() => setOpen(true)}
            i18nForPackageTrackAndTrace={i18nForPackageTrackAndTrace}
          />
          <StatusUpdate
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            sendungsnummer={data?.lieferung?.dossier?.sendungsnummer}
            kennzeichen={data?.lieferung?.kennzeichen}
            system={data?.lieferung?.dossier?.quellsystem?.name}
          />
        </Box>
        {showPOD && (
          <Flex bg="white" sx={{ p: 2, justifyContent: 'flex-end' }}>
            <ProofOfDelivery
              trigger={Button}
              label={t(
                'tracking.ProofOfDelivery.showPOD.label',
                'POD anzeigen',
              )}
              title={title}
              pod={data?.lieferung?.pod}
            />
          </Flex>
        )}
        {!loading && (
          <Report
            key={i18n.language}
            styles={{ flex: '1 1 auto' }}
            messages={messages}
            query={report.query}
            columns={columns.filter(column => !column.only || column.only === transportType)}
            filterState={{ lieferungId: code }}
            legend={{ title: t('tracking.versandstatus.title', 'Status') }}
          />
        )}
      </Flex>
    </View>
  );
};

export default Tracking;
