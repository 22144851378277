import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Box, Spinner } from '@uniteplus/rhenus-design-system';
import { SynchronizedAreaChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { StorageEvolutionQuery } from '../__generated__/StorageEvolutionQuery';

const storageEvolutionQuery = gql`
  query StorageEvolutionQuery($location: Lokation!) {
    warehouseEvolution(lokation: $location) {
      dataKey
      dv1Int
      dv2Int
    }
  }
`;

const StorageEvolution = ({ location, locationKey = location }) => {
  const { t } = useTranslation();
  const receipt = t(
    'dashboard.developmentGoodsIn/Out.default.in',
    'Lageraufträge Wareneingang',
  );
  const issue = t(
    'dashboard.developmentGoodsIn/Out.default.out',
    'Lageraufträge Warenausgang',
  );
  const history = useHistory();
  const { loading, data: queryData } = useQuery<StorageEvolutionQuery>(
    storageEvolutionQuery,
    {
      variables: {
        location,
      },
    },
  );

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!queryData || !queryData.warehouseEvolution) {
    return <div>{t('storageEvolution.noData', 'Keine Daten')}</div>;
  }

  const data = queryData.warehouseEvolution.map((ds) => ({
    name: ds.dataKey,
    [receipt]: ds.dv1Int,
    [issue]: ds.dv2Int,
  }));

  return (
    <Box sx={{ padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <SynchronizedAreaChart
        data={data}
        syncId={location}
        onClick={({ id, position, activeLabel }) => {
          // prettier-ignore
          const url = `/warehouse/${position === 'top' ? 'in' : 'out'}/${locationKey || location}/${activeLabel}`;

          if (id === 'chart') {
            history.push(url);
          }
        }}
        dataKeys={{
          uv: receipt,
          pv: issue,
          xAxis: 'name',
        }}
        messages={{
          missingData: t(
            'dashboard.developmentGoodsIn/Out.missingData',
            'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
          ),
        }}
      />
    </Box>
  );
};

export default StorageEvolution;
