/** @jsx jsx */
import { useContext } from 'react';
import { jsx, ThemeContext } from '@emotion/core';
import { ThemeProvider as ThemeUIProvider } from 'theme-ui';
import { themes } from './themes';

const ThemeProvider = ({ theme, children }) => (
  <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>
);

ThemeProvider.defaultProps = {
  theme: themes.v1,
};

const useTheme = () => {
  const theme = useContext(ThemeContext);
  if (theme === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return theme;
};

export default ThemeProvider;
export { useTheme };
