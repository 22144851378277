import React from 'react';
import { Report } from '@uniteplus/rhenus-reports';

import View from '../components/View';
import { useFilterContext } from '../filter-context';
import { filtersToVariables } from '../utils/reports';
import {
  useLocalizedButtons,
  useLocalizedColumns,
  useLocalizedFilters,
} from '../utils/useLocalized';
import { useTranslation } from 'react-i18next';
import usePreset from '../transports/usePreset';
import report from './reports/warenausgang';
import { presets } from './reports/presets';
import { messages } from '../utils/exportDialog';

const Warenausgang = ({ preset }: { preset?: string }) => {
  const { t, i18n } = useTranslation();
  const { setFilter } = useFilterContext();
  const filterId = preset ? `warehouse/out/${preset}` : `warehouse/out`;
  const { isPreset, filterState, presetFilters } = usePreset(
    filterId,
    preset,
    presets,
  );

  const filters = useLocalizedFilters(
    report.filters,
    'warehouse.warenausgang.filters',
  );

  const columns = useLocalizedColumns(
    report.columns,
    'warehouse.warenausgang.columns',
  );

  return (
    <View>
      <Report
        key={i18n.language}
        messages={messages(t)}
        legend={{
          title: t('warehouse.warenausgang.title', 'Warenausgang'),
          subtitle: ({ count }) =>
            t('warehouse.warenausgang.info', {
              counter: count,
              defaultValue: `Gesamt: ${count}`,
            }),
          ...useLocalizedButtons(),
        }}
        query={report.query}
        columns={columns}
        isSelectable={true}
        filters={filters}
        filtersToVariables={filtersToVariables}
        filterState={filterState}
        onFilterStateChange={(filter) => setFilter(filterId, filter)}
        resetFilters={() => setFilter(filterId, presetFilters)}
        gridColumns={[2, 3, 4]}
        filterHeightOverride={[550, 385, 275]} // IE11
        limit={200}
        buttons={{ apply: true, reset: isPreset, clear: true }}
      />
    </View>
  );
};

export default Warenausgang;
