import React, { FC, forwardRef } from 'react';
import { Flex } from 'theme-ui';

const withGap = (g) => {
  const withFlexBasis = (n) =>
    Array.isArray(n)
      ? n.map(withFlexBasis)
      : !!n && (typeof n === 'number' ? `calc(${100 / n}% - ${g * 8}px)` : n);

  return withFlexBasis;
};

const Grid: FC<any> = forwardRef(
  ({ gap = 1, columns = [], height, children, ...props }, ref: any) => {
    const flexBasis = withGap(gap)(columns);

    return (
      <Flex
        ref={ref}
        {...props}
        sx={{
          flexWrap: 'wrap',
          flexDirection: 'row',
          flex: '0 0 auto',
          height,
          '& > div': {
            display: 'flex',
            flex: '0 0 auto',
            flexBasis,
            flexDirection: 'column',
            margin: gap,
          },
        }}
      >
        {children}
      </Flex>
    );
  },
);

export default Grid;
