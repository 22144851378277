import React, { forwardRef, FC } from 'react';
import styled from '@emotion/styled';
import { Box, useThemeUI } from 'theme-ui';

const Svg = styled(Box)`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`;

const Icon: FC<any> = forwardRef(
  (
    {
      size = '1em',
      name,
      color = 'currentColor',
      role = 'presentation',
      focusable = false,
      ...rest
    },
    ref,
  ) => {
    const {
      // @ts-ignore
      theme: { icons: iconPaths },
    } = useThemeUI();

    // Fallback in case you pass the wrong name
    const iconFallback = iconPaths['question-outline'];

    const path =
      iconPaths[name] == null ? iconFallback.path : iconPaths[name].path;

    const viewBox =
      (iconPaths[name] == null
        ? iconFallback.viewBox
        : iconPaths[name].viewBox) || '0 0 24 24';

    return (
      <Svg
        ref={ref}
        as="svg"
        sx={{ size, color, display: 'inline-block', verticalAlign: 'middle' }}
        viewBox={viewBox}
        focusable={focusable}
        role={role}
        {...rest}
      >
        {path}
      </Svg>
    );
  },
);

Icon.displayName = 'Icon';

export default Icon;
