/** @jsx jsx */
import { Fragment, useState } from 'react';
import { Auth, Logger } from 'aws-amplify';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {
  jsx,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  Divider,
  useThemeUI,
} from '@uniteplus/rhenus-design-system';
import S3 from 'aws-sdk/clients/s3';

import config from '../config';
import { useTranslation } from 'react-i18next';

const logger = new Logger('Proof of Delivery');

const DefaultTrigger = ({ onClick = () => {}, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <a
      href="#pod"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      style={{
        display: 'flex',
        color: theme.colors.primary,
        width: '100%',
        alignItems: 'center',
      }}
      {...props}
    >
      {props.children}
      <svg viewBox="0 0 24 24" style={{ height: 11, marginLeft: 5 }}>
        <g fill="currentColor">
          <path d="M6.40644318,24 C6.70089542,24 6.99228843,23.9716255 7.28100459,23.914498 C9.34217033,23.5089312 10.4228483,21.7788408 10.4293492,21.761816 L16.5180098,13.5611948 C16.7703975,13.2218352 16.6965932,12.7436296 16.3528107,12.4939336 C16.0090281,12.2442377 15.5268147,12.3176331 15.2740447,12.6577493 L9.14752592,20.9147414 C9.14026021,20.9264695 8.35977056,22.1458182 6.97966905,22.4174572 C5.97470735,22.6168356 4.85808324,22.2744495 3.66115397,21.4046751 C0.152965765,18.8558845 1.98277616,16.1603029 2.20380655,15.8599111 L12.2790447,2.27834148 C12.2836336,2.27228825 12.7115453,1.68928597 13.4905054,1.55573645 C14.2036917,1.4327801 15.0235691,1.70404073 15.9482256,2.37557158 C16.3084516,2.62224094 17.2774672,3.46704567 17.4342535,4.39583905 C17.5107346,4.84756177 17.3898944,5.27998981 17.0644673,5.71809275 L8.06340628,17.8427242 C8.05958223,17.8480207 7.65729162,18.3844888 7.06418067,18.4817189 C6.68942326,18.5414946 6.30319369,18.4208082 5.87681154,18.1113365 C4.61028447,17.190488 5.26763955,16.0751793 5.39689261,15.8829891 L10.4132882,9.12568621 C10.6652934,8.78632667 10.5918716,8.30812104 10.248089,8.05842506 C9.90430644,7.80872909 9.42132827,7.88136792 9.16894063,8.22224076 L4.14069049,14.9954333 C3.44012359,15.9934606 3.08142721,17.974382 4.96209754,19.3416567 C5.73111503,19.9000677 6.52154724,20.1160925 7.3161859,19.9878396 C8.54141318,19.7873262 9.25154022,18.8256183 9.31731397,18.7314148 L18.3080501,6.62191653 C18.8862472,5.84294074 19.1042184,5.00608089 18.953933,4.13517158 C18.6514502,2.3744366 16.8954441,1.1675727 16.8419073,1.13125329 C15.5853228,0.218727985 14.3696557,-0.143709512 13.2254983,0.0511290174 C11.8048619,0.295528415 11.0775266,1.31020207 11.0171065,1.39986563 L0.96366546,14.9519257 C0.913570337,15.018133 -0.260414598,16.6044591 0.0531579252,18.6833673 C0.277247558,20.1690584 1.18393104,21.4988786 2.7479696,22.6353736 C3.99805323,23.5429807 5.22442773,24 6.40644318,24 Z" />
        </g>
      </svg>
    </a>
  );
};

const ProofOfDelivery = ({
  label,
  title,
  pod = {},
  trigger: Trigger = DefaultTrigger,
}: any) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>();

  return (
    <Fragment>
      <Trigger onClick={() => setOpen(true)}>{label}</Trigger>
      <Modal isOpen={isOpen} size="xl" onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent sx={{ fontSize: '12px' }}>
          <ModalHeader>
            <Heading>{title}</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text sx={{ fontSize: 2, fontFamily: 'heading', color: 'black' }}>
              {t(
                'tracking.ProofOfDelivery.deliveryComment',
                'Kommentar zur Lieferung',
              )}
            </Text>
            <Text sx={{ fontSize: 2, fontFamily: 'body', color: 'black' }}>
              {pod?.kommentar}
            </Text>
            <Divider />
            <Text sx={{ fontSize: 1, fontFamily: 'heading', color: 'black' }}>
              {t('tracking.ProofOfDelivery.uploadDate', 'Upload Datum')}
            </Text>
            <Text sx={{ fontSize: 1, fontFamily: 'body', color: 'black' }}>
              {pod?.lieferzeitpunkt &&
                format(parseISO(pod.lieferzeitpunkt), 'dd.MM.yyyy HH:mm:ss')}
            </Text>
            <Divider />
          </ModalBody>
          <ModalFooter sx={{ justifyContent: 'flex-start', pt: 1 }}>
            <Button
              mr={2}
              onClick={async () => {
                const url = await getSignedUrl(pod.filename);
                window.open(url, '_blank');
              }}
            >
              {t('tracking.ProofOfDelivery.showPOD.label', 'POD ansehen')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ProofOfDelivery;

export async function getSignedUrl(key: string) {
  const credentials = await Auth.currentCredentials();
  const s3 = new S3({
    apiVersion: '2006-03-01',
    params: { Bucket: config.POD_BUCKET },
    signatureVersion: 'v4',
    region: 'eu-central-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  logger.debug('file download', { bucket: config.POD_BUCKET, key });

  return s3.getSignedUrl('getObject', { Key: key, Expires: 15 });
}
