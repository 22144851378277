import React from 'react';
import { Flex, Link } from '@uniteplus/rhenus-design-system';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        mt: 1,
        padding: 1,
        bg: 'white',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link
        href="https://www.rhenus.com/de/ch/service-kontakt/impressum/"
        target="_blank"
        sx={{ mr: 3, fontSize: 1 }}
      >
        {t('login.imprint', 'Impressum')}
      </Link>
      <Link
        href="https://www.rhenus.com/de/ch/service-kontakt/impressum/datenschutzerklaerung/"
        target="_blank"
        sx={{ mr: 3, fontSize: 1 }}
      >
        {t('login.dataProtection', 'Datenschutzerklärung')}
      </Link>
    </Flex>
  );
};

export default Footer;
