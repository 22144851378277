export const GRAPHQL = 'https://api.rhenus.ch';

export const PUBLIC_TNT = 'https://tracking.rhenus.ch';
export const LEGACY_TNT = 'https://old-tracking.rhenus.ch';
export const DASHBOARD_MANAGER_URL = '';

export const POD_BUCKET = 'rhenus-dashboard-uploads';

export const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-central-1:fbebe2dd-cc94-4116-89f0-270b7448b849',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_ApuDWa4LJ',
    userPoolWebClientId: '1fqbj6028ra4o1gvjn1c3750bp',
    mandatorySignIn: true,
    cookieStorage: {
      domain: 'dashboard.rhenus.ch',
      path: '/',
      expires: 365,
      secure: true,
    },
  },
};
