/** @jsx jsx */
import React from 'react';
import { Button, jsx, Spinner } from 'theme-ui';

const IconButton: React.FC<any> = ({
  variant,
  children,
  loading = false,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      sx={{ display: 'flex', alignItems: 'center' }}
      {...props}
    >
      {children}
      {loading && <Spinner size={16} sx={{ ml: 1, color: 'inherit' }} />}
    </Button>
  );
};

export default IconButton;
