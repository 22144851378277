import React, { FC } from 'react';
import ReactSelect from 'react-select';
import { Icon, useThemeUI } from '@uniteplus/rhenus-design-system';

export const ClearIndicator = props => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;

  const { theme } = useThemeUI();
  const midRed = theme?.colors?.red[500] || '#E3F0FF';

  return (
    <div {...restInnerProps} ref={ref}>
      <Icon
        mr="2"
        css={{
          '&:hover': {
            color: midRed,
            cursor: 'pointer',
          },
        }}
        color={theme => theme.colors.gray[600] || '#CCCCCC'}
        name="close"
        size="10px"
      />
    </div>
  );
};

export const DropdownIndicator = props => {
  const {} = props;
  return (
    <Icon
      mt="6px"
      css={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      name="arrowDown"
      color={theme => theme.colors.text || '#333333'}
    />
  );
};

export const customStyles = ({ height, theme }: any) => {
  const lightBlue = theme.colors.blue[50] || '#E3F0FF';
  const lightGray = theme.colors.gray[100] || '#EEEEEE';

  return {
    container: provided => ({ ...provided, fontSize: 12 }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        height: '30px',
        padding: '6px 8px',
        backgroundColor: isSelected ? lightBlue : '#fff',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:hover': {
          backgroundColor: isSelected ? lightBlue : lightBlue,
        },
      };
    },
    control: provided => ({
      ...provided,
      color: theme.text,
      borderRadius: 2,
      border: `1px solid ${lightGray}`,
      height: height,
      minHeight: height,
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${lightGray}`,
      },
      '> div': {
        padding: '1px 4px',
      },
    }),
    menu: provided => ({
      ...provided,
      marginTop: '-1px',
      borderTopRightRadius: '0px',
      borderTopLeftRadius: '0px',
      borderTop: 'none',
      boxShadow: '0 2px 2px -1px rgba(0,0,0,0.1)',
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      marginTop: '0px',
      borderRadius: 2,
      border: `1px solid ${lightGray}`,
      '&:active': {
        backgroundColor: lightGray,
      },
    }),
    singleValue: (provided, _state) => ({ ...provided, color: theme.text }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: provided => ({
      ...provided,
      padding: 0,
      height: height,
    }),
    valueContainer: provided => ({ ...provided, height: height }),
  };
};

const Select: FC<any> = ({
  name,
  onChange = () => {},
  height = 25,
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <ReactSelect
      styles={customStyles({ height, theme })}
      components={{ ClearIndicator, DropdownIndicator }}
      name={name}
      onChange={value => onChange({ target: { name, value } })}
      {...props}
    />
  );
};

export default Select;
