import styled from '@emotion/styled';

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  font-size: 12px;
  background-color: #fff;
  position: relative;
  overflow: hidden;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
    -ms-overflow-style: none;
  }

  .tableWrap::-webkit-scrollbar {
    display: none;
  }

  .virtualList {
    -ms-overflow-style: none;
  }

  .virtualList::-webkit-scrollbar {
    display: none;
  }

  .table {
    width: 100%;
    border-spacing: 0;
    height: 100%;

    .header-tr {
      font-family: 'TradeGothicBold', Arial, sans-serif;
      font-weight: 700;
      height: 50px;
      border-top: 1px solid #e3e3e3;
    }

    .table-body {
      height: calc(100% - 50px);
    }

    .th,
    .td {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 15px;
      border-bottom: 1px solid #e3e3e3;
      background: white;
      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .resizer {
        display: inline-block;
        background: #e3e3e3;
        width: 4px;
        height: 30px;
        margin: 10px 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: #7aa9de;
        }
      }
    }
  }
`;

export default Styles;
