const rules = {
  Mitarbeiter: {
    static: [
      'dashboard:visit',
      'transports:visit',
      'warehouse:visit',
      'dossier-status:update',
      'dossier-status:updateToNotDelivered',
      'track-and-trace-status:update',
      'attached-files:display',
    ],
    dynamic: {},
  },
  Kunde: {
    static: ['dashboard:visit', 'transports:visit', 'warehouse:visit', 'attached-files:display'],
    dynamic: {},
  },
  Dienstleister: {
    static: [
      'dashboard:visit',
      'dossier-status:update',
      'track-and-trace-status:update',
    ],
    dynamic: {},
  },
};

export default rules;
