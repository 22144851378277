import React from 'react';
import { Flex, Text } from 'theme-ui';

type Props = { value: string | number; subnote?: string; onClick?: () => void };

const StatEntry: React.FC<Props> = ({
  value = '0',
  subnote,
  onClick = () => {},
}: Props) => {
  return (
    <Flex sx={{ alignItems: 'flex-end' }}>
      <Text
        onClick={onClick}
        sx={{
          mt: 1,
          px: '7px',
          py: '5px',
          fontSize: 6,
          border: '1px solid',
          borderColor: 'gray.300',
          width: '70px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        {value}
      </Text>
      <Text sx={{ fontSize: 0, px: '5px', py: '7px' }}>{subnote}</Text>
    </Flex>
  );
};

export default StatEntry;
