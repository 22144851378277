import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Logger } from 'aws-amplify';
import ReactGA from 'react-ga';

import { isDev, gaTrackingID } from './config';

const logger = new Logger('usePageViews');

export function initGA() {
  if (!isDev) {
    logger.debug('<<<<< GA >>>>>');
    ReactGA.initialize(gaTrackingID);
  }
}

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    if (!isDev) {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
}

export default usePageViews;
