import React, { useState, useEffect, createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Flex } from '@uniteplus/rhenus-design-system';

import Header from './Header';
import Filters from './Filters';
import Debug from './Debug';
import Table from './Table';
import Details from './Details';
import { mapFilters } from './utils';
import useSpace from './useSpace';

const ReportContext = createContext<any>({});
export const useReportContext = () => useContext(ReportContext);

export const reportLegend = {
  subtitle: () => {},
  showFilters: 'Filter anzeigen',
  hideFilters: 'Filter ausblenden',
  applyFilters: 'Jetzt filtern',
  resetFilters: 'Filter zurücksetzen',
  clearFilters: 'Filter entfernen',
  csvExport: `CSV Export`,
  actionButton: 'Ausgewählte Zeilen bearbeiten',
};

function Report({
  query,
  columns: reportColumns,
  isSelectable = false,
  filters: reportFilters = [],
  filtersToVariables = mapFilters,
  filterState: reportFilterState,
  filterHeightOverride,
  limit: pageSize = 50,
  debug = false,
  gridColumns = [1, 2, 3],
  onFilterStateChange = () => {},
  resetFilters = () => {},
  onAction,
  legend = {},
  styles = {},
  minTableHeight = 400,
  details = Details,
  buttons = { apply: true, reset: false, clear: true },
  messages = {},
}: any) {
  const initialFilterState =
    reportFilterState ||
    reportFilters.reduce(
      (acc, { name, initialValue }) => ({
        ...acc,
        [name]: initialValue || null,
      }),
      {},
    );
  const clearedFilterState = reportFilters.reduce(
    (acc, { name, initialValue, nullable = true }) => ({
      ...acc,
      [name]: nullable ? null : initialValue,
    }),
    {},
  );
  const [filterState, setFilterState] = useState(initialFilterState);
  const [showFilters, setShowFilters] = useState(reportFilters.length);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    const filterState = reportFilterState || initialFilterState;
    debug && console.log('setFilterState', filterState);
    setFilterState(filterState);
  }, [reportFilterState]);

  if (debug) {
    console.log('filters', {
      filterState,
      reportFilterState,
      initialFilterState,
      clearedFilterState,
      reportFilters,
    });
  }

  const { ref: reportRef, height: reportHeight } = useSpace([]);
  const { ref: filterRef, height: filterHeight } = useSpace([showFilters]);
  const { ref: debugRef, height: debugHeight } = useSpace([filterState]);
  const minHeight = 62 + filterHeight + minTableHeight;
  const tableBodyHeight =
    Math.max(reportHeight, minHeight) - 62 - filterHeight - debugHeight - 60;

  if (debug) {
    console.log('heights', {
      reportHeight,
      minHeight,
      filterHeight,
      debugHeight,
      minTableHeight,
      tableBodyHeight,
    });
  }

  const limit = Math.max(50, pageSize);
  const {
    loading: initialLoading,
    data: queryData,
    fetchMore,
    refetch,
    error,
  } = useQuery<any>(query, {
    variables: { offset: 0, limit, ...filtersToVariables(filterState) },
    fetchPolicy: 'cache-and-network',
  });
  const [loading, setLoading] = useState(initialLoading);

  if (error) {
    console.log('GraphQL error', error);
  }

  const { collection } = queryData || {};
  const count = (collection && collection.count) || 0;

  useEffect(() => setLoading(initialLoading), [initialLoading]);

  const columns = React.useMemo(() => reportColumns, []);
  const data = React.useMemo(
    () => (collection ? collection.rows : []),
    [collection],
  );

  // Are there more items to load?
  const hasNextPage = !initialLoading && data.length < collection?.count;

  const itemCount = data.length + 1;
  const loadMoreItems = loading
    ? () => {}
    : (startIndex: number) => {
        setLoading(true);
        fetchMore({
          variables: { offset: startIndex, limit },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            setLoading(false);
            if (!fetchMoreResult) {
              return previousResult;
            }
            return {
              ...previousResult,
              collection: {
                ...previousResult.collection,
                rows: [
                  ...previousResult.collection.rows,
                  ...fetchMoreResult.collection.rows,
                ],
              },
            };
          },
        });
      };

  const _setFilterState = (state) => {
    setFilterState(state);
    onFilterStateChange(state);
  };

  const updateFilter = ({ target: { name, value } }) =>
    _setFilterState({ ...filterState, [name]: value });

  const clearFilters = () => _setFilterState(clearedFilterState);
  const toggleFilters = () => setShowFilters(!showFilters);
  const applyFilters = () => {
    refetch(filtersToVariables(filterState));
    onFilterStateChange(filterState);
  };

  const context = {
    legend: { ...reportLegend, ...legend },
    isSelectable,
    tableBodyHeight,
    query,
    queryVariables: filtersToVariables(filterState),
    limit,
    // Filters
    buttons,
    reportFilters,
    filterState,
    filterHeightOverride,
    showFilters,
    updateFilter,
    toggleFilters,
    resetFilters,
    clearFilters,
    applyFilters,
    filtersToVariables,
    gridColumns,
    // Selected
    selectedRows,
    setSelectedRows,
    isSelected: !!selectedRows.length,
    onAction: onAction
      ? () => onAction(selectedRows.map((row: any) => row.values))
      : undefined,
    stats: {
      count,
      loading: loading ? limit : 0,
      loaded: data.length,
    },
    // Table
    data,
    columns,
    itemCount,
    hasNextPage,
    loadMoreItems,
    loading,
    Details: details,
    messages,
  };

  return (
    <ReportContext.Provider value={context}>
      <Flex
        ref={reportRef}
        sx={{
          flexDirection: 'column',
          height: '100%',
          flex: '0 0 auto',
          ...styles,
          minHeight,
        }}
      >
        <Header />
        <Filters ref={filterRef} />
        <Debug ref={debugRef} enabled={debug} />
        <Table />
      </Flex>
    </ReportContext.Provider>
  );
}

export default Report;
