/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core';
import preflight from './preflight';

const CSSReset = () => {
  const configCSS = (theme) => {
    const color = theme.colors['text'];
    const bg = theme.colors['background'];
    const borderColor = theme.colors['gray.300'];
    const placeholderColor = theme.colors['gray.300'];

    return css`
      /* prettier-ignore */
      @font-face {
        font-family: 'TradeGothicLT';
        src: url('/fonts/TradeGothicLT.eot'); /* IE9 Compat Modes */
        src: url('/fonts/TradeGothicLT.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('/fonts/TradeGothicLT.woff') format('woff'), /* Pretty Modern Browsers */
             url('/fonts/TradeGothicLT.ttf') format('truetype'), /* Safari, Android, iOS */
             url('/fonts/TradeGothicLT.svg#svgFontName') format('svg'); /* Legacy iOS */
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: 'TradeGothicRegular';
        src: url('/fonts/2FE5FF_1_0.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
      }
      /* prettier-ignore */
      @font-face {
        font-family: 'TradeGothicBold';
        src: url('/fonts/TradeGothicLTStd-Bold.eot'); /* IE9 Compat Modes */
        src: url('/fonts/TradeGothicLTStd-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
             url('/fonts/2FE5FF_2_0.woff2') format('woff2'), /* Pretty Modern Browsers */
             url('/fonts/TradeGothicLTStd-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
        font-weight: normal;
        font-style: normal;
      }

      html {
        line-height: 1.5;
        color: ${color};
      }

      html,
      body {
        background: ${bg};
        min-height: 100vh;
        height: 100%;
        -ms-overflow-style: none;
        overflow: auto;
      }

      /**
      * Allow adding a border to an element by just adding a border-width.
      */

      *,
      *::before,
      *::after {
        border-width: 0;
        border-style: solid;
        border-color: ${borderColor};
      }

      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        color: ${placeholderColor};
      }

      input::-ms-input-placeholder,
      textarea::-ms-input-placeholder {
        color: ${placeholderColor};
      }

      input::placeholder,
      textarea::placeholder {
        color: ${placeholderColor};
      }
    `;
  };

  return (
    <Global styles={(theme) => css([preflight(theme), configCSS(theme)])} />
  );
};

export default CSSReset;
