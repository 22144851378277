import { gql } from 'apollo-boost';

export const query = gql`
  query WarehouseQuery(
    $warehouseType: WarehouseType
    $auftragsnummer: Int
    $startDate: String
    $endDate: String
    $location: String
    $status: String
    $kundeNr: Int
    $kundeName: String
    $kundePlz: String
    $kundeOrt: String
    $kundeLand: String
    $empfaengerNr: Int
    $empfaengerName: String
    $empfaengerPlz: String
    $empfaengerOrt: String
    $empfaengerLand: String
    $absenderNr: Int
    $absenderName: String
    $absenderPlz: String
    $absenderOrt: String
    $absenderLand: String
    $offset: Int
    $limit: Int
  ) {
    collection: warehouse(
      warehouseType: $warehouseType
      auftragsnummer: $auftragsnummer
      startDate: $startDate
      endDate: $endDate
      rhenusStandort: $location
      status: $status
      kundeNr: $kundeNr
      kundeName: $kundeName
      kundePlz: $kundePlz
      kundeOrt: $kundeOrt
      kundeLand: $kundeLand
      empfaengerNr: $empfaengerNr
      empfaengerName: $empfaengerName
      empfaengerPlz: $empfaengerPlz
      empfaengerOrt: $empfaengerOrt
      empfaengerLand: $empfaengerLand
      absenderNr: $absenderNr
      absenderName: $absenderName
      absenderPlz: $absenderPlz
      absenderOrt: $absenderOrt
      absenderLand: $absenderLand
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        warehouseType
        auftragsnummer
        dossier
        kundeNr
        kundeName
        kundePlz
        kundeOrt
        kundeLand
        empfaengerNr
        empfaengerName
        empfaengerPlz
        empfaengerOrt
        empfaengerLand
        absenderNr
        absenderName
        absenderPlz
        absenderOrt
        absenderLand
        absenderReferenz
        auftragsdatum
        lieferdatum
        zusaetzlicheReferenzen
        leistungsdatum
        status
        versandart
        stueck
        positionen
        rhenusStandortId
        rhenusStandort
        transportExists
      }
    }
  }
`;
