import React, { Fragment } from 'react';
import { Link as NavLink } from 'react-router-dom';
import { Link } from '@uniteplus/rhenus-design-system';

import { ProofOfDelivery } from '../../../components';

import { StatusCircle } from '../dossier/columns';
import { Translation } from 'react-i18next';

export const columns = [
  {
    Header: 'Sendungsverfolgung',
    accessor: 'lieferId',
    width: 150,
    resizable: true,
    disableSortBy:true,
    Cell: ({ cell }) => (
      // @ts-ignore
      <Link as={NavLink} to={`/lieferung/${cell.value}`}>
        <Translation>
          {(t) => t('transports.lieferungen.lieferId', 'Sendungsverfolgung')}
        </Translation>
      </Link>
    ),
  },
  {
    Header: 'Typ',
    accessor: 'lieferung.typ',
    width: 150,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Lief.-Kennzeichen',
    accessor: 'lieferung.kennzeichen',
    width: 150,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => (
      <Fragment>
        {cell.value.substr(0, 3) === '608' &&
          (cell.value.length === 13 || cell.value.length === 14)
          ? '0' + cell.value
          : cell.value}
      </Fragment>
    ),
  },
  {
    Header: 'Auftragsnr.',
    accessor: 'lieferung.auftragsNr',
    width: 150,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Transport Abgangsdatum',
    accessor: 'lieferung.transportAbgangsdatum',
    width: 170,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Geplantes Ankunftsdatum',
    accessor: 'lieferung.geplantesAnkunftsdatum',
    width: 170,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Event Status Gruppe',
    accessor: 'statusCode.statusGruppe.name',
    width: 150,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => (
      <Fragment>
        <StatusCircle
          idStatusGruppe={row.original.statusCode?.statusGruppe?.id}
        />
        {cell.value}
      </Fragment>
    ),
  },
  {
    Header: 'Event Status',
    accessor: 'status',
    width: 200,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) =>
      row.original.lieferung.pod ? (
        <ProofOfDelivery
          label={cell.value}
          title={cell.value}
          pod={row.original.lieferung.pod}
        />
      ) : (
        <Fragment>{cell.value}</Fragment>
      ),
  },
  {
    Header: 'Event Status Code',
    accessor: 'statusCode.statuscode',
    width: 150,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Event Datum',
    accessor: 'statusDate',
    width: 150,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Event Uhrzeit',
    accessor: 'statusTime',
    width: 150,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Gewicht',
    accessor: 'lieferung.weight',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Abgangsort',
    accessor: 'lieferung.departurePlace',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Bestimmungort',
    accessor: 'lieferung.destinationPlace',
    width: 100,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Frachtführer',
    accessor: 'lieferung.frachtfuehrer',
    i18n: 'lieferung.frachtfuehrer.name',
    width: 170,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => (
      <Fragment>
        {cell.value.map(frachtfuehrer => frachtfuehrer.name).join(', ')}
      </Fragment>
    ),
  },
  {
    Header: 'Frachtführer-Gruppe',
    accessor: 'lieferung.frachtfuehrer[0]',
    i18n: 'lieferung.frachtfuehrer.gruppe.name',
    width: 170,
    resizable: true,
    disableSortBy:true,
    Cell: ({ row, cell }) => {
      if (!row?.original?.lieferung?.frachtfuehrer) { return '' }
      const frachtfuehrerGruppen = row.original.lieferung.frachtfuehrer.map(frachtfuehrer => frachtfuehrer.gruppe.name)
      return (
        <Fragment>
          {frachtfuehrerGruppen.filter((item, index) => frachtfuehrerGruppen.indexOf(item) === index).join(', ')}
        </Fragment>
      )
    },
  },
  {
    Header: 'Empfänger Name',
    accessor: 'lieferung.empfaenger.name',
    width: 250,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Empfänger Strasse',
    accessor: 'lieferung.empfaenger.strasse',
    width: 250,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Empfänger PLZ',
    accessor: 'lieferung.empfaenger.plz',
    width: 130,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Empfänger Ort',
    accessor: 'lieferung.empfaenger.ort',
    width: 200,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Empfänger Land',
    accessor: 'lieferung.empfaenger.land',
    width: 200,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Absender Name',
    accessor: 'lieferung.absender.name',
    width: 250,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Absender Strasse',
    accessor: 'lieferung.absender.strasse',
    width: 250,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Absender PLZ',
    accessor: 'lieferung.absender.plz',
    width: 130,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Absender Ort',
    accessor: 'lieferung.absender.ort',
    width: 200,
    resizable: true,
    disableSortBy:true,
  },
  {
    Header: 'Absender Land',
    accessor: 'lieferung.absender.land',
    width: 200,
    resizable: true,
    disableSortBy:true,
  },
];
