/** @jsx jsx */
import { jsx, Box } from '@uniteplus/rhenus-design-system';

const Drawer = ({ open, children }) => (
  <Box
    sx={{
      color: 'rgb(77, 77, 77)',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 'auto',
      height: '100%',
      width: 320,
      transform: `translateX(${open ? 0 : 100}%)`,
      zIndex: 1000,
      background: 'rgb(255, 255, 255)',
      transition: 'transform 0.4s ease 0s',
    }}
  >
    {children}
  </Box>
);

export default Drawer;
