import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de-DE.json';
import en from './locales/en-EN.json';
import fr from './locales/fr-FR.json';
import it from './locales/it-IT.json';

import trackingDE from './locales/tracking/de-DE.json';
import trackingEN from './locales/tracking/en-EN.json';
import trackingFR from './locales/tracking/fr-FR.json';
import trackingIT from './locales/tracking/it-IT.json';

import amplifyDE from './locales/amplify/de-DE.json';
import amplifyEN from './locales/amplify/en-EN.json';
import amplifyFR from './locales/amplify/fr-FR.json';
import amplifyIT from './locales/amplify/it-IT.json';

const resources = {
  de_DE: { translation: { ...de, ...trackingDE, ...amplifyDE } },
  en_EN: { translation: { ...en, ...trackingEN, ...amplifyEN } },
  fr_FR: { translation: { ...fr, ...trackingFR, ...amplifyFR } },
  it_IT: { translation: { ...it, ...trackingIT, ...amplifyIT } },
};

i18n.use(initReactI18next).init({
  resources,
  // lng: 'de_DE',
  fallbackLng: 'en_EN',
  debug: true,

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  react: {
    useSuspense: false,
  },
});

export default i18n;
