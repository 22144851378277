/** @jsx jsx */
import React from 'react';
import { Button, jsx } from 'theme-ui';
import Icon from './Icon';

const IconButton: React.FC<any> = ({
  variant,
  iconBefore,
  iconAfter,
  children,
  ...props
}) => {
  return (
    <Button variant={variant} {...props}>
      {iconBefore && <Icon name={iconBefore} sx={{ mr: 1 }} />}
      {children}
      {iconAfter && <Icon name={iconAfter} sx={{ ml: 1 }} />}
    </Button>
  );
};

export default IconButton;
