import * as development from './config.development';
import * as staging from './config.staging';
import * as production from './config.production';

const config = {
  development,
  staging,
  production,
};

export const env =
  process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';
export const isProd = env === 'production';
export const isDev = env === 'development';
export const isStaging = env === 'staging';

export const gaTrackingID = 'UA-157271012-1';

export const LEGACY_TNT = config[env].LEGACY_TNT;
export const PUBLIC_TNT = config[env].PUBLIC_TNT;
export const DASHBOARD_MANAGER_URL = config[env].DASHBOARD_MANAGER_URL;

export const amplifyConfig = config[env].amplifyConfig || {};

export default config[env] as any;
