import { useState, useRef, useCallback, useEffect } from 'react';

function getBoundingClientRect(
  element: HTMLElement,
): ClientRect | DOMRect | null {
  return element.getBoundingClientRect();
}

const useSpace = (deps: any[]) => {
  const [value, setValue] = useState<ClientRect | DOMRect | null>(null);
  const ref = useRef<any>();

  const update = useCallback(() => {
    setValue(ref.current ? getBoundingClientRect(ref.current) : null);
  }, [ref]);

  useEffect(update, [ref, ...deps]);

  useEffect(() => {
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, []);

  const { width = 0, height = 0 } = value || {};

  return { ref, width, height, updateHeight: update };
};

export default useSpace;
