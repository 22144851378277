import React from 'react';
import { gql } from 'apollo-boost';
import { Select } from '@uniteplus/rhenus-reports';
import { useQuery } from '@apollo/react-hooks';

const locationsQuery = gql`
  query locations {
    locations {
      location
    }
  }
`;

const Locations = props => {
  const { data } = useQuery(locationsQuery);
  const { locations = [] } = data || {};
  const options = locations.map(({ location }) => ({
    value: location,
    label: location,
  }));
  return <Select {...props} options={options} />;
};

export default Locations;
