import React, { useRef, FC } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Input, IconButton } from '@uniteplus/rhenus-design-system';
import './registerLocales';

const DATE_FORMAT = 'dd.MM.yyyy';

const DateRangePicker: FC<any> = ({
  name,
  value,
  placeholders = { startDate: 'Startdatum', endDate: 'Enddatum' },
  onChange = () => { },
  locale = 'de',
}) => {
  console.log('DateRangePicker', { name, locale });
  const { startDate, endDate } = value || {};
  const endDateRef = useRef<any>();

  const _onChange = (value) =>
    onChange({
      target: {
        name,
        value,
      },
    });

  return (
    <Flex
      sx={{
        alignItems: 'center',
      }}
    >
      <DatePicker
        locale={locale}
        customInput={<Input />}
        shouldCloseOnSelect={true}
        dateFormat={DATE_FORMAT}
        selected={startDate}
        onChange={(value) => {
          _onChange({
            startDate: value,
            endDate,
          });
        }}
        onCalendarClose={() => {
          endDateRef?.current?.setFocus(true);
        }}
        placeholderText={placeholders.startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        locale={locale}
        customInput={<Input ml={1} />}
        ref={endDateRef}
        dateFormat={DATE_FORMAT}
        selected={endDate}
        onChange={(value) =>
          _onChange({
            startDate,
            endDate: value,
          })
        }
        placeholderText={placeholders.endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
      <IconButton
        variant="primary"
        iconBefore="close"
        pl="6px"
        pr="7px"
        py="3px"
        sx={{
          height: '25px',
          width: '28px',
          background: 'none',
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: (theme) => theme.colors.gray[200] || '#333333',
          },
          color: 'text',
        }}
        ml={2}
        onClick={() =>
          _onChange({
            startDate: null,
            endDate: null,
          })
        }
      />
    </Flex>
  );
};

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;
