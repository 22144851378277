import React, { FC, forwardRef } from 'react';
// https://reactdatepicker.com/
import ReactDatePicker from 'react-datepicker';
import { Input } from '@uniteplus/rhenus-design-system';
import './registerLocales';

const DATE_FORMAT = 'dd.MM.yyyy';

const DatePicker: FC<any> = forwardRef(
  ({ name, value, placeholder, onChange = () => { }, ...props }, ref) => {
    return (
      <ReactDatePicker
        ref={ref}
        customInput={<Input style={props?.style || {}} />}
        dateFormat={DATE_FORMAT}
        placeholderText={placeholder}
        selected={value}
        onChange={(value) => onChange({ target: { name, value } })}
        {...props}
      />
    );
  },
);
DatePicker.displayName = 'DatePicker';

export default DatePicker;
