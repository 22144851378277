import React from 'react';
import { Container, Flex, Heading } from '@uniteplus/rhenus-design-system';
import { logError } from '../errors';
import { Translation } from 'react-i18next';

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <Container>
        <Flex sx={{ justifyContent: 'center', my: 4 }}>
          <Heading>
            <Translation>
              {(t) =>
                t(
                  'errorBoundary.msg',
                  'Beim Laden dieser Seite ein Problem aufgetreten',
                )
              }
            </Translation>
          </Heading>
        </Flex>
      </Container>
    ) : (
      this.props.children
    );
  }
}
