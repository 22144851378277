import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Stat,
  StatEntry,
  Spinner,
} from '@uniteplus/rhenus-design-system';

import { StorageStatsQuery } from '../__generated__/StorageStatsQuery';

const storageStatsQuery = gql`
  query StorageStatsQuery {
    stats {
      ordersInDay
      ordersOutDay
      openOrdersInDay
      openOrdersOutDay
    }
  }
`;

const StoragePerformance = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data, loading } = useQuery<StorageStatsQuery>(storageStatsQuery);

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!data) {
    return null;
  }

  const {
    ordersInDay,
    ordersOutDay,
    openOrdersInDay,
    openOrdersOutDay,
  } = data.stats;

  return (
    <Flex sx={{ pl: 8, pr: 8 }}>
      <Stat
        icon="arrowUp"
        label={t(
          'dashboard.storagePerformance.goodsReceipt.mapLegend.title',
          'Wareneingang',
        )}
        missingData={t(
          'dashboard.storagePerformance.missingData.desc',
          'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
        )}
      >
        <StatEntry
          value={ordersInDay}
          onClick={() => history.push('/warehouse/in/ordersInDay')}
        />
        <StatEntry
          value={openOrdersInDay}
          subnote={t(
            'dashboard.storagePerformance.goodsReceipt.mapLegend.undelivered',
            'offen',
          )}
          onClick={() => history.push('/warehouse/in/openOrdersInDay')}
        />
      </Stat>
      <Stat
        ml={[1, 2, 4]}
        icon="arrowUp"
        label={t(
          'dashboard.storagePerformance.goodsIssue.mapLegend.title',
          'Warenausgang',
        )}
        missingData={t(
          'dashboard.storagePerformance.missingData.desc',
          'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
        )}
      >
        <StatEntry
          value={ordersOutDay}
          onClick={() => history.push('/warehouse/out/ordersOutDay')}
        />
        <StatEntry
          value={openOrdersOutDay}
          subnote={t(
            'dashboard.storagePerformance.goodsIssue.mapLegend.undelivered',
            'offen',
          )}
          onClick={() => history.push('/warehouse/out/openOrdersOutDay')}
        />
      </Stat>
    </Flex>
  );
};

export default StoragePerformance;
