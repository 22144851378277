/** @jsx jsx */
import { jsx, Flex, Spinner } from '@uniteplus/rhenus-design-system';

interface LoadingProps {
  loading: boolean;
  height: string | number;
}

const Loading = ({ loading, height = '100%' }: LoadingProps) => {
  if (!loading) {
    return null;
  }

  return (
    <Flex
      sx={{
        position: 'absolute',
        height,
        width: '100%',
        zIndex: 10,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.64)',
      }}
    >
      <Spinner size={64} />
    </Flex>
  );
};

export default Loading;
