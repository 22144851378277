/** @jsx jsx */
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  BrandLogo,
  Flex,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Text,
  Divider,
  Heading,
  Link as UILink,
  List,
  ListItem,
  IconButton,
  jsx,
  Box,
} from '@uniteplus/rhenus-design-system';
import { useAuth } from '../identity-context';
import Can from './Can';
import AttachedFiles from './AttachedFiles';
import PublicTntLink from './PublicTntLink';
import { LanguageSelect } from './LanguageSelect';
import { DASHBOARD_MANAGER_URL } from '../config';

const Header = () => {
  const { user } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const dividerStyles = { my: 4 };
  const { t } = useTranslation();

  const close = () => setOpen(false);

  return (
    <Fragment>
      <Flex
        sx={{
          px: 5,
          py: 3,
          bg: 'white',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '56px',
        }}
      >
        <Icon
          name="burger"
          color="primary"
          size="24px"
          sx={{ cursor: 'pointer' }}
          onClick={() => setOpen(true)}
        />
        <BrandLogo size="md" color="primary" />
      </Flex>
      <Drawer placement="left" onClose={() => setOpen(false)} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <BrandLogo size="sm" />
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody
            sx={{
              '& > h2': {
                fontSize: 1,
                pb: 2,
                color: 'rgba(255, 255, 255, 0.25)',
                fontWeight: 400,
              },
            }}
          >
            <Divider sx={{ ...dividerStyles, mt: 0 }} />
            <Text sx={{ fontSize: 15 }}>{user.name}</Text>
            <Divider sx={dividerStyles} />
            <Heading>{t('sidebar.menu.dashboard.title', 'Dashboard')}</Heading>
            <Box
              as="ul"
              sx={{
                listStyleType: 'none',
                listStylePosition: 'inside',
                '& > li': { mb: 2 },
                '& > li > a': { color: 'white' },
              }}
            >
              <Box as="li">
                <Link to="/" onClick={close}>
                  {t('sidebar.menu.dashboard.link', 'Dashboard')}
                </Link>
              </Box>
              <Can role={user.role} perform="transports:visit">
                <Box as="li">
                  <Link to="/transports" onClick={close}>
                    {t('sidebar.menu.transports.link', 'Transporte')}
                  </Link>
                </Box>
              </Can>
              <Can role={user.role} perform="transports:visit">
                <Box as="li">
                  <Link to="/regellaufzeitauswertung" onClick={close}>
                    {t(
                      'sidebar.menu.standardDeliveryTimeEvaluation.link',
                      'Regellaufzeitauswertung',
                    )}
                  </Link>
                </Box>
              </Can>
              <Can role={user.role} perform="warehouse:visit">
                <Box as="li">
                  <Link to="/warehouse/in" onClick={close}>
                    {t('sidebar.menu.goodsReceipt.link', 'Wareneingang')}
                  </Link>
                </Box>
                <Box as="li">
                  <Link to="/warehouse/out" onClick={close}>
                    {t('sidebar.menu.goodsIssue.link', 'Warenausgang')}
                  </Link>
                </Box>
              </Can>
            </Box>
            <Can role={user.role} perform="attached-files:display">
              <AttachedFiles dividerStyles={dividerStyles} />
            </Can>
            <Divider sx={dividerStyles} />
            <Heading>
              {t('sidebar.menu.externalSites.title', 'Externe Seiten')}
            </Heading>
            <List spacing={2}>
              <ListItem>
                <PublicTntLink user={user} t={t} />
              </ListItem>
              {DASHBOARD_MANAGER_URL && (
                <ListItem>
                  <UILink href={DASHBOARD_MANAGER_URL} target="_blank" rel="noopener noreferrer">
                    Dashboard-Manager
                  </UILink>
                </ListItem>
              )}
            </List>
            <Divider sx={dividerStyles} />
            <Heading>{t('sidebar.menu.language.title', 'Sprache')}</Heading>
            <LanguageSelect />
            <Divider sx={dividerStyles} />
            <IconButton
              variant="ghost"
              iconBefore="logOut"
              sx={{ padding: 0 }}
              onClick={() => Auth.signOut().catch((err) => console.error(err))}
            >
              {t('sidebar.menu.logout', 'Logout')}
            </IconButton>
          </DrawerBody>
        </DrawerContent>
      </Drawer >
    </Fragment >
  );
};

export default Header;
