import sizes, { baseSizes } from '../sizes';
import typography from '../typography';
import colors from './colors';
import icons from '../icons';

const space = baseSizes;

const heading = {
  color: 'primary',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};

const button = {
  lineHeight: 'normal',
  fontWeight: 'bold',
  fontSize: 1,
  padding: '7px 14px',
  border: '1px solid transparent',
  borderRadius: 2,
  outline: 'none',
  cursor: 'pointer',
};

const theme = {
  space,
  sizes,
  ...typography,
  fontSizes: [14, 16, 13, 18, 22, 26, 28, 38],
  zIndices,
  colors,
  icons,
  // https://theme-ui.com/color-modes/#turn-off-custom-properties
  useCustomProperties: false,
  borders: [1],
  buttons: {
    primary: {
      ...button,
      fontFamily: 'TradeGothicBold',
      bg: 'primary',
      color: 'white',
      ':disabled': {
        bg: 'gray.300',
      },
    },
    secondary: {
      ...button,
      fontFamily: 'TradeGothicLT',
      bg: 'yellow.500',
      color: 'white',
      ':disabled': {
        color: 'gray.600',
        bg: 'gray.300',
      },
    },
    ghost: {
      ...button,
      bg: 'transparent',
      fontFamily: 'TradeGothicBold',
    },
  },
  radii: {
    none: 0,
    small: 2,
  },
  text: {
    heading: {
      fontSize: 6,
      color: 'primary',
      fontWeight: '700',
    },
    'heading-drawer': {
      border: '1px solid red.500',
    },
  },
  forms: {
    label: {
      fontSize: 1,
      color: 'blue.200',
      marginBottom: 1,
    },
    input: {
      fontSize: 1,
      color: 'muted',
      outline: 'none',
      borderRadius: 2,
      borderColor: 'gray.300',
      lineHeight: '24px',
      height: 25,
      '&::placeholder': {
        color: 'gray.300',
      },
    },
  },
  modals: {
    overlay: {
      bg: 'rgba(255,255,255,0.4)',
    },
    modal: {
      bg: 'white',
      shadow: '0 7px 14px 0 rgba(0,0,0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)',
    },
    drawer: {
      bg: 'rgba(77, 77, 77, 0.95)',
      color: 'white',
      fontSize: 2,
    },
  },
  drawers: {
    overlay: {
      bg: 'rgba(255,255,255,0.4)',
    },
    drawer: {
      bg: 'rgba(77, 77, 77, 0.95)',
      color: 'white',
      fontSize: 2,
    },
    closeButton: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },
  },
  charts: {
    grid: {
      stroke: 'rgba(71, 71, 71, 0.1)',
      // strokeDasharray: '3 3',
    },
    fillColors: {
      uv: '#7aa9de',
      pv: '#FFBE33',
      tooltipCursor: '#eaeaea',
    },
  },
  messages: {
    error: {
      fontSize: 1,
      color: 'red.500',
      border: 'none',
      background: 'none',
      padding: 0,
      marginBottom: 2,
    },
  },
  styles: {
    icon: {
      small: {
        width: '12px',
        height: '12px',
      },
      medium: {
        width: '18px',
        height: '18px',
      },
      large: {
        width: '24px',
        height: '24px',
      },
    },
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      bg: '#eee',
    },
    h1: {
      ...heading,
      fontSize: 7,
    },
    h2: {
      ...heading,
      fontSize: 6,
    },
    h3: {
      ...heading,
      fontSize: 5,
    },
    h4: {
      ...heading,
      fontSize: 4,
    },
    h5: {
      ...heading,
      fontSize: 3,
    },
    p: {
      color: 'primary',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
      fontSize: 1,
      textDecoration: 'none',
    },
    hr: {
      opacity: 0.13,
    },
  },
};

export default theme;
