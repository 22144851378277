import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {
  Box,
  Text,
  Spinner,
} from '@uniteplus/rhenus-design-system';
import { PieChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TransportDistributionQuery } from '../__generated__/TransportDistributionQuery';

const transportDistributionQuery = gql`
  query TransportDistributionQuery {
    kurier: transportsByCarrier(
      servicetype: Kurier
      searchScope: International
    ) {
      dataKey
      dv1Int
    }
    lkw: transportsByCarrier(servicetype: Lkw, searchScope: International) {
      dataKey
      dv1Int
    }
    seefracht: transportsByCarrier(servicetype: Seefracht, searchScope: International) {
      dataKey
      dv1Int
    }
    luftfracht: transportsByCarrier(servicetype: Luftfracht, searchScope: International) {
      dataKey
      dv1Int
    }
    paket: transportsByCarrier(servicetype: Paket, searchScope: International) {
      dataKey
      dv1Int
    }
    frachtfuehrerGruppen {
      id
      name
    }
  }
`;

function TransportDistribution() {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useQuery<TransportDistributionQuery>(
    transportDistributionQuery,
  );
  const missingData = {
    missingData: t(
      'pieChart.messages.missingData.desc',
      'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
    ),
  };

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!data || (!data.kurier && !data.lkw && !data.seefracht && !data.luftfracht && !data.paket)) {
    return <div>{t('transportDistribution.noData', 'Keine Daten')}</div>;
  }

  const {
    kurier = [],
    lkw = [],
    seefracht = [],
    luftfracht = [],
    paket = [],
    frachtfuehrerGruppen = []
  } = data;

  return (
    <Box sx={{ padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
        <PieChartWrapper>
          <Text>
            {t(
              'dashboard.transportDistribution.paket',
              'Paket',
            )}
          </Text>
          <PieChart
            height={220}
            fill="#7aa9de"
            messages={missingData}
            data={paket.map((ds) => ({
              name: ds.dataKey,
              value: ds.dv1Int,
            }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${frachtfuehrerGruppen.find((element) => element.name === name)
                  .id
                }`,
              )
            }
          />
        </PieChartWrapper>
        <PieChartWrapper>
          <Text>
            {t(
              'dashboard.transportDistribution.kurier',
              'Kurier',
            )}
          </Text>
          <PieChart
            height={220}
            fill="#7aa9de"
            messages={missingData}
            data={kurier.map((ds) => ({ name: ds.dataKey, value: ds.dv1Int }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${frachtfuehrerGruppen.find((element) => element.name === name)
                  .id
                }`,
              )
            }
          />
        </PieChartWrapper>
        <PieChartWrapper>
          <Text>
            {t(
              'dashboard.transportDistribution.lkw',
              'Lkw',
            )}
          </Text>
          <PieChart
            height={220}
            fill="#7aa9de"
            messages={missingData}
            data={lkw.map((ds) => ({
              name: ds.dataKey,
              value: ds.dv1Int,
            }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${frachtfuehrerGruppen.find((element) => element.name === name)
                  .id
                }`,
              )
            }
          />
        </PieChartWrapper>
        <PieChartWrapper>
          <Text>
            {t(
              'dashboard.transportDistribution.seefracht',
              'Seefracht',
            )}
          </Text>
          <PieChart
            height={220}
            fill="#7aa9de"
            messages={missingData}
            data={seefracht.map((ds) => ({
              name: ds.dataKey,
              value: ds.dv1Int,
            }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${frachtfuehrerGruppen.find((element) => element.name === name)
                  .id
                }`,
              )
            }
          />
        </PieChartWrapper>
        <PieChartWrapper>
          <Text>
            {t(
              'dashboard.transportDistribution.luftfracht',
              'Luftfracht',
            )}
          </Text>
          <PieChart
            height={220}
            fill="#7aa9de"
            messages={missingData}
            data={luftfracht.map((ds) => ({
              name: ds.dataKey,
              value: ds.dv1Int,
            }))}
            onClick={({ name }) =>
              history.push(
                `/transports/carrierGroup/${frachtfuehrerGruppen.find((element) => element.name === name)
                  .id
                }`,
              )
            }
          />
        </PieChartWrapper>
      </div>
    </Box >
  );
};

function PieChartWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 350
      }}
    >
      {children}
    </div>
  )
}

export default TransportDistribution;
