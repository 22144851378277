import _ from 'lodash';

export enum TransportType {
  TRUCK,
  SHIP,
  AIRPLANE,
  RAIL
}

const DUTY_STATES = [2, 3];
const FINAL_STATES = [5, 6];

const isDutyState = path => item => DUTY_STATES.includes(_.get(item, path));
const lastStatusFilter = topId => item =>
  item.id <= topId && (item.id !== 5 || topId !== 6);
const dutyFilter = excludeDutyStates => item =>
  !(excludeDutyStates && isDutyState('id')(item));

const buildTrackingList = ({
  versandstatus = [],
  statusGruppen = [],
  editable = false,
}) => {
  const excludeDutyStates =
    versandstatus.filter(isDutyState('statusCode.statusGruppe.id')).length ===
    0;
  const highestStatusId = versandstatus.reduce((prev, acc) => {
    const nextId = _.get(acc, 'statusCode.statusGruppe.id');
    return nextId > prev || FINAL_STATES.includes(nextId) ? nextId : prev;
  }, 0);

  const list = statusGruppen
    .filter(dutyFilter(excludeDutyStates))
    .filter(lastStatusFilter(highestStatusId));

  if (!list || list.length === 0) {
    return [
      { id: 1, icon: 'shipping', success: false, active: false, editable },
    ];
  }

  return list.map(({ id, name, icon }, index) => ({
    id,
    icon,
    text: name,
    active: true,
    editable: editable && index === list.length - 1,
    success: id !== 6,
  }));
};

const buildTrackingInfo = (lieferung, t, transportType: TransportType) => {
  if (!lieferung || Object.keys(lieferung).length === 0 || !lieferung.dossier)
    return [];

  let id = 1;
  const result = [];
  const {
    dossier: { sendungsnummer },
    auftragsNr,
    lieferscheinNr,
    kennzeichen,
    transportAbgangsdatum,
    geplantesAnkunftsdatum,
    departurePlace,
    destinationPlace,
    kundenreferenzen,
  } = lieferung || {};
  if (sendungsnummer) {
    result.push({ id: id++, title: t.trackingNumber || 'Sendungsnummer', text: sendungsnummer });
  }
  if (auftragsNr && (!kennzeichen || !kennzeichen.startsWith(auftragsNr))) {
    result.push({ id: id++, title: t.contractNumber || 'Auftrag-Nummer', text: auftragsNr });
  }
  if (lieferscheinNr) {
    result.push({
      id: id++,
      title: t.receiptNumber || 'Lieferschein-Nummer',
      text: lieferscheinNr,
    });
  }
  if (kennzeichen) {
    result.push({
      id: id++,
      title:
        transportType === TransportType.SHIP
          ? t.deliveryNumber.ship || 'Buchungsnummer'
          : transportType === TransportType.AIRPLANE
            ? t.deliveryNumber.plane || 'Airwaybill'
            : t.deliveryNumber.default || 'Lieferungskennzeichen',
      // Workaround for DPD-Frachtbriefnummern where the leading '0' was cut off during import
      text:
        kennzeichen.substr(0, 3) === '608' &&
          (kennzeichen.length === 13 || kennzeichen.length === 14)
          ? '0' + kennzeichen
          : kennzeichen,
    });
  }
  if (transportAbgangsdatum) {
    result.push({
      id: id++,
      title: t.dayStarted || 'Transportabgangsdatum',
      text: transportAbgangsdatum,
    });
  }
  if (geplantesAnkunftsdatum) {
    result.push({
      id: id++,
      title: t.scheduledArrival || 'geplantes Ankunftsdatum',
      text: geplantesAnkunftsdatum,
    });
  }
  if (kundenreferenzen && kundenreferenzen.length > 0) {
    result.push({
      id: id++,
      title: t.kundenreferenzen || 'Referenz-Nummer',
      text: kundenreferenzen.map(referenz => referenz.nr).join(', '),
    });
  }
  if (departurePlace) {
    result.push({
      id: id++,
      title: transportType === TransportType.SHIP
        ? t.departurePlace.ship || 'Abgangshafen'
        : transportType === TransportType.AIRPLANE
          ? t.departurePlace.plane || 'Abgangsflughafen'
          : transportType === TransportType.RAIL
            ? t.departurePlace.rail || 'Abgangsbahnhof'
            : t.departurePlace.default || 'Abgangsort',
      text: departurePlace,
    });
  }
  if (destinationPlace) {
    result.push({
      id: id++,
      title: transportType === TransportType.SHIP
        ? t.destinationPlace.ship || 'Bestimmungshafen'
        : transportType === TransportType.AIRPLANE
          ? t.destinationPlace.plane || 'Bestimmungsflughafen'
          : transportType === TransportType.RAIL
            ? t.destinationPlace.rail || 'Bestimmungsbahnhof'
            : t.destinationPlace.default || 'Bestimmungort',
      text: destinationPlace,
    });
  }
  return result;
};

const buildSendingDetailInfo = (lieferung, t) => {
  if (!lieferung) return [];

  let id = 1;
  const result = [];

  const addr = field =>
    [
      field['nr'] && field['nr'] !== '0' ? `${t.address.nr || 'Nr:'} ` + field['nr'] : null,
      field['name'] ? `${t.address.name || 'Name:'} ` + field['name'] : null,
      field['strasse'] ? `${t.address.street || 'Strasse:'} ` + field['strasse'] : null,
      field['plz'] || field['ort']
        ? `${t.address.zipCity || 'PLZ/Ort:'} ` + (field['plz'] + ' ' + field['ort']).trim()
        : null,
      field['land'] ? `${t.address.country || 'Land:'} ` + field['land'] : null,
    ].filter(v => v !== null);

  if (lieferung.dossier.kunde) {
    result.push({
      id: id++,
      title: t.customer || 'Kunde',
      info: addr(lieferung.dossier.kunde),
    });
  } else {
    result.push({ id: id++, title: t.customer || 'Kunde', info: [t.notSpecified || `Nicht angegeben`] });
  }
  if (lieferung.absender) {
    result.push({
      id: id++,
      title: t.shipper || 'Absender',
      info: addr(lieferung.absender),
    });
  } else {
    result.push({ id: id++, title: t.shipper || 'Absender', info: [t.notSpecified || `Nicht angegeben`] });
  }
  if (lieferung.empfaenger) {
    result.push({
      id: id++,
      title: t.recipient || 'Empfaenger',
      info: addr(lieferung.empfaenger),
    });
  } else {
    result.push({ id: id++, title: t.recipient || 'Empfänger', info: [t.notSpecified || `Nicht angegeben`] });
  }

  if (lieferung.fluege && lieferung.fluege.length > 0) {
    result.push({
      id: id++,
      title: t.flights?.label || 'Flüge',
      info: lieferung.fluege.map(flight => [`${flight.seq}: ${flight.apdep}-${flight.apdest}`, `${flight.frachtfuehrer?.name} ${flight.flightNumber}`]).flat()
    });
  }
  else if (lieferung.frachtfuehrer && lieferung.frachtfuehrer.length > 0) {
    result.push({
      id: id++,
      title: t.freightCarrier.label || 'Frachtfuehrer',
      info: [
        lieferung.frachtfuehrer[0].nr ? `${t.freightCarrier.nr || 'Nr:'} ` + lieferung.frachtfuehrer[0].nr : null,
        lieferung.frachtfuehrer[0].name
          ? `${t.freightCarrier.name || 'Name:'} ` + lieferung.frachtfuehrer[0].name
          : null,
        lieferung.frachtfuehrer[0].gruppe.name
          ? `${t.freightCarrier.group || 'Gruppe:'} ` + lieferung.frachtfuehrer[0].gruppe.name
          : null,
      ].filter(v => v !== null),
    });
  } else {
    result.push({
      id: id++,
      title: t.freightCarrier.label || 'Frachtfuehrer',
      info: [t.notSpecified || `Nicht angegeben`],
    });
  }
  return result;
};

export const buildTrackingResultFromResponse = ({
  response = {},
  editable = false,
  i18nForTrackingResultsFromResponse: t,
  transportType,
}) => {
  const { lieferung = {}, versandstatus = [] } = response as any;
  const [firstStatus] = versandstatus || [];

  if (!firstStatus) {
    return {
      track: [
        { id: 1, icon: 'shipping', success: false, active: false, editable },
      ],
      infos: buildTrackingInfo(lieferung, t, transportType),
      details: [],
    };
  }

  return {
    track: buildTrackingList({ ...response, editable }),
    infos: buildTrackingInfo(lieferung, t, transportType),
    shippingDetails: buildSendingDetailInfo(lieferung, t),
    details: [],
  };
};
