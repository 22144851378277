import { gql } from 'apollo-boost';

export const query = gql`
  query VersandstatusQuery($lieferungId: String!) {
    collection: versandstatusCollection(lieferungId: $lieferungId) {
      count
      rows {
        statusDate
        statusTime
        city
        customCarrierReference
        units
        weight
        flug {
          seq
          apdest
          airlineCode
          flightNumber
        }
        lieferung {
          units
          weight
        }
        statusCode {
          statuscode
          statusGruppe {
            id
            name
            icon
          }
        }
        quellsystem {
          name
        }
        status
      }
    }
  }
`;
