/** @jsx jsx */
import {
  jsx,
  Link as UILink,
} from '@uniteplus/rhenus-design-system';
import { Component } from 'react';
import { PUBLIC_TNT } from '../config';

type PublicTntLinkProps = {
  user: any
  t: any
};

class PublicTntLink extends Component<PublicTntLinkProps> {

  state = { slug: null };
  constructor(props) {
    super(props);
    if (this.props.user['custom:aclKey'] === 'ALL-000') { return; }
    const iHateThis = this;
    fetch(PUBLIC_TNT + '/clients/store.json'
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(async function (response) {
        const json: {
          "version": string,
          "clients": {
            (key: string): {
              "slug": string,
              "acid": string,
              "logo": string,
              "logoHeight": number,
              "image": string
            }[],
          }
        } = await response.json();
        const clientKey = Object.keys(json.clients).find(key => json.clients[key].acid === iHateThis.props.user['custom:aclKey'])
        if (clientKey) {
          iHateThis.setState(_ => ({
            slug: json.clients[clientKey].slug,
          }))
        }
      })
  }


  render() {
    return (
      <UILink
        href={PUBLIC_TNT + (this.state.slug ? '/?client=' + this.state.slug : '')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {

          this.props.t(
            'sidebar.menu.externalSites.publicT&T.link',
            'Öffentliches T&T',
          )
        }
      </UILink>

    );
  }
}

export default PublicTntLink;
