import { gql } from 'apollo-boost';
import { subDays } from 'date-fns';
import { Select, DateRangePicker } from '@uniteplus/rhenus-reports';
import Autocomplete from '../../../components/Autocomplete';

const dateRangeFieldOptions = [
  { value: 'geplantesAnkunftsdatum', label: 'Geplantes Ankunftsdatum' },
  { value: 'transportAbgangsdatum', label: 'Transport Abgangsdatum' },
  { value: 'statusDate', label: 'Letztes Event Datum' },
];
const performanceStatusOptions = [
  { value: 'DeliveredOnTime', label: 'zugestellt on time' },
  { value: 'DeliveredDelayed', label: 'nicht on time zugestellt' },
  { value: 'DeliveryInVerzug', label: 'in Verzug' },
];
const servicetypeOptions = [
  { value: 'Paket', label: 'Paket' },
  { value: 'Kurier', label: 'Kurier' },
  { value: 'Lkw', label: 'LKW' },
  { value: 'Seefracht', label: 'Seefracht' },
  { value: 'Luftfracht', label: 'Luftfracht' },
];
const gesamtstatusOptions = [
  {
    value: 'keineSendungsbewegung',
    label: 'keine Sendungsbewegung / nicht unterwegs',
  },
  {
    value: 'teilweiseAbgeschlossen',
    label: 'Teilweise abgeschlossen / unterwegs',
  },
  { value: 'komplettAbgeschlossen', label: 'Komplett abgeschlossen' },
];

export const filters = [
  {
    name: 'dateRangeField',
    label: 'Datumsfilter',
    component: Select,
    props: { isClearable: false, options: dateRangeFieldOptions },
    initialValue: dateRangeFieldOptions[2],
    nullable: false,
  },
  {
    name: 'timeRange',
    label: 'Zeitraum',
    component: DateRangePicker,
    props: {
      placeholders: {
        startDate: 'Startdatum',
        endDate: 'Enddatum',
      },
    },
    initialValue: {
      startDate: subDays(new Date(), 1),
      endDate: new Date(),
    },
  },
  {
    name: 'id',
    label: 'Sendungsnummer',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierSendungsnummern($inputValue: String!) {
          suggestions: sendungsnummern(sendungsnummer: $inputValue) {
            value: id
            label: sendungsnummer
          }
        }
      `,
    },
  },
  {
    name: 'kundeNr',
    i18n: 'kundeNr',
    label: 'Kundennummer',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierKunden($inputValue: String!) {
          suggestions: kunden(nr: $inputValue) {
            nr
            name
          }
        }
      `,
      mapOptions: (suggestion) => {
        const { name, nr = {} } = suggestion;
        return {
          value: nr,
          label: `${nr} (${name})`,
        };
      },
    },
  },
  {
    name: 'kundeNr',
    i18n: 'kundeId',
    label: 'Kunde',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierKunden($inputValue: String!) {
          suggestions: kunden(name: $inputValue) {
            nr
            name
          }
        }
      `,
      mapOptions: (suggestion) => {
        const { name, nr = {} } = suggestion;
        return {
          value: nr,
          label: `${name} (${nr})`,
        };
      },
    },
  },
  {
    name: 'kundengruppe',
    label: 'Kundengruppe',
    component: Autocomplete,
    props: {
      defaultOptions: true,
      query: gql`
        query kundengruppen($inputValue: String!) {
          suggestions: kundengruppen(prefix: $inputValue) {
            value
            label
          }
        }
      `,
    },
  },
  {
    name: 'statusGruppeId',
    label: 'Letztes Event Status Gruppe',
    component: Autocomplete,
    props: {
      defaultOptions: true,
      query: gql`
        query DossierStatusGruppen($inputValue: String!) {
          suggestions: statusGruppen(prefix: $inputValue) {
            id
            name
          }
        }
      `,
      mapOptions: ({ id, name }) => ({
        value: id,
        label: name,
      }),
    },
  },
  {
    name: 'statusCodeId',
    label: 'Letztes Event Status Code',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierStatusCodes($inputValue: String!) {
          suggestions: statusCodes(statusCode: $inputValue) {
            id
            statuscode
            beschreibung
            quellsystem {
              id
              name
            }
          }
        }
      `,
      mapOptions: (suggestion) => {
        const { id, statuscode, beschreibung, quellsystem = {} } = suggestion;
        return {
          value: id,
          label: `${statuscode} - ${beschreibung} - ${quellsystem.name}`,
        };
      },
    },
  },
  {
    name: 'containedStatusCodeId',
    label: 'Enthaltener Status Code',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierStatusCodes($inputValue: String!) {
          suggestions: statusCodes(statusCode: $inputValue) {
            id
            statuscode
            beschreibung
            quellsystem {
              id
              name
            }
          }
        }
      `,
      mapOptions: (suggestion) => {
        const { id, statuscode, beschreibung, quellsystem = {} } = suggestion;
        return {
          value: id,
          label: `${statuscode} - ${beschreibung} - ${quellsystem.name}`,
        };
      },
    },
  },
  {
    name: 'containedStatusCodeGroupId',
    label: 'Enthaltener Status Gruppe',
    component: Autocomplete,
    props: {
      defaultOptions: true,
      query: gql`
        query DossierStatusGruppen($inputValue: String!) {
          suggestions: statusGruppen(prefix: $inputValue) {
            id
            name
          }
        }
      `,
      mapOptions: ({ id, name }) => ({
        value: id,
        label: name,
      }),
    },
  },
  {
    name: 'referenz',
    label: 'Referenz',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierKundenreferenzen($inputValue: String!) {
          suggestions: kundenreferenzen(nr: $inputValue) {
            value: nr
            label: nr
          }
        }
      `,
      creatable: true,
    },
  },
  {
    name: 'frachtfuehrerId',
    label: 'Frachtführer',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierFrachtfuehrer($inputValue: String!) {
          suggestions: frachtfuehrer(name: $inputValue) {
            id
            name
            nr
          }
        }
      `,
      mapOptions: (suggestion) => {
        const { id, name, nr = {} } = suggestion;
        return {
          value: id,
          label: `${name} ${nr ? `(${nr})` : ``}`,
        };
      },
    },
  },
  {
    name: 'frachtfuehrerGruppeId',
    label: 'Frachtführer-Gruppe',
    component: Autocomplete,
    props: {
      defaultOptions: true,
      query: gql`
        query DossierFrachtfuehrerGruppen($inputValue: String!) {
          suggestions: frachtfuehrerGruppen(prefix: $inputValue) {
            id
            name
          }
        }
      `,
      mapOptions: ({ id, name }) => ({
        value: id,
        label: name,
      }),
    },
  },
  {
    name: 'empfaengerLand',
    label: 'Empfänger Land',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierEmpfaengerLand($inputValue: String!) {
          suggestions: empfaengerLaender(land: $inputValue)
        }
      `,
      mapOptions: (suggestion) => {
        return {
          value: suggestion,
          label: suggestion,
        };
      },
    },
  },
  {
    name: 'lokation',
    label: 'Standort',
    component: Autocomplete,
    props: {
      query: gql`
        query DossierLokation($inputValue: String!) {
          suggestions: dossierLokationen(lokation: $inputValue) {
            value: lokation
            label: lokation
          }
        }
      `,
    },
  },
  {
    name: 'performanceStatus',
    label: 'Laufzeit Status',
    component: Select,
    props: { isClearable: true, options: performanceStatusOptions },
    // initialValue: dateRangeFieldOptions[2],
    nullable: true,
  },
  {
    name: 'servicetype',
    label: 'Servicetyp',
    component: Select,
    props: { isClearable: true, options: servicetypeOptions },
    // initialValue: dateRangeFieldOptions[2],
    nullable: true,
  },
  {
    name: 'lieferungKennzeichen',
    label: 'Lief.-Kennzeichen',
    component: Autocomplete,
    props: {
      query: gql`
        query LieferungKennzeichen($inputValue: String!) {
          suggestions: lieferungKennzeichen(kennzeichen: $inputValue) {
            value: kennzeichen
            label: kennzeichen
          }
        }
      `,
    },
  },
  {
    name: 'gesamtstatus',
    label: 'Gesamtstatus',
    component: Select,
    props: { isClearable: true, options: gesamtstatusOptions },
    nullable: true,
  },
];
