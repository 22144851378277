import React from 'react'
import ReactSelect from 'react-select'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';

type Props = {
    value?: any[]
    onChange?: (args: any) => void
}

const countries = gql`
  query Laender(
    $inputValue: String!
  ) {
     empfaengerLaender(land: $inputValue) 
  }
`;

export const CountrySelect = ({ onChange, value }: Props) => {
    const { t } = useTranslation();
    const { loading, data } = useQuery(countries, { variables: { inputValue: '' } });

    const options = (data?.empfaengerLaender || [])?.reduce((acc: any, value: any) => /^[A-Z]{2}$/.test(value) ? [...acc, { value, label: value }] : acc, [])


    return (
        <ReactSelect
            isMulti
            isClearable
            placeholder={t('filters.placeholder')}
            isLoading={loading}
            value={value}
            options={options}
            onChange={onChange}
            styles={{
                indicatorSeparator: (baseStyle) => ({ ...baseStyle, display: 'none' }),
                control: (baseStyle) => ({
                    ...baseStyle,
                    borderRadius: 'none',
                    borderColor: '#eeeeee',
                }),
            }}
        />
    )
}