import React from 'react';
import { Box, Flex, Icon } from '@uniteplus/rhenus-design-system';

const Details = ({ data, onClose = () => {} }) => (
  <Flex sx={{ flexDirection: 'column', height: '100%' }}>
    <Flex
      sx={{
        padding: '18px',
        borderTop: '1px solid #e3e3e3',
        borderBottom: '1px solid #e3e3e3',
        flexDirection: 'row-reverse',
      }}
    >
      <Icon name="close" style={{ cursor: 'pointer' }} onClick={onClose} />
    </Flex>
    <Box as="pre" sx={{ mt: 2, height: '100%', overflowY: 'scroll' }}>
      {JSON.stringify(data, null, 2)}
    </Box>
  </Flex>
);

export default Details;
