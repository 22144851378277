import React, { forwardRef, FC } from 'react';
import styled from '@emotion/styled';

import { Box } from '@uniteplus/rhenus-design-system';
import {iconSizes, icons} from './icons';

const Svg = styled(Box)`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`;

const StatusIcon: FC<any> = forwardRef(
  (
    {
      size = '100%',
      name,
      active = false,
      success = false,
      status = 'active', // active | open | success |
      color = 'currentColor',
      role = 'presentation',
      focusable = false,
      sx = {},
      ...rest
    },
    ref,
  ) => {
    const path = icons[name][status];
    const viewBox = iconSizes[name];

    return (
      <Svg
        ref={ref}
        as="svg"
        sx={{
          size,
          color,
          display: 'inline-block',
          verticalAlign: 'middle',
          ...sx,
        }}
        viewBox={viewBox}
        focusable={focusable}
        role={role}
        {...rest}
      >
        {path}
      </Svg>
    );
  },
);

StatusIcon.displayName = 'StatusIcon';

export default StatusIcon;
