import React, { forwardRef, FC } from 'react';
import { Flex, Box, Text } from 'theme-ui';
import { useReportContext } from './Report';

const Debug: FC<any> = forwardRef<any>(({ enabled = false }: any, ref) => {
  const { filterState, filtersToVariables, stats = {} } = useReportContext();

  if (!enabled) return null;

  return (
    <Flex
      ref={ref}
      sx={{ bg: 'white', padding: 4, justifyContent: 'space-between' }}
    >
      <Box as="pre">
        <Text mb={2} color="primary">
          Filters
        </Text>
        {JSON.stringify(filterState, null, 2)}
      </Box>
      <Box as="pre">
        <Text mb={2} color="primary">
          Query variables
        </Text>
        {JSON.stringify(filtersToVariables(filterState), null, 2)}
      </Box>
      <Box sx={{ fontSize: 2 }}>
        {stats.loading} loading, {stats.loaded} loaded, {stats.count} total
      </Box>
    </Flex>
  );
});

export default Debug;
