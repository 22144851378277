import React from 'react';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Heading,
} from '@uniteplus/rhenus-design-system';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, Link } from 'react-router-dom';

const shipmentsQuery = gql`
  query LieferungenToDossierQuery($dossierId: Int!) {
    lieferungenCollection(dossierId: $dossierId, limit: 5) {
      count
      rows {
        id
        auftragsNr
        typ
        frachtfuehrer {
          name
        }
        empfaenger {
          name
        }
        absender {
          name
        }
      }
    }
  }
`;

const Entry = ({ label, value }) => (
  <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
    <Text sx={{ minWidth: '95px' }}>{label}:</Text>
    <Text>{value}</Text>
  </Flex>
);

const TransportDetails = ({ data = {}, onClose = () => {} }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sendungsnummer, id: dossierId } = data;
  const { data: shipmentsData } = useQuery(shipmentsQuery, {
    variables: { dossierId },
    skip: !dossierId,
  });

  const collection = shipmentsData?.lieferungenCollection?.rows || [];

  return (
    <Flex
      sx={{
        pb: '20px',
        px: '20px',
        mb: '10px',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Text>
            {t('tracking.details.trackingnumber.title', 'SENDUNGSNUMMER:')}
          </Text>
          <Text sx={{ fontSize: 6 }}>{sendungsnummer}</Text>
        </div>
        <Icon name="close" style={{ cursor: 'pointer' }} onClick={onClose} />
      </Flex>
      <Box
        sx={{
          mt: 2,
          mr: 1,
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {collection.map((row, idx) => (
          <Box
            key={idx}
            sx={{
              borderTopWidth: 1,
              borderTopStyle: 'solid',
              borderTopColor: 'gray.700',
              mt: '2',
            }}
          >
            <Link to={`/lieferung/${row.id}`}>
              <Heading variant="styles.h5" mt="1">
                {t('tracking.details.integrator.title', 'Lieferung')} {idx++} (
                {row.typ})
              </Heading>
            </Link>
            <Entry
              label={t(
                'tracking.details.row.auftragsNr.title',
                'Auftragsnummer',
              )}
              value={row.auftragsNr}
            />
            <Entry
              label={t(
                'tracking.details.row.frachtfuehrer.title',
                'Frachtführer',
              )}
              value={row.frachtfuehrer?.map(frachtfuehrer=>frachtfuehrer.name).join(', ')}
            />
            <Entry
                label={t('tracking.details.row.absender.title', 'Absender')}
                value={row.absender?.name}
              />
            <Entry
              label={t('tracking.details.row.empfaenger.title', 'Empfänger')}
              value={row.empfaenger?.name}
            />
          </Box>
        ))}
        {collection.length ? (
          <Button
            mt={3}
            variant="primary"
            onClick={() => history.push(`/transport/${sendungsnummer}`)}
          >
            {collection.length > 5
              ? t('tracking.details.button.showAll', 'Alle anzeigen')
              : t('tracking.details.button.showDetails', 'Details anzeigen')}
          </Button>
        ) : (
          <Box>
            {t(
              'tracking.details.button.noShipmentAvailable',
              'Keine Lieferungen vorhanden',
            )}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default TransportDetails;
