import React, { Fragment, useState } from 'react';
import { Box, Button, Flex, Icon, Text } from '@uniteplus/rhenus-design-system';
import StatusIcon from './StatusIcon';

export interface Status {
  id: number;
  icon: string;
  text?: string;
  active?: boolean;
  editable?: boolean;
  success?: boolean;
}

interface Track {
  track: Status[];
  onEdit?: (status: Status) => void;
  buttonText?: any;
}

const Track = ({
  track = [],
  onEdit = () => { },
  buttonText = 'Bearbeiten',
}: Track) => {
  const [last] = [...track].reverse();
  const [activeStatus, setActiveStatus] = useState(last);
  const showActiveLabel = !!activeStatus.text;

  const desktop = ['none', 'none', 'block'];
  const mobile = ['block', 'block', 'none'];

  return (
    <Flex
      sx={{
        flex: '1 0 auto',
        p: 4,
        pb: 8,
        bg: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 316
      }}
    >
      <Flex sx={{ height: [65, 70, 85] }}>
        {track.map((status, idx) => {
          const isLast = idx < track.length - 1;
          const isActive = status.id === activeStatus.id;
          const showLabel = !!status.text;

          // open | active | success
          const currentStatus = status.success
            ? 'success'
            : status.active
              ? 'active'
              : 'open';

          const statusProps = {
            status: currentStatus,
            sx: {
              ...(currentStatus === 'open' && { color: 'gray.300' }),
              cursor: ['pointer', 'pointer', 'inherit'],
            },
          };

          return (
            <Fragment key={status.id}>
              <Flex
                sx={{
                  flexGrow: 1,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <StatusIcon
                  name={status.icon}
                  {...statusProps}
                  onClick={() => setActiveStatus(status)}
                />
                {/* Desktop label */}
                {showLabel && (
                  <Text sx={{ display: desktop, fontSize: 1, p: 4 }}>
                    {status.text}
                  </Text>
                )}
                {/* Desktop button */}
                {status.editable && (
                  <Box>
                    <Button
                      sx={{ display: desktop, mt: 2 }}
                      onClick={() => onEdit(status)}
                    >
                      {buttonText}
                    </Button>
                  </Box>
                )}
                {isActive && (
                  <Box
                    sx={{
                      display: mobile,
                      width: 0,
                      height: 0,
                      borderStyle: 'solid',
                      borderWidth: '0px 6px 10px',
                      borderColor: 'transparent transparent #e9e9e9',
                      margin: '6px auto',
                    }}
                  />
                )}
              </Flex>
              {isLast && (
                <Flex sx={{ pr: 5, alignItems: 'center' }}>
                  <Icon name="arrowRight" size={['18px', '22px', '25px']} />
                </Flex>
              )}
            </Fragment>
          );
        })}
      </Flex>
      {/* Mobile label */}
      {
        showActiveLabel && (
          <Flex
            sx={{
              display: ['flex', 'flex', 'none'],
              fontSize: 1,
              padding: 2,
              mt: 4,
              background: '#e9e9e9',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {activeStatus.text}
          </Flex>
        )
      }
      {/* Mobile button */}
      <Button
        disabled={!activeStatus.editable}
        sx={{ display: mobile, mt: 2 }}
        onClick={() => onEdit(activeStatus)}
      >
        {buttonText}
      </Button>
    </Flex>
  );
};

export default Track;
