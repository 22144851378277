import React, { useEffect, useState } from 'react';
import { Report } from '@uniteplus/rhenus-reports';
import { Logger } from 'aws-amplify';

import report from './reports/dossier';
import { presets } from './reports/dossier/presets';
import usePreset from './usePreset';
import View from '../components/View';
import { useFilterContext } from '../filter-context';
import { filtersToVariables } from '../utils/reports';
import {
  useLocalizedButtons,
  useLocalizedFilters,
  useLocalizedColumns,
} from '../utils/useLocalized';
import { checkRules } from '../components/Can';
import { useAuth } from '../identity-context';
import BatchStatusUpdate from './BatchStatusUpdate';
import TransportDetails from './TransportDetails';
import { useTranslation } from 'react-i18next';
import { messages } from '../utils/exportDialog';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@uniteplus/rhenus-design-system';

const logger = new Logger('Dossier');

const dossierReportConfigurationQuery = gql`
  query dossierReportConfigurationQuery {
    dossierReportConfiguration {
      showDeliveryDelayColumn
    }
  }
`;

const Dossier = ({ preset }: { preset?: string }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { loading: loadingReportConfiguration, data: maybeReportConfigurationResultOrMayNot } = useQuery<any>(dossierReportConfigurationQuery);
  const showDeliveryDelayColumn = maybeReportConfigurationResultOrMayNot && maybeReportConfigurationResultOrMayNot.dossierReportConfiguration && maybeReportConfigurationResultOrMayNot.dossierReportConfiguration.showDeliveryDelayColumn;
  const [editable, setEditable] = useState(false);
  const { setFilter } = useFilterContext();
  const filterId = preset ? `transports/${preset}` : `transports`;
  const { isPreset, filterState, presetFilters } = usePreset(
    filterId,
    preset,
    presets,
  );
  const [isOpen, setOpen] = useState<boolean>();
  const [rows, setRows] = useState([]);

  const filters = useLocalizedFilters(
    report.filters.reduce(
      (acc: any, filter: any) =>
        ['kundengruppe'].includes(filter.name) && user['custom:aclKey'] && user['custom:aclKey'] !== 'ALL-000' ? acc : [...acc, filter], []),
    'transports.dossier.filters',
  );

  const columns = useLocalizedColumns(
    report.columns(showDeliveryDelayColumn),
    'transports.dossier.columns',
  );

  const buttons = useLocalizedButtons('transports.dossier.buttons');

  logger.debug('render', { filterId, isPreset, filterState });

  useEffect(() => {
    setEditable(checkRules(user.role, 'dossier-status:update'));
  }, [user]);

  if (isPreset && !filterState) {
    return null;
  }

  const handleAction = (rows) => {
    if (editable) {
      setRows(rows);
      setOpen(true);
    }
  };

  return (
    <View>
      {loadingReportConfiguration
        ? <Spinner size={32} />
        : <Report
          key={i18n.language}
          messages={messages(t)}
          legend={{
            title: t('transports.dossier.title', 'Transporte'),
            subtitle: ({ count }) =>
              t('transports.dossier.info', {
                counter: count,
                defaultValue: `Anzahl der Sendungen: ${count}`,
              }),
            ...buttons,
          }}
          query={report.query}
          columns={columns}
          isSelectable={true}
          filters={filters}
          filtersToVariables={filtersToVariables}
          filterState={filterState}
          onFilterStateChange={(filter) => setFilter(filterId, filter)}
          resetFilters={() => setFilter(filterId, presetFilters)}
          onAction={handleAction}
          details={TransportDetails}
          gridColumns={[4, 5, 6]}
          filterHeightOverride={[660, 220, 212]} // IE11
          limit={200}
          buttons={{ apply: true, reset: isPreset, clear: true }}
        />
      }
      <BatchStatusUpdate
        isOpen={isOpen}
        rows={rows}
        onClose={() => setOpen(false)}
      />
    </View>
  );
};

export default Dossier;
