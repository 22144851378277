import React, { useState, Fragment } from 'react';
import { gql } from 'apollo-boost';
import {
  Box,
  Flex,
  FileUpload,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
  LoadingButton,
  Message,
} from '@uniteplus/rhenus-design-system';
import { DatePicker, TimePicker, Select } from '@uniteplus/rhenus-reports';
import { useMutation } from '@apollo/react-hooks';
import { setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns/fp';
import { getHours, getMinutes, getSeconds, getMilliseconds } from 'date-fns';
import flow from 'lodash/fp/flow';
import { Container } from 'aws-amplify-react';
import { checkRules } from '../components/Can';
import { useAuth } from '../identity-context';
import { useTranslation } from 'react-i18next';

const resolveStatusUpdateErrors = (error, t = (key, options?) => null) => {
  const { message } = error;
  if (message && message.indexOf('GraphQL error:') !== -1) {
    return message.split(':')[1];
  }

  return t(
    'tracking.statusUpdate.error.msg',
    `Fehler: Der Eintrag konnte nicht gespeichert werden. Bitte versuchen Sie es später
    erneut oder wenden sich in dringenden Angelegenheiten an Ihren Ansprechpartner bei Rhenus.`,
  );
};

const statusUpdateMutation = gql`
  mutation statusUpdate(
    $sendungsnummer: String!
    $kennzeichen: String
    $system: String
    $kommentar: String
    $file: Upload
    $lieferzeitpunkt: String
    $abschlussart: String
  ) {
    statusUpdate(
      sendungsnummer: $sendungsnummer
      kennzeichen: $kennzeichen
      system: $system
      kommentar: $kommentar
      file: $file
      lieferzeitpunkt: $lieferzeitpunkt
      abschlussart: $abschlussart
    ) {
      path
      message
    }
  }
`;

const StatusUpdate = ({
  isOpen = false,
  onClose = () => { },
  sendungsnummer,
  kennzeichen,
  system,
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n?.language ? i18n.language?.split?.('_')?.[0] : 'de';
  const [state, setState] = useState<any>({});
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<string>();
  const [abschlussart, setAbschlussart] = useState<String>('zugestellt');
  const [updateStatus, { loading }] = useMutation(statusUpdateMutation, {
    onCompleted: () => setCompleted(true),
    onError: (err) => setError(resolveStatusUpdateErrors(err, t)),
  });
  const { user } = useAuth();

  const handleChange = ({ target }) =>
    setState({ ...state, invalid: false, [target.name]: target.value });

  const handleChangeAbschlussart = (e) => {
    setError(undefined);
    console.log(e.target.value);
    setAbschlussart(e.target.value.value);
  };

  const handleFileChange = (event) =>
    setState({ ...state, invalid: false, file: event.target.files[0] });

  const _updateStatus = () => {
    const { kommentar, file, lieferdatum, lieferzeit } = state;
    const deliveryDate = Date.parse(lieferdatum);
    const deliveryTime = Date.parse(lieferzeit);

    if (
      !lieferdatum !== !lieferzeit ||
      (!lieferdatum && !lieferzeit && !file)
    ) {
      return setState({ ...state, invalid: true });
    }

    const lieferzeitpunkt = flow(
      setHours(getHours(deliveryTime)),
      setMinutes(getMinutes(deliveryTime)),
      setSeconds(getSeconds(deliveryTime)),
      setMilliseconds(getMilliseconds(deliveryTime)),
    )(deliveryDate);

    const variables = {
      sendungsnummer,
      kennzeichen,
      system,
      lieferzeitpunkt,
      abschlussart,
      file,
      kommentar,
    };

    updateStatus({ variables });
  };
  var abschlussarten = [
    {
      value: 'zugestellt',
      label: t(
        'tracking.statusUpdate.confirmCompletion.abschlussarten.delivered.label',
        'Zugestellt',
      ),
    },
  ];
  if (checkRules(user.role, 'dossier-status:updateToNotDelivered')) {
    abschlussarten.push({
      value: 'nichtZugestellt',
      label: t(
        'tracking.statusUpdate.confirmCompletion.abschlussarten.undelivered.label',
        'Nicht zugestellt',
      ),
    });
  }
  console.log('abschlussarten', abschlussarten);
  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ fontSize: 1 }}>
        {/* Success body */}
        {completed && (
          <Fragment>
            <ModalHeader>
              <Heading>
                {t(
                  'tracking.statusUpdate.confirmCompletion.confirmation.title',
                  'Bearbeitung bestätigt!',
                )}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text sx={{ mb: 1, fontSize: 1, pr: 4, pb: 3 }}>
                {t(
                  'tracking.statusUpdate.confirmCompletion.confirmation.msg',
                  'Vielen Dank für das Update. Die Änderungen erscheinen innerhalb der nächsten Stunde auch in Ihrer Transportübersicht.',
                )}
              </Text>
            </ModalBody>
          </Fragment>
        )}
        {/* Main body */}
        {!completed && (
          <Fragment>
            <ModalHeader>
              <Heading>
                {t(
                  'tracking.statusUpdate.confirmCompletion.title',
                  'Abschluss bestätigen, POD hochladen',
                )}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box bg="white">
                <Text sx={{ pb: 1, fontSize: 0 }}>
                  {t(
                    'tracking.statusUpdate.confirmCompletion.desc',
                    'Zur Bestätigung des Abschlusses setzen Sie hier Abschlussdatum und -Uhrzeit.',
                  )}
                </Text>
                <Flex>
                  <DatePicker
                    name="lieferdatum"
                    placeholder={t(
                      'tracking.statusUpdate.confirmCompletion.datePicker.date',
                      'Datum',
                    )}
                    value={state.lieferdatum}
                    onChange={handleChange}
                    locale={locale}
                  />
                  <TimePicker
                    name="lieferzeit"
                    placeholder={t(
                      'tracking.statusUpdate.confirmCompletion.datePicker.time',
                      'Uhrzeit',
                    )}
                    timeCaption={t(
                      'tracking.statusUpdate.confirmCompletion.datePicker.time',
                      'Uhrzeit',
                    )}
                    value={state.lieferzeit}
                    onChange={handleChange}
                    sx={{ pl: 2 }}
                  />
                  <Container style={{ width: `200px` }}>
                    <Select
                      name="abschlussart"
                      label={t(
                        'tracking.statusUpdate.confirmCompletion.abschlussarten.default.label',
                        'Abschlussart',
                      )}
                      isClearable={false}
                      options={abschlussarten}
                      defaultValue={abschlussarten[0]}
                      nullable={false}
                      onChange={handleChangeAbschlussart}
                    />
                  </Container>
                </Flex>
                <Text sx={{ pt: 3, pb: 1, fontSize: 0 }}>
                  {t(
                    'tracking.statusUpdate.confirmCompletion.upload.desc',
                    'Zum Hochladen eines PODs wählen Sie hier bitte die Datei.',
                  )}
                </Text>
                <FileUpload
                  placeholder={t(
                    'tracking.statusUpdate.confirmCompletion.upload.placeholder',
                    'Datei auswählen...',
                  )}
                  buttonText={t(
                    'tracking.statusUpdate.confirmCompletion.upload.buttonText',
                    'Durchsuchen',
                  )}
                  onChange={handleFileChange}
                />
                <Text sx={{ pt: 3, pb: 1, fontSize: 0 }}>
                  {t(
                    'tracking.statusUpdate.confirmCompletion.comment.desc',
                    'Zusammen mit dem POD können Sie hier einen Kommentar für Rhenus hinterlegen (optional).',
                  )}
                </Text>
                <Textarea
                  rows={5}
                  name="kommentar"
                  placeholder={t(
                    'tracking.statusUpdate.confirmCompletion.comment.textarea.placeholder',
                    'Kommentar eingeben',
                  )}
                  onChange={handleChange}
                />
              </Box>
            </ModalBody>
            <ModalFooter
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                pt: 0,
              }}
            >
              {error && (
                <Message variant="error" sx={{ mt: 2 }}>
                  {error}
                </Message>
              )}
              {state.invalid && (
                <Message variant="error" sx={{ mt: 2 }}>
                  {t(
                    'tracking.statusUpdate.confirmCompletion.validator.msg',
                    'Es muss ein Abschlussdatum und -Uhrzeit oder ein POD angegeben werden!',
                  )}
                </Message>
              )}
              <LoadingButton
                variant="primary"
                loading={loading}
                onClick={_updateStatus}
              >
                {t(
                  'tracking.statusUpdate.confirmCompletion.submit.buttonText',
                  'Absenden',
                )}
              </LoadingButton>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  );
};

export default StatusUpdate;
