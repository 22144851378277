import React, { FC } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Box, Text } from '@uniteplus/rhenus-design-system';

import useChartsTheme from './useChartsTheme';
import {
  CustomizedAxisTick,
  ChartProps,
  handleClick,
  MissingData,
} from './shared';

type SynchronizedAreaChartProps = ChartProps & {
  syncId: string;
  height?: number | undefined;
};

const Chart: FC<SynchronizedAreaChartProps> = ({
  syncId,
  data,
  dataKeys,
  height = 200,
  onClick = () => {},
  messages = {
    missingData: 'Für diese Ansicht sind aktuell keine Daten vorhanden.',
  },
}) => {
  const { grid, fillColors } = useChartsTheme();
  const missingData = !data.length;

  return (
    <Box style={{ width: 'auto' }}>
      <Text
        style={{ display: 'inline-block', cursor: 'pointer', marginBottom: 8 }}
        onClick={handleClick('legend', onClick, {
          dataKey: dataKeys.uv,
          position: 'top',
        })}
      >
        {dataKeys.uv}
      </Text>
      <Box style={{ width: '100%', height: missingData ? '100%' : height }}>
        {missingData && <MissingData>{messages.missingData}</MissingData>}
        {!missingData && (
          <ResponsiveContainer>
            <AreaChart
              data={data}
              syncId={syncId}
              onClick={handleClick('chart', onClick, { position: 'top' })}
            >
              <CartesianGrid {...grid} />
              <XAxis dataKey={dataKeys.xAxis} tick={<CustomizedAxisTick />} />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey={dataKeys.uv}
                stroke={fillColors.uv}
                fill={fillColors.uv}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
      {dataKeys.pv !== undefined ? (
        <Text
          style={{ display: 'inline-block', cursor: 'pointer', marginBottom: 8 }}
          onClick={handleClick('legend', onClick, {
            dataKey: dataKeys.pv,
            position: 'bottom',
          })}
        >
          {dataKeys.pv}
        </Text>
        
      ) : (<React.Fragment/>)}
      {dataKeys.pv !== undefined ? (
        <Box style={{ width: '100%', height: missingData ? '100%' : height }}>
          {missingData && <MissingData>{messages.missingData}</MissingData>}
          {!missingData && (
            <ResponsiveContainer>
              <AreaChart
                data={data}
                syncId={syncId}
                onClick={handleClick('chart', onClick, { position: 'bottom' })}
              >
                <CartesianGrid {...grid} />
                <XAxis dataKey={dataKeys.xAxis} tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey={dataKeys.pv}
                  stroke={fillColors.pv}
                  fill={fillColors.pv}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Box>
      ) : (<React.Fragment/>)}
    </Box>
  );
};

export default Chart;
