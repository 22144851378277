import { Column } from 'react-table';
import { ExportToCsv } from 'export-to-csv';

export const mapFilters = (state) =>
  Object.entries(state)
    .map(([key, value]: any[]) => ({
      [key]: (value && value.value) || value,
    }))
    .reduce((acc, filter) => ({ ...acc, ...filter }), {});

export const csvExporter = ({
  filename = 'report',
  columns = [],
}: {
  filename: string;
  columns: Column[];
}) => {
  const options = {
    fieldSeparator: ';', // Defines the field separator character
    filename, // Sets the name of the downloaded file. ".csv" will be appended to the value provided. Will use 'generated' as default
    quoteStrings: '"', // If provided, will use this characters to "escape" fields, otherwise will use double quotes as deafult
    decimalSeparator: '.', // Defines the decimal separator character (default is .). If set to "locale", it uses the language sensitive representation of the number.
    showLabels: true, // If true, the first row will be the headers option or object keys if useKeysAsHeaders is present
    showTitle: false, // Includes the title as the first line in the generated file
    // title: 'My Generated Report', // This string will be used as the report title
    useBom: true, // If true, adds a BOM character at the start of the CSV to improve file compatibility
    useTextFile: false, // If true, returns a .txt file instead of .csv
    useKeysAsHeaders: false, // If true, this will use the keys of the first object in the collection as the column headers
    headers: columns.map((col) => `"${col.Header}"`) as string[], // 	Expects an array of strings, which if supplied, will be used as the column headers
  };

  return new ExportToCsv(options);
};
