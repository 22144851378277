import React, { useState, FC } from 'react';
import { Button, Flex, Input } from 'theme-ui';

const FileUpload: FC<any> = ({
  buttonText = 'Durchsuchen',
  placeholder = 'Datei auswählen...',
  onChange = () => {},
}) => {
  const [file, setFile] = useState();

  const handleChange = (event) => {
    setFile(event.target?.files[0]?.name);
    onChange(event);
  };

  return (
    <Flex sx={{ position: 'relative', overflow: 'hidden', height: '25px' }}>
      <Flex
        sx={{
          flex: '1 1 auto',
          whiteSpace: 'nowrap',
          border: `1px solid #ebebeb`,
          borderRight: 0,
          borderRadius: 2,
          fontSize: '11px',
          color: (theme) =>
            file ? theme.colors?.blue['200'] : theme.colors?.gray['300'],
          alignItems: 'center',
          px: 3,
        }}
      >
        {file ? file : placeholder}
      </Flex>
      <Button
        variant="secondary"
        sx={{ px: 4, py: 0, display: 'flex', alignItems: 'center' }}
      >
        {buttonText}
      </Button>
      <Input
        type="file"
        sx={{
          position: 'absolute',
          height: '100%',
          left: 0,
          top: 0,
          opacity: 0,
        }}
        onChange={handleChange}
      />
    </Flex>
  );
};

export default FileUpload;
