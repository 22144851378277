import React, { FC, forwardRef } from 'react';
import { Input } from 'theme-ui';

const CustomInput: FC<any> = forwardRef(
  ({ isInvalid = false, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        variant={isInvalid ? 'forms.error' : 'forms.input'}
        {...props}
      />
    );
  },
);

export default CustomInput;
