import {
  getMonth,
  getYear,
  setHours,
  setDate,
  setMonth,
  setYear,
  subYears,
} from 'date-fns/fp';
import flow from 'lodash/fp/flow';

const now = new Date();
const noon = setHours(12);
const today = { startDate: noon(now), endDate: noon(now) };

export const presets = {
  ordersInDay: () => ({ warehouseType: 'in', timeRange: today }),
  openOrdersInDay: ({openOrdersInDayLabels}) => ({
    warehouseType: 'in',
    status: { label: openOrdersInDayLabels?.OPEN , value: 'OPEN' },
    timeRange: today,
  }),
  ordersOutDay: () => ({ warehouseType: 'out', timeRange: today }),
  openOrdersOutDay: ({openOrdersOutDayLabels}) => ({
    warehouseType: 'out',
    status: { label: openOrdersOutDayLabels?.OPEN, value: 'OPEN' },
    timeRange: today,
  }),
  locationDay: ({ type = 'in', location, day = '' }) => {
    const dayOfMonth = parseInt(day.substring(0, 2), 10);
    const month = parseInt(day.substring(3, 5), 10) - 1;
    const prevYear = flow(subYears(1), getYear);
    const year = month > getMonth(now) ? prevYear(now) : getYear(now);
    const date = flow(setYear(year), setMonth(month), setDate(dayOfMonth))(now);

    return {
      warehouseType: type,
      location: { label: location, value: location },
      timeRange: { startDate: date, endDate: date },
    };
  },
};
