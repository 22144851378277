import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Box, Spinner } from '@uniteplus/rhenus-design-system';
import { BarChart } from '@uniteplus/rhenus-reports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TransportEvolutionQuery } from '../__generated__/TransportEvolutionQuery';

const transportEvolutionQuery = gql`
  query TransportEvolutionQuery {
    transportEvolution {
      dataKey
      dv1Int
      dv2Int
    }
  }
`;

const TransportEvolution = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data: queryData } = useQuery<TransportEvolutionQuery>(
    transportEvolutionQuery,
  );
  const missingData = {
    missingData: t(
      'barChart.messages.missingData.desc',
      'Für diese Ansicht sind aktuell keine Aufträge vorhanden.',
    ),
  };

  if (loading) {
    return <Spinner size={32} />;
  }

  if (!queryData || !queryData.transportEvolution) {
    return <div>No data</div>;
  }

  const data = queryData.transportEvolution.map((ds) => ({
    name: ds.dataKey,
    Schweiz: ds.dv1Int,
    International: ds.dv2Int,
  }));

  return (
    <Box sx={{ height: 350, padding: '20px', fontFamily: 'body', fontSize: 1 }}>
      <BarChart
        data={data}
        messages={missingData}
        onClick={({ activeLabel }) =>
          history.push(`/transports/yearMonth/${activeLabel}`)
        }
        dataKeys={{
          uv: t('dashboard.transportDevelopment.mapLegend.swiss', 'Schweiz'),
          pv: t(
            'dashboard.transportDevelopment.mapLegend.international',
            'International',
          ),
          xAxis: 'name',
        }}
      />
    </Box>
  );
};

export default TransportEvolution;
