import { gql } from 'apollo-boost';

export const query = gql`
  query DossierReportQuery(
    $dateRangeField: DateRangeField
    $startDate: String
    $endDate: String
    $id: Int
    $kundeNr: String
    $statusGruppeId: Int
    $statusCodeId: Int
    $containedStatusCodeId: Int
    $containedStatusCodeGroupId: Int
    $referenz: String
    $lokation: String
    $frachtfuehrerId: Int
    $frachtfuehrerGruppeId: Int
    $empfaengerLand: String
    $performanceStatus: PerformanceStatus
    $servicetype: Servicetype
    $lieferungKennzeichen: String
    $gesamtstatus: String
    $kundengruppe: String
    $offset: Int
    $limit: Int
  ) {
    collection: dossierReportCollection(
      dateRangeField: $dateRangeField
      startDate: $startDate
      endDate: $endDate
      id: $id
      kundeNr: $kundeNr
      statusGruppeId: $statusGruppeId
      statusCodeId: $statusCodeId
      containedStatusCodeId: $containedStatusCodeId
      containedStatusCodeGroupId: $containedStatusCodeGroupId
      referenz: $referenz
      lokation: $lokation
      frachtfuehrerId: $frachtfuehrerId
      frachtfuehrerGruppeId: $frachtfuehrerGruppeId
      empfaengerLand: $empfaengerLand
      performanceStatus: $performanceStatus
      servicetype: $servicetype
      lieferungKennzeichen: $lieferungKennzeichen
      gesamtstatus: $gesamtstatus
      kundengruppe: $kundengruppe
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        id
        sendungsnummer
        referenz
        anzahlLieferungen
        gesamtstatus
        statusDate
        statusTime
        status
        transportAbgangsdatum
        geplantesAnkunftsdatum
        anzahlPackstuecke
        weight
        lokation
        kundeNr
        kundeName
        deliveryDelay
        performanceStatus
        empfaengerName
        empfaengerPlz
        empfaengerOrt
        empfaengerLand
        pod {
          kommentar
          filename
          lieferzeitpunkt
        }
        statuscode
        statusgruppeName
        statusCode {
          quellsystem {
            name
          }
        }
        statusGruppe {
          id
        }
      }
    }
  }
`;
