import React, { forwardRef } from 'react';
import {
  Box,
  Grid,
  Label,
  IconButton,
  Button,
} from '@uniteplus/rhenus-design-system';
import { useReportContext } from './Report';

const Filters = forwardRef<any>((_, ref) => {
  const {
    legend = {},
    buttons = {},
    showFilters,
    reportFilters = [],
    filterState = {},
    filterHeightOverride,
    updateFilter = () => {},
    resetFilters = () => {},
    clearFilters = () => {},
    applyFilters = () => {},
    gridColumns,
  } = useReportContext();

  return (
    <Box
      ref={ref}
      sx={{
        bg: 'white',
        display: showFilters ? 'flex' : 'none',
        flexDirection: 'column',
        padding: 4,
      }}
    >
      <Grid columns={gridColumns} height={filterHeightOverride}>
        {reportFilters
          .filter((filter) => !filter.hide)
          .map(({ component: Component, ...filter }, idx) => (
            <Box key={`${filter.name}-${idx}`}>
              <Label htmlFor={filter.name}>{filter.label}</Label>
              <Component
                name={filter.name}
                value={filterState[filter.name]}
                onChange={updateFilter}
                isClearable
                {...filter.props}
              />
            </Box>
          ))}
      </Grid>
      <Box mt={4} mb={4}>
        {buttons.apply && (
          <IconButton
            variant="primary"
            iconBefore="filter"
            mr={2}
            onClick={applyFilters}
          >
            {legend.applyFilters}
          </IconButton>
        )}
        {buttons.reset && (
          <Button variant="secondary" mr={2} onClick={resetFilters}>
            {legend.resetFilters}
          </Button>
        )}
        {buttons.clear && (
          <Button variant="secondary" onClick={clearFilters}>
            {legend.clearFilters}
          </Button>
        )}
      </Box>
    </Box>
  );
});

export default Filters;
