import React, { forwardRef } from 'react';
import { useDrawerContext } from './Drawer';
import { ModalOverlay } from '../Modal';

const DrawerOverlay = forwardRef((props, ref) => {
  const { styles } = useDrawerContext();
  return (
    <ModalOverlay
      // @ts-ignore
      variant="overlay"
      __themeKey="drawers"
      ref={ref}
      opacity={styles.opacity}
      {...props}
    />
  );
});

DrawerOverlay.displayName = 'DrawerOverlay';

export default DrawerOverlay;
