/* @jsx jsx */
import { forwardRef, Children, cloneElement, isValidElement, FC } from 'react';
import { Box, jsx } from 'theme-ui';

const List: FC<any> = forwardRef(
  (
    { styleType = 'none', stylePos = 'inside', spacing, children, ...props },
    ref,
  ) => (
    <Box
      ref={ref}
      as="ul"
      sx={{
        listStyleType: styleType,
        listStylePosition: stylePos,
      }}
      {...props}
    >
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) return;

        const isLast = index + 1 === Children.count(children);
        if (isLast) {
          return child;
        }

        // @ts-ignore
        return cloneElement(child, { spacing });
      })}
    </Box>
  ),
);

List.displayName = 'List';

export default List;
