/** @jsx jsx */
import { FC, forwardRef } from 'react';
import { Box, jsx } from 'theme-ui';
import { wrapEvent } from '../utils';

const ModalOverlay: FC<any> = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Box
      variant="overlay"
      __themeKey="modals"
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 'overlay',
        ...sx,
      }}
      // @ts-ignore
      ref={ref}
      onClick={wrapEvent(props.onClick, event => {
        event.stopPropagation();
      })}
      {...props}
    />
  );
});

export default ModalOverlay;
