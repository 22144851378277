import React from 'react';

export const VerticalScrollbar = ({ height }) => (
  <div
    style={{
      position: 'absolute',
      top: 50,
      right: 0,
      bottom: 0,
      width: 18,
      overflowY: 'scroll',
      msOverflowStyle: 'auto',
    }}
  >
    <div
      style={{
        display: 'block',
        height: height,
        border: '1px solid transparent',
      }}
    />
  </div>
);

export const HorizontalScrollbar = ({ width }) => (
  <div
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: 18,
      width: '100%',
      overflowX: 'scroll',
      msOverflowStyle: 'auto',
    }}
  >
    <div
      style={{
        display: 'block',
        width: width,
        border: '1px solid transparent',
      }}
    />
  </div>
);
