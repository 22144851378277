export const filtersToVariables = state =>
  Object.entries(state)
    .map(([key, value]: any[]) =>
      value && value.startDate
        ? {
            startDate: value?.startDate?.toISOString(),
            endDate: value?.endDate?.toISOString(),
          }
        : { [key]: (value && value.value) || value },
    )
    .reduce((acc, filter) => ({ ...acc, ...filter }), {});
