import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import Wareneingang from './Wareneingang';
import Warenausgang from './Warenausgang';

const Warehouse = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      {/* Wareneingang */}
      <Route path={`${path}/in`} exact>
        {/*http://localhost:3000/warehouse/in */}
        <Wareneingang />
      </Route>
      <Route path={`${path}/in/ordersInDay`} exact>
        {/*http://localhost:3000/warehouse/in/ordersInDay */}
        <Wareneingang preset="ordersInDay" />
      </Route>
      <Route path={`${path}/in/openOrdersInDay`} exact>
        {/* http://localhost:3000/warehouse/in/openOrdersInDay */}
        <Wareneingang preset="openOrdersInDay" />
      </Route>
      <Route path={`${path}/in/:location/:day`} exact>
        {/* http://localhost:3000/warehouse/in/Buchs/18.02.2020 */}
        <Wareneingang preset="locationDay" />
      </Route>

      {/* Warenausgang */}
      <Route path={`${path}/out`} exact>
        {/*http://localhost:3000/warehouse/out */}
        <Warenausgang />
      </Route>
      <Route path={`${path}/out/ordersOutDay`} exact>
        {/*http://localhost:3000/warehouse/out/ordersOutDay */}
        <Warenausgang preset="ordersOutDay" />
      </Route>
      <Route path={`${path}/out/openOrdersOutDay`} exact>
        {/*http://localhost:3000/warehouse/out/openOrdersOutDay */}
        <Warenausgang preset="openOrdersOutDay" />
      </Route>
      <Route path={`${path}/out/:location/:day`} exact>
        {/* http://localhost:3000/warehouse/out/Buchs/18.02.2020 */}
        <Warenausgang preset="locationDay" />
      </Route>
    </Switch>
  );
};

export default Warehouse;
