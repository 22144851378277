import React from 'react';
import { SignIn as AmplifySignIn } from 'aws-amplify-react';
import {
  Background,
  Box,
  Container,
  Heading,
  Label,
  Input,
  Button,
  Flex,
  Link,
  Message,
} from '@uniteplus/rhenus-design-system';

import Footer from './Footer';
import { logError } from '../../errors';
import { Translation } from 'react-i18next';

class SignIn extends AmplifySignIn {
  state: any = {};

  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }

  error(error: any) {
    logError(error);
    const [message] = error?.message.split(':')?.map(str => str.trim())?.reverse()
    this.state.showError = true
    // @ts-ignore
    this.setState({ error: { ...error, message } });
  }

  showComponent() {
    return (
      <Translation>
        {(t) => (
          <Container>
            <Background url="/img/bg_rhenus_login.jpg" />
            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ flex: '1 0 auto', mt: 100, width: 400 }}>
                <Box bg="white" mb={1} p={3}>
                  <Heading as="h3">
                    {t('login.title', 'Dashboard Login')}
                  </Heading>
                </Box>
                <Box as="form" bg="white" p={3}>
                  <Label htmlFor="username">
                    {t('login.username', 'Benutzername')}
                  </Label>
                  <Input
                    id="username"
                    key="username"
                    name="username"
                    onChange={this.handleInputChange}
                    type="text"
                    placeholder={t(
                      'login.username.placeholder',
                      'Benutzername oder E-Mail Adresse',
                    )}
                    mb={3}
                  />
                  {this.state.showError === 'signIn' && this.state.error?.message === 'Username cannot be empty' && (
                    <Message variant="error">
                      {t(this.state.error.message)}
                    </Message>
                  )}
                  <Label htmlFor="password">
                    {t('login.password', 'Passwort')}
                  </Label>
                  <Input
                    id="password"
                    key="password"
                    name="password"
                    onChange={(e) => {
                      this.handleInputChange(e);
                      // @ts-ignore
                      this.setState({ password: e.target.value });
                    }}
                    type="password"
                    placeholder={t('login.password.placeholder', 'Passwort')}
                    mb={3}
                  />
                  {this.state.showError && this.state.error?.message === 'Username cannot be empty' && !this.state.password && (
                    <Message variant="error">
                      {t('Custom auth lambda trigger is not configured for the user pool.')}
                    </Message>
                  )}
                  {this.state.showError && this.state.error && this.state.error?.message !== 'Username cannot be empty' && (
                    <Message variant="error">
                      {t(this.state.error.message)}
                    </Message>
                  )}
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Link
                      href="#"
                      onClick={() => {
                        this.state.showError = false
                        super.changeState('forgotPassword')
                      }}
                      sx={{ fontSize: 1 }}
                    >
                      {t('login.password.forgot', 'Passwort vergessen?')}
                    </Link>
                    <Button onClick={(e) => super.signIn(e)}>
                      {t('login.button.text', 'Anmelden')}
                    </Button>
                  </Flex>
                </Box>
                <Footer />
              </Box>
            </Flex>
          </Container>
        )}
      </Translation>
    );
  }
}

export default SignIn;
