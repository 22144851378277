import React, { useState } from 'react';
import { Report } from '@uniteplus/rhenus-reports';
import { useTranslation } from 'react-i18next';

import report from './reports/lieferungen';
import View from '../components/View';
import { filtersToVariables } from '../utils/reports';
import { useRouteMatch } from 'react-router-dom';
import { messages } from '../utils/exportDialog';
import {
  useLocalizedButtons,
  useLocalizedColumns,
  useLocalizedFilters,
} from '../utils/useLocalized';

const Lieferungen = () => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch();
  const { code } = (params as any) || {};
  const [filterState, setFilter] = useState<any>({
    sendungsnummer: { value: code, label: code },
  });

  const filters = useLocalizedFilters(
    report.filters,
    'transports.lieferungen.filters',
  );

  const columns = useLocalizedColumns(
    report.columns,
    'transports.lieferungen.columns',
  );

  return (
    <View>
      <Report
        key={i18n.language}
        messages={messages(t)}
        legend={{
          title: t('transports.lieferungen.title', 'Lieferungen'),
          subtitle: ({ count }) =>
            t('transports.lieferungen.info', {
              counter: count,
              defaultValue: `Anzahl der Lieferungen ${count}`,
            }),
          ...useLocalizedButtons(),
        }}
        query={report.query}
        columns={columns}
        filters={filters}
        filtersToVariables={filtersToVariables}
        filterState={filterState}
        onFilterStateChange={(filter) => setFilter(filter)}
        isSelectable={true}
        gridColumns={[2, 3, 4]}
        limit={50}
      />
    </View>
  );
};

export default Lieferungen;
