import { gql } from 'apollo-boost';
import Autocomplete from '../../../components/Autocomplete';

export const filters = [
  {
    name: 'sendungsnummer',
    label: 'Sendungsnummer',
    component: Autocomplete,
    props: {
      query: gql`
        query LieferungenSendungsnummern($inputValue: String!) {
          suggestions: sendungsnummern(sendungsnummer: $inputValue) {
            value: sendungsnummer
            label: sendungsnummer
          }
        }
      `,
    },
  },
];
