import React, { createContext, useContext, FC } from 'react';
import { Slide } from '../Transition';
import { Modal } from '../Modal';

const DrawerContext = createContext<any>({});
export const useDrawerContext = () => useContext(DrawerContext);

const Drawer: FC<any> = ({
  isOpen,
  onClose,
  isFullHeight,
  placement = 'right',
  finalFocusRef,
  size = '2xs',
  ...props
}) => {
  return (
    <Slide
      in={isOpen}
      from={placement}
      finalHeight={isFullHeight ? '100vh' : 'auto'}
    >
      {styles => (
        <DrawerContext.Provider value={{ styles, size }}>
          <Modal isOpen={true} onClose={onClose} {...props} />
        </DrawerContext.Provider>
      )}
    </Slide>
  );
};

export default Drawer;
