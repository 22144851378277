import React, { forwardRef, FC } from 'react';
import { useDrawerContext } from './Drawer';
import { ModalContent } from '../Modal';

const drawerSizes = {
  xs: 'xs',
  sm: 'md',
  md: 'lg',
  lg: '2xl',
  xl: '4xl',
  full: '100vw',
};

const DrawerContent: FC<any> = forwardRef((props, ref) => {
  const {
    // Don't want to  animate the opacity of the DrawerContent
    styles: { opacity, ...placementStyles },
    size,
  } = useDrawerContext();

  const _size = size in drawerSizes ? drawerSizes[size] : size;

  return (
    <ModalContent
      ref={ref}
      noStyles
      variant="drawer"
      __themeKey="drawers"
      sx={{ position: 'fixed', maxWidth: _size, opacity, ...placementStyles }}
      {...props}
    />
  );
});

DrawerContent.displayName = 'DrawerContent';

export default DrawerContent;
