/** @jsx jsx */
import { forwardRef, FC } from 'react';
import { Box, Button, jsx } from 'theme-ui';
import { useModalContext } from './Modal';
import Icon from '../Icon';

const ModalCloseButton: FC<any> = forwardRef(({ sx, ...props }, ref) => {
  const { onClose } = useModalContext();

  return (
    <Box
      as={Button}
      // @ts-ignore
      ref={ref}
      onClick={onClose}
      variant="closeButton"
      __themeKey="modals"
      sx={{
        position: 'absolute',
        top: 2,
        right: 2,
        bg: 'transparent',
        ...sx,
      }}
      {...props}
    >
      <Icon name="close" />
    </Box>
  );
});

ModalCloseButton.displayName = 'ModalCloseButton';

export default ModalCloseButton;
