import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withApollo } from '@apollo/react-hoc';
import { gql } from 'apollo-boost';
import {
  Box,
  Input,
  Text,
  LoadingButton,
  Message,
} from '@uniteplus/rhenus-design-system';

const searchQuery = gql`
  query SearchByTrackingNumber($code: String!) {
    searchResult: trackingNumberSearch(trackingNumber: $code) {
      type
      identifier
    }
  }
`;

const Search = ({ client }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [success, setSuccess] = useState(true);

  useEffect(() => setDisabled(value.length < 5), [value]);

  const handleSearch = async (e) => {
    e.preventDefault();

    const {
      data: { searchResult },
    } = await client.query({
      query: searchQuery,
      variables: { code: value },
    });

    if (!searchResult) {
      setSuccess(false);
      return;
    }

    const targets = {
      Dossier: `${path}transport/${searchResult.identifier}`, // 10.22.1.20-159/0
      Lieferung: `${path}lieferung/${searchResult.identifier}`, // 10.21.4.13-29/0
      AmbiguousLieferung: `${path}transports/byLieferungKennzeichen/${searchResult.identifier}`, // +GF+65111001
      Referenz: `${path}transports/kundenreferenz/${searchResult.identifier}`,
    };

    const target = targets[searchResult.type];

    if (target) {
      return history.push(target);
    }
  };

  return (
    <Box as="form" onSubmit={handleSearch} sx={{ p: 3, maxWidth: 400 }}>
      <Text sx={{ fontSize: 1, mb: 2 }}>
        {t(
          'dashboard.transportOverview.performanceCharts.search.tooltip',
          'Trackingnummer eingeben',
        )}
      </Text>
      <Input
        value={value}
        isInvalid={!success}
        onChange={(e) => {
          setValue(e.target?.value);
          setSuccess(true);
        }}
      />
      {!success && (
        <Message variant="error">
          {t(
            'dashboard.transportOverview.performanceCharts.search.error',
            'Keine Sendung für die Trackingnummer gefunden',
          )}
        </Message>
      )}
      <LoadingButton
        type="submit"
        variant="primary"
        disabled={disabled}
        sx={{ mt: 2 }}
      >
        {t(
          'dashboard.transportOverview.performanceCharts.search.buttonText',
          'Sendung verfolgen',
        )}
      </LoadingButton>
    </Box>
  );
};

export default withApollo(Search);
