import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';
import i18n from './i18n';
import config from './config';

const httpLink = createUploadLink({
  uri: config.GRAPHQL,
  fetchOptions: {
    credentials: 'include',
  },
  credentials: 'same-origin',
});

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  const userLocale = user.attributes.locale;
  const i18nLocale = i18n.language;
  const acceptLanguage = userLocale === i18nLocale ? userLocale : i18nLocale;
  // console.log('authLink', { userLocale, i18nLocale, acceptLanguage });

  return {
    headers: {
      ...headers,
      AccessToken: session.getIdToken().getJwtToken(),
      'Accept-Language': acceptLanguage,
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
