import React from 'react';
import { Box } from 'theme-ui';

const logo = {
  path: (
    <g fill="currentColor">
      <polygon points="50.6,72.5 90.4,72.5 130.6,34.7 90.9,34.7"></polygon>
      <polygon points="12.7,107.9 101.1,107.9 130.4,80.3 42.2,80.3"></polygon>
      <path d="M0.7,142.3h142.1V0.2H0.7V142.3z M8.8,8.3h125.9v125.9H8.8V8.3z"></path>
      <polygon points="283.5,44.2 339.4,44.2 339.4,71 357.3,71 357.3,0.3 339.4,0.3 339.4,27.1 283.5,27.1 283.5,0.3 265.6,0.3 265.6,71 283.5,71"></polygon>
      <polygon points="460.4,54 385,54 385,44.2 456.2,44.2 456.2,27.1 385,27.1 385,17.3 460.4,17.3 460.4,0.3 367.1,0.3 367.1,71 460.4,71"></polygon>
      <polygon points="486,22.1 539.9,71 560.7,71 560.7,0.3 542.8,0.3 542.8,49 489.1,0.3 468,0.3 468,71 486,71"></polygon>
      <path d="M643.6,71c10.2,0,18.5-8.1,18.5-18.2V0.3h-17.9v47.9c0,3.2-2.7,5.9-6,5.9h-45.8c-3.3,0-6-2.6-6-5.9V0.3h-17.9 v52.6c0,10,8.3,18.2,18.5,18.2H643.6z"></path>
      <path d="M691.7,44.2h48.9c2.8,0,5.1,2.3,5.1,5c0,2.8-2.3,4.8-5.1,4.8h-69.8v17H741v0c12.4,0,22.4-9.8,22.4-21.9 c0-12.1-10-22-22.4-22H692c-2.8,0-5.1-2.3-5.1-5c0-2.8,2.3-4.8,5.1-4.8h68.8V0.3h-69.2c-12.4,0-22.4,9.8-22.4,22 C669.3,34.4,679.3,44.2,691.7,44.2z"></path>
      <path d="M230.7,44.2h5.5c12.4,0,22.4-9.9,22.4-22c0-12.1-10-22-22.4-22H166V71h17.9V44.2v0h21.5L232.6,71h25.2 L230.7,44.2z M183.9,27.1L183.9,27.1v-9.8h51.8c2.8,0,5,2.2,5,4.9s-2.2,4.9-5,4.9H183.9z"></path>
      <path d="M630.8,103.4h47.8l-0.1-9H625c-4.5,0-8.2,3.8-8.2,8.5v30.8c0,4.7,3.7,8.5,8.2,8.5h53.6l0.1-8.7h-47.8 c-2.4,0-4.3-2-4.3-4.4v-21.2C626.4,105.4,628.3,103.4,630.8,103.4z"></path>
      <path d="M746.8,113.9h-35.2c-2.9,0-5.3-2.3-5.3-5.3c0-3.1,2.5-5.1,5.3-5.1h48v-9.1h-48.2c-8,0-14.5,6.4-14.5,14.2 c0,7.8,6.5,14.2,14.5,14.2h35.2c2.9,0,5.3,2.3,5.3,5.3c0,3.1-2.5,5.1-5.3,5.1h-48.7v9h49c8.1,0,14.5-6.4,14.5-14.2 C761.3,120.3,754.8,113.9,746.8,113.9z"></path>
      <polygon points="511.6,103.4 534.8,103.5 534.8,142.3 544.5,142.3 544.5,103.5 567.7,103.5 567.7,94.4 511.6,94.4"></polygon>
      <rect x="585.8" y="94.4" width="9.7" height="47.9"></rect>
      <path d="M483,113.9h-35.3c-2.9,0-5.3-2.3-5.3-5.3c0-3.1,2.5-5.1,5.3-5.1h48v-9.1h-48.2c-8,0-14.5,6.4-14.5,14.2 c0,7.8,6.5,14.2,14.5,14.2h35.2c2.9,0,5.3,2.3,5.3,5.3c0,3.1-2.5,5.1-5.3,5.1h-48.7v9H483c8.1,0,14.5-6.4,14.5-14.2 C497.5,120.3,491.1,113.9,483,113.9z"></path>
      <rect x="404.2" y="94.4" width="9.7" height="47.9"></rect>
      <polygon points="175.3,94.4 165.6,94.4 165.7,142.4 226.6,142.4 226.6,133.4 175.3,133.3"></polygon>
      <path d="M292.3,94.4h-45.4c-4.5,0-8.2,3.8-8.2,8.5v30.8c0,4.7,3.7,8.5,8.2,8.5h11.2h34.2c4.5,0,8.2-3.8,8.2-8.5v-30.8 C300.5,98.1,296.8,94.4,292.3,94.4z M290.8,129c0,2.4-1.9,4.4-4.3,4.4h-33.8c-2.4,0-4.3-2-4.3-4.4v-21.2c0-2.4,1.9-4.4,4.3-4.4h5.4 h28.4c2.4,0,4.3,2,4.3,4.4V129z"></path>
      <path d="M372.7,113.2h-29.4l-0.1,9h23.6c2.4,0,4.3,2,4.3,4.4v2.3c0,2.4-1.9,4.4-4.3,4.4h-26.5v0h-7.6 c-2.4,0-4.3-2-4.3-4.4v-21.2c0-2.4,1.9-4.4,4.3-4.4h48v-8.9l-53.8-0.1c-4.5,0-8.2,3.8-8.2,8.5v30.8c0,4.7,3.7,8.5,8.2,8.5h45.7 c4.5,0,8.2-3.8,8.2-8.5v-11.9C380.9,117,377.2,113.2,372.7,113.2z"></path>
    </g>
  ),
  viewBox: '0 0 764 143',
};

const logoSizes = {
  xs: { height: 16, width: 85 },
  sm: { height: 24, width: 128 },
  md: { height: 32, width: 170 },
  lg: { height: 48, width: 256 },
  xl: { height: 64, width: 342 },
};

const BrandLogo: React.FC<any> = ({ size = 'md', color, ...props }) => {
  const { height, width } = logoSizes[size] || logoSizes['sm'];

  return (
    <Box
      color={color || 'currentColor'}
      sx={{ display: 'inline-block', width }}
    >
      <svg viewBox={logo.viewBox} height={height} {...props}>
        {logo.path}
      </svg>
    </Box>
  );
};

export default BrandLogo;
