import React, { useMemo, useState } from 'react';
import TransportLaufzeitauswertung from './../dashboard/TransportLaufzeitauswertung';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@uniteplus/rhenus-reports';
import moment from 'moment';
import {
  Background,
  Grid,
  Card,
  Box,
  Heading,
  Text,
} from '@uniteplus/rhenus-design-system';
import View from '../components/View';
import { CountrySelect } from '../components/CountrySelect';

const Regellaufzeitauswertung = (props) => {
  const [countries, setCountries] = useState([])
  const [startDate, setStartDate] = useState(moment().startOf('year').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('year').toDate());
  const transportStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-01`
  const transportEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-01`
  const country = useMemo(() => countries?.map((country: any) => country.label), [countries])

  const cardHead = {
    p: 3,
    mb: '1px',
    bg: 'white',
    display: 'flex',
    alignItems: 'baseline',
  };
  const cardContent = { p: 3, bg: 'white' };
  const { t, i18n } = useTranslation();
  const locale = i18n?.language ? i18n.language?.split?.('_')?.[0] : 'de';
  return (
    <View>
      <Background url="/img/rhenus-background-ship.jpg" />

      <Grid gap={1} mx={2} mb={2} columns={[1, 1, 1]}>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t('standardDeliveryTimeEvaluation.period.title', 'Plandatum')}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <div style={{ display: 'grid', gridTemplateColumns: '130px 130px 260px', columnGap: 10 }}>
              <label>
                <Text>
                  {t('dashboard.standardDeliveryTimeEvaluation.from', 'von')}
                </Text>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date?.target?.value)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  locale={locale}
                  style={{ minHeight: 38 }}
                />
              </label>
              <label>
                <Text>
                  {t('dashboard.standardDeliveryTimeEvaluation.until', 'bis')}
                </Text>
                <DatePicker
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date.target.value)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  locale={locale}
                  style={{ minHeight: 38 }}
                />
              </label>
              <label >
                <Text>{t('transports.dossier.filters.empfaengerLand', 'Empfänger Land')}</Text>
                <CountrySelect value={countries} onChange={setCountries} />
              </label>
            </div>
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.Paket.detail.title',
                'Regellaufzeitauswertung pro Land (Paket)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Paket"
              limit={20}
              startDate={transportStartDate}
              endDate={transportEndDate}
              country={country}
            />
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.Kurier.detail.title',
                'Regellaufzeitauswertung pro Land (Kurier)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Kurier"
              limit={20}
              startDate={transportStartDate}
              endDate={transportEndDate}
              country={country}
            />
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.Lkw.detail.title',
                'Regellaufzeitauswertung pro Land (Stückgut)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Lkw"
              limit={20}
              startDate={transportStartDate}
              endDate={transportEndDate}
              country={country}
            />
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.Seefracht.detail.title',
                'Regellaufzeitauswertung pro Land (Seefracht)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Seefracht"
              limit={20}
              startDate={transportStartDate}
              endDate={transportEndDate}
              country={country}
            />
          </Box>
        </Card>
        <Card>
          <Box sx={cardHead}>
            <Heading>
              {t(
                'dashboard.standardDeliveryTimeEvaluation.Luftfracht.detail.title',
                'Regellaufzeitauswertung pro Land (Luftfracht)',
              )}
            </Heading>
          </Box>
          <Box sx={cardContent}>
            <TransportLaufzeitauswertung
              servicetype="Luftfracht"
              limit={20}
              startDate={transportStartDate}
              endDate={transportEndDate}
              country={country}
            />
          </Box>
        </Card>
      </Grid>
    </View>
  );
};

export default Regellaufzeitauswertung;
