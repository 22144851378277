import React, { Fragment, useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import get from 'lodash.get';
import {
  Box,
  Button,
  Flex,
  LoadingButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Checkbox,
  Label,
} from '@uniteplus/rhenus-design-system';
import { csvExporter } from './utils';

const fullExportReducer = (columns: any[]) => (row: any) => {
  const keys = columns.map((col) => col.accessor);

  return keys
    .map((key) => [key, get(row, key)])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value || '' }), {});
};

const reportReducer = (columns: any[]) => (row: any) => {
  const keys = columns.map((col) => col.id || col.accessor);

  return Object.entries(row.values)
    .filter(([key]) => keys.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value || '' }), {});
};

const ExportDialog: React.FC<any> = ({
  query,
  queryVariables = {},
  buttonText = 'CSV Export',
  filename = 'report',
  columns,
  selectedRows = [],
  totalRows = 0,
  disabled,
  pageSize = 25,
  messages = {},
}: any) => {
  const client = useApolloClient();
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullExport, setFullExport] = useState(false);
  const [paging, setPaging] = useState({ page: 0, pageCount: 0 });
  const rowCount = fullExport ? totalRows : selectedRows.length;
  const fullExportinProgress = fullExport && loading;
  useEffect(
    () => setPaging({ page: 1, pageCount: Math.ceil(totalRows / pageSize) }),
    [totalRows, pageSize],
  );

  const csvExport = async () => {
    setLoading(true);

    const csvColumns = columns.filter((col: any) => !col.hide);
    const exporter = csvExporter({ filename, columns: csvColumns });

    if (!fullExport) {
      setLoading(false);
      return exporter.generateCsv(selectedRows.map(reportReducer(csvColumns)));
    }

    let rows: any = [];

    let currentPage = 0;
    do {
      setPaging({ ...paging, page: currentPage + 1 });

      const { data } = await client.query({
        query,
        variables: {
          offset: currentPage * pageSize,
          limit: pageSize,
          ...queryVariables,
        },
        fetchPolicy: 'network-only', // 'cache-first' policy produces weird caching issues
      });

      rows.push(...data?.collection?.rows);
    } while (++currentPage < paging.pageCount);

    setLoading(false);
    exporter.generateCsv(rows.map(fullExportReducer(csvColumns)));
  };

  const {
    exportDialog: dialog = {
      rowsOfRows: () => undefined,
      pageOfPageCount: () => undefined,
    },
  } = messages;

  return (
    <Fragment>
      <LoadingButton
        disabled={disabled}
        loading={loading}
        variant="secondary"
        ml={2}
        onClick={() => setOpen(true)}
      >
        {buttonText}
      </LoadingButton>
      <Modal isOpen={isOpen} size="lg" onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent sx={{ fontSize: 1 }}>
          <ModalHeader>
            <Heading>{dialog.title || 'CSV Export'}</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text sx={{ mb: 3, fontSize: 2 }}>
              {dialog.rowsOfRows(rowCount, totalRows) ||
                `Es werden ${rowCount} von ${totalRows} Zeilen exportiert!`}
            </Text>
            {fullExportinProgress && (
              <Text sx={{ mt: 4, mb: 2, fontSize: 2 }}>
                {dialog.pageOfPageCount(paging) ||
                  `Lade Seite ${paging.page} von ${paging.pageCount}`}
              </Text>
            )}
          </ModalBody>
          <ModalFooter sx={{ justifyContent: 'space-between', pt: 1 }}>
            <Flex>
              <LoadingButton
                variant="primary"
                loading={loading}
                onClick={() => csvExport()}
              >
                {dialog.export || 'Exportieren'}
              </LoadingButton>
              {fullExportinProgress && (
                <Button
                  variant="secondary"
                  ml={2}
                  onClick={() => window.location.reload()}
                >
                  {dialog.abort || 'Abbrechen'}
                </Button>
              )}
            </Flex>
            <Box>
              <Label
                sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
              >
                <Checkbox
                  checked={fullExport}
                  onChange={(e) => setFullExport(e.target.checked)}
                />
                {dialog.selectAll || 'Alle auswählen (erhöhte Ladezeit)'}
              </Label>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ExportDialog;
