import React, { FC } from 'react';
import { withApollo } from '@apollo/react-hoc';
import { AsyncSelect } from '@uniteplus/rhenus-reports';
import { useTranslation } from 'react-i18next';

const Autocomplete: FC<any> = ({
  client,
  query,
  model,
  inputKey,
  mapOptions,
  ...props
}) => {
  const { t } = useTranslation();
  const getOptions = async (input) => {
    const variables = { model, inputKey, inputValue: input };
    const options = { fetchPolicy: 'network-only' };
    const fetch = props.defaultOptions || (input && input.length > 0);

    return (
      fetch &&
      client
        .query({ query, variables, ...options })
        .then(({ data: { suggestions } }) =>
          mapOptions ? suggestions.map(mapOptions) : suggestions,
        )
    );
  };

  return (
    <AsyncSelect
      noOptionsMessage={() =>
        t('autocomplete.noOptionsMessage', 'Keine Auswahl möglich')
      }
      formatCreateLabel={(inputValue: string) =>
        t('autocomplete.formatCreateLabel', {
          inputValue,
          defaultValue: `Suche ${inputValue}`,
        })
      }
      {...props}
      loadOptions={getOptions}
    />
  );
};

export default withApollo(Autocomplete);
