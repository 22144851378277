import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import Dossier from './Dossier';
import Lieferungen from './Lieferungen';

const Transports = () => {
  let { path } = useRouteMatch();
  console.log({ path });

  return (
    <Switch>
      <Route path={`/transports`} exact>
        {/*http://localhost:3000/transports */}
        <Dossier />
      </Route>
      <Route path={`/transports/week`} exact>
        {/*http://localhost:3000/transports/week */}
        <Dossier preset="week" />
      </Route>
      <Route path={`/transports/open`} exact>
        {/*http://localhost:3000/transports/open */}
        <Dossier />{' '}
        {/* FIXME: special route doesn't do anything, but is linked from dashboard (TransportPerformance)  */}
      </Route>
      <Route path={`/transports/kundenreferenz/:kundenreferenz*`} exact>
        {/*http://localhost:3000/transports/kundenreferenz/2930099 */}
        <Dossier preset="kundenreferenz" />
      </Route>
      <Route path={`/transports/yearMonth/:yearMonth*`} exact>
        {/*http://localhost:3000/transports/yearMonth/2019-04 */}
        <Dossier preset="yearMonth" />
      </Route>
      <Route path={`/transports/carrierGroupCH/:carrierGroupId*`} exact>
        {/*http://localhost:3000/transports/carrierGroupCH/2*/}
        <Dossier preset="carrierGroupCH" />
      </Route>
      <Route path={`/transports/carrierGroup/:carrierGroupId*`} exact>
        {/*http://localhost:3000/transports/carrierGroup/4 */}
        <Dossier preset="carrierGroup" />
      </Route>
      <Route path={`/transports/consigneeCountryDistribution/:country?`} exact>
        {/*http://localhost:3000/transports/consigneeCountryDistribution/IT */}
        <Dossier preset="consigneeCountryDistribution" />
      </Route>
      <Route path={`/transports/negativreporting/:servicetype/:country?`} exact>
        {/*http://localhost:3000/transports/negativreporting/Paket/DE */}
        <Dossier preset="negativreporting" />
      </Route>
      <Route
        path={`/transports/regellaufzeitauswertung/:servicetype/:performancestatus/:startDate/:endDate/:country?`}
        exact
      >
        {/*http://localhost:3000/transports/regellaufzeitauswertung/Transport/DeliveredOnTime/2020-01-01/2020-12-01/DE */}
        <Dossier preset="regellaufzeitauswertung" />
      </Route>
      <Route path={`/transports/byLieferungKennzeichen/:lieferungKennzeichen*`} exact>
        {/*http://localhost:3000/transports/byLieferungKennzeichen/+GF+65111001 */}
        <Dossier preset="lieferungKennzeichen" />
      </Route>
      <Route path={`/transports/byKundenreferenz/:kundenreferenz*`} exact>
        {/*http://localhost:3000/transports/byKundenreferenz/81006202 */}
        <Dossier preset="kundenreferenz" />
      </Route>
      <Route path={`/transports/:code*`} exact>
        {/*http://localhost:3000/transports/10.12.2.20-6/0 */}
        <Dossier preset="sendungsnummer" />
      </Route>
      <Route path={`/transport/:code*`} exact>
        {/*http://localhost:3000/transport/10.12.2.20-6/0 */}
        <Lieferungen />
      </Route>
    </Switch>
  );
};

export default Transports;
